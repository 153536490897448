export const AppId = {
    TEMPERATURE_MONITORING: "temperature_monitoring",
    WATER_MANAGEMENT: "water_management",
    DEVICE_SCAN: "device_scan",
    SMART_AGRICULTURE: "smart_agriculture",
    EMERA: "emera",
    DEVICE_EXPLORER: "device_explorer",
    SENS_DC: "sens_dc",
    AGRICULTURE_WATER: "agriculture_water",
    EMERA_V2: "emera_v2",
    BIZ: "biz",
    SUPERVIOT: "superviot",
    COMPOST: "compost",
    ROBOT: "robot",
    RIOTAPP: "riot",
    WATER_METERING: "water_metering",
};

const ApplicationData = [
    {
        id: AppId.TEMPERATURE_MONITORING,
        title: "Temperature monitoring",
        path: "/temperature_monitoring",
    },
    {
        id: AppId.WATER_MANAGEMENT,
        title: "Water Management",
        path: "/water_management",
    },
    {
        id: AppId.DEVICE_SCAN,
        title: "Device Scan",
        path: "/device_scan/scan",
    },
    {
        id: AppId.SMART_AGRICULTURE,
        title: "Smart Agriculture",
        path: "/smart_agriculture",
    },
    {
        id: AppId.EMERA,
        title: "Emera",
        path: "/emera/v1",
        hide: true,
    },
    {
        id: AppId.DEVICE_EXPLORER,
        title: "Device Explorer",
        path: `/device_explorer/${AppId.DEVICE_EXPLORER}/main`,
    },
    {
        id: AppId.SENS_DC,
        title: "SensDC",
        path: "/sens_dc",
    },
    {
        id: AppId.AGRICULTURE_WATER,
        title: "Agriculture Water",
        path: "/agriculture_water",
    },
    {
        id: AppId.EMERA_V2, // It was called MULTI METERING APP
        title: "Emera",
        path: "/emera/v2",
    },
    {
        id: AppId.BIZ,
        title: "Biz",
        path: "/biz",
    },
    {
        id: AppId.SUPERVIOT,
        title: "Superviot",
        path: "/superviot",
    },  
    {
        id: AppId.RIOTAPP,
        title: "Riot app demo",
        path: "/riotapp",
    },
    {
        id: AppId.COMPOST,
        title: "Compost",
        path: "/compost",
    },
    {
        id: AppId.ROBOT,
        title: "Robot",
        path: "/robot",
    },
    {
        id: AppId.WATER_METERING,
        title: "H2O Connect",
        path: `/water_metering/${AppId.WATER_METERING}`,
    },
];

export default ApplicationData;
