import { useState, useEffect, useContext } from "react";
import { alpha } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import _ from "underscore";
import * as React from "react";
import Grid from "@mui/material/Grid";
import SettingContext from "Providers/SettingContext";
import ManufacturerList from "./ManufacturerList";
import ProductList from "./ProductList";
import ProductDetail from "./ProductDetail";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import Item from "../../../components/ui/Item";

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    // menuButton: {
    //     marginRight: theme.spacing(2),
    // },
    title: {
        display: "none",
        // [theme.breakpoints.up('sm')]: {
        //     display: 'block',
        // },
    },
    search: {
        position: "relative",
        // borderRadius: theme.shape.borderRadius,
        borderRadius: "5px",
        // backgroundColor: alpha(theme.palette.common.black, 0.15),
        backgroundColor: alpha("#000000", 0.15),
        // '&:hover': {
        //     backgroundColor: alpha(theme.palette.common.black, 0.25),
        // },
        "&:hover": {
            backgroundColor: alpha("#000000", 0.25),
        },
        // marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        // [theme.breakpoints.up('sm')]: {
        //     marginLeft: theme.spacing(3),
        //     width: 'auto',
        // },
        color: "#424242",
    },
    searchIcon: {
        // padding: theme.spacing(0, 2),
        height: "100%",
        width: "50px",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "grey",
    },
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        // padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        // transition: theme.transitions.create('width'),
        width: "100%",
        // [theme.breakpoints.up('md')]: {
        //     width: '20ch',
        // },
    },
    sectionDesktop: {
        display: "none",
        // [theme.breakpoints.up('md')]: {
        //     display: 'flex',
        // },
    },
    sectionMobile: {
        display: "flex",
        // [theme.breakpoints.up('md')]: {
        //     display: 'none',
        // },
    },
}));

export default function Catalog(props) {
    const { t, i18n } = useTranslation();
    const setting = useContext(SettingContext);
    const classes = useStyles();
    const appId = props.appId;

    const [keyword, setKeyword] = useState();
    const [selectedManufacturer, setSelectedManufacturer] = useState({});
    const [selectedProduct, setSelectedProduct] = useState({});

    useEffect(() => {
        // do nothing
    }, [keyword, selectedManufacturer, selectedProduct]);

    const handleSelectedManufacturer = (manufacturer) => {
        setSelectedManufacturer(manufacturer);
    };

    const handleSelectedProduct = (product) => {
        setSelectedProduct(product);
    };

    const handleKeyword = (e) => {
        setKeyword(e.target.value);
    };

    const handleClear = (e) => {
        setKeyword("");
    };

    return (
        <Box sx={{ pt: 10, pl: 2, pr: 2 }}>
            <Grid container spacing={0}>
                <Grid item xs={4} sx={{ height: 50 }}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "400px",
                            position: "relative",
                            borderRadius: "5px",
                            backgroundColor: alpha("#000000", 0.15),
                            "&:hover": {
                                backgroundColor: alpha("#000000", 0.25),
                            },
                            color: "#424242",
                            pl: 2,
                            mr: 2,
                        }}
                    >
                        <SearchIcon />
                        {/* </div> */}
                        <InputBase
                            placeholder={t("de.placeholder")}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ "aria-label": "search" }}
                            onChange={(e) => handleKeyword(e)}
                            style={{ width: "80%" }}
                            value={keyword}
                        />
                        <IconButton aria-label="delete" onClick={handleClear} size="large">
                            <ClearIcon />
                        </IconButton>
                    </Box>
                </Grid>
                <Grid item xs={8}>
                    &nbsp;
                </Grid>
                <Grid item xs={4}>
                    <Item>
                        <ManufacturerList callback={handleSelectedManufacturer} keyword={keyword}></ManufacturerList>
                    </Item>
                </Grid>
                <Grid item xs={4}>
                    <Item>
                        <ProductList selectedManufacturer={selectedManufacturer} callback={handleSelectedProduct} keyword={keyword}></ProductList>
                    </Item>
                </Grid>
                <Grid item xs={4}>
                    <Item>
                        <ProductDetail product={selectedProduct}></ProductDetail>
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
}
