import React, { useEffect, useState, useContext } from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import ChartGeneralAllService from "./ChartGeneralAllService";
import ChartPir from "./ChartPir";
import ChartGeneral from "Components/chart/ChartGeneral";
import AppContext from "Components/AppContext";
import SettingContext from "Providers/SettingContext";
import ApplicationData, { AppId } from "Components/applications/ApplicationData";

export default function ChartGeneralAll() {
    const { thingId } = useParams();
    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);
    const [attributes, setAttributes] = useState([]);

    useEffect(() => {
        setting.setGlobalSetting({ appId: AppId.EMERA_V2 });
        ctx.updateTitle(ApplicationData.find((a) => a.id === AppId.EMERA_V2).title);

        ChartGeneralAllService.requestThingModel(thingId, setAttributes);
    }, []);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 10, ml: 4, mr: 4 }}>
            {attributes.map((attr) => (
                attr.name === "pir" 
                    ? <ChartPir thingId={thingId} attributeId={attr.id} />
                    : <ChartGeneral thingId={thingId} attributeId={attr.id} />
            ))}
        </Box>
    );
}
