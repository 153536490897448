import { useEffect, useState } from "react";
import _ from "underscore";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import DeviceExplorerConstant from "./DeviceExplorerConstant";
import ButtonSwitch from "./ButtonSwitch";
import ShareService from "./ShareService";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import UserService from "Services/userService";

export default function Destination({ callback }) {
    const { t, i18n } = useTranslation();
    const [checkedMqtt, setCheckedMqtt] = useState(false);
    const [checkedHttp, setCheckedHttp] = useState(false);
    const [checkedIotCore, setCheckedIotCore] = useState(false);
    const [checkedEsri, setCheckedEsri] = useState(false);
    const [checkedSqlServer, setCheckedSqlServer] = useState(false);
    const [checkedIcicle, setCheckedIcicle] = useState(false);
    const [checkedThingsBoard, setCheckedThingsBoard] = useState(false);

    const [disableMqtt, setDisableMqtt] = useState(true);
    const [disableHttp, setDisableHttp] = useState(true);
    const [disableIotCore, setDisableIotCore] = useState(true);
    const [disableEsri, setDisableEsri] = useState(true);
    const [disableSqlServer, setDisableSqlServer] = useState(true);
    const [disableIcicle, setDisableIcicle] = useState(true);
    const [disableThingsBoard, setDisableThingsBoard] = useState(true);

    const [companyName, setCompanyName] = useState("");

    useEffect(() => {
        UserService.getAuthenticatedUser().then((user) => {
            setCompanyName(user.company);

            ShareService.checkFlowTargetIncludeSub(DeviceExplorerConstant.FORWARD_KEY_MQTT, user.company, setCheckedMqtt, setDisableMqtt);
            ShareService.checkFlowTargetIncludeSub(DeviceExplorerConstant.FORWARD_KEY_HTTP, user.company, setCheckedHttp, setDisableHttp);
            ShareService.checkFlowTargetIncludeSub(DeviceExplorerConstant.FORWARD_KEY_AWS_IOT, user.company, setCheckedIotCore, setDisableIotCore);
            ShareService.checkFlowTargetIncludeSub(DeviceExplorerConstant.FORWARD_KEY_ESRI_VELOCITY, user.company, setCheckedEsri, setDisableEsri);
            ShareService.checkFlowTargetIncludeSub(DeviceExplorerConstant.FORWARD_KEY_SQL_SERVER, user.company, setCheckedSqlServer, setDisableSqlServer);
            ShareService.checkFlowTargetIncludeSub(DeviceExplorerConstant.FORWARD_KEY_ICICLE, user.company, setCheckedIcicle, setDisableIcicle);
            ShareService.checkFlowTargetIncludeSub(DeviceExplorerConstant.FORWARD_KEY_THINGSBOARD, user.company, setCheckedThingsBoard, setDisableThingsBoard);
        });
    }, [checkedMqtt]);

    const clickButton = (forwardKey) => {
        console.log("clickButton", forwardKey);
        callback(forwardKey, true);
    };

    const clickSwitch = (name, enable) => {
        console.log("clickSwitch", name, enable);
        // Convert name to uppercase for case-insensitive comparison
        const upperName = name.toUpperCase();

        switch (upperName) {
            case "MQTT":
                ShareService.enableFlowFlowTargetByCompany(companyName, DeviceExplorerConstant.FORWARD_KEY_MQTT, enable).then(() => {
                    setCheckedMqtt(enable);
                });
                break;
            case "ESRI VELOCITY":
                ShareService.enableFlowFlowTargetByCompany(companyName, DeviceExplorerConstant.FORWARD_KEY_ESRI_VELOCITY, enable).then(() => {
                    setCheckedEsri(enable);
                });
                break;
            case "SQL SERVER":
                ShareService.enableFlowFlowTargetByCompany(companyName, DeviceExplorerConstant.FORWARD_KEY_SQL_SERVER, enable).then(() => {
                    setCheckedSqlServer(enable);
                });
                break;
            case "HTTP":
            case "HTTP(S)":
                ShareService.enableFlowFlowTargetByCompany(companyName, DeviceExplorerConstant.FORWARD_KEY_HTTP, enable).then(() => {
                    setCheckedHttp(enable);
                });
                break;
            case "IOT CORE":
                ShareService.enableFlowFlowTargetByCompany(companyName, DeviceExplorerConstant.FORWARD_KEY_AWS_IOT, enable).then(() => {
                    setCheckedIotCore(enable);
                });
                break;
            case "ICICLE":
                ShareService.enableFlowFlowTargetByCompany(companyName, DeviceExplorerConstant.FORWARD_KEY_ICICLE, enable).then(() => {
                    setCheckedIcicle(enable);
                });
                break;
            case "THINGSBOARD":
                ShareService.enableFlowFlowTargetByCompany(companyName, DeviceExplorerConstant.FORWARD_KEY_THINGSBOARD, enable).then(() => {
                    setCheckedThingsBoard(enable);
                });
                break;
            default:
                break;
        }
    };

    return (
        <Paper elevation={6} spacing={2} style={{ width: "100%", position: "relative" }}>
            <Box sx={{ flexGrow: 1, gap: 2, display: "flex", flexDirection: "row", flexWrap: "wrap", p: 3 }}>
                <ButtonSwitch
                    forwardKey={DeviceExplorerConstant.FORWARD_KEY_MQTT}
                    name="MQTT"
                    clickButton={clickButton}
                    clickSwitch={clickSwitch}
                    checked={checkedMqtt}
                    disableSwitch={disableMqtt}
                />
                <ButtonSwitch
                    forwardKey={DeviceExplorerConstant.FORWARD_KEY_HTTP}
                    name="Http(s)"
                    clickButton={clickButton}
                    clickSwitch={clickSwitch}
                    checked={checkedHttp}
                    disableSwitch={disableHttp}
                />
                <ButtonSwitch
                    forwardKey={DeviceExplorerConstant.FORWARD_KEY_AWS_IOT}
                    name="AWS IoT core"
                    clickButton={clickButton}
                    clickSwitch={clickSwitch}
                    checked={checkedIotCore}
                    disableSwitch={disableIotCore}
                />
                <ButtonSwitch
                    forwardKey={DeviceExplorerConstant.FORWARD_KEY_ESRI_VELOCITY}
                    name="ESRI Velocity"
                    clickButton={clickButton}
                    clickSwitch={clickSwitch}
                    checked={checkedEsri}
                    disableSwitch={disableEsri}
                />
                <ButtonSwitch
                    forwardKey={DeviceExplorerConstant.FORWARD_KEY_SQL_SERVER}
                    name="SQL Server"
                    clickButton={clickButton}
                    clickSwitch={clickSwitch}
                    checked={checkedSqlServer}
                    disableSwitch={disableSqlServer}
                />
                <ButtonSwitch
                    forwardKey={DeviceExplorerConstant.FORWARD_KEY_ICICLE}
                    name="ICICLE"
                    clickButton={clickButton}
                    clickSwitch={clickSwitch}
                    checked={checkedIcicle}
                    disableSwitch={disableIcicle}
                />
                <ButtonSwitch
                    forwardKey={DeviceExplorerConstant.FORWARD_KEY_THINGSBOARD}
                    name="ThingsBoard"
                    clickButton={clickButton}
                    clickSwitch={clickSwitch}
                    checked={checkedThingsBoard}
                    disableSwitch={disableThingsBoard}
                />
            </Box>

            <Box sx={{ ml: 2, mr: 2, mb: 2 }}>
                <Alert severity="info">
                    {t("de.contact_us")} <a href="mailto:support@pilot-things.com">support@pilot-things.com</a>
                </Alert>
            </Box>
        </Paper>
    );
}
