import ThermostatIcon from '@mui/icons-material/Thermostat';
import OpacityIcon from '@mui/icons-material/Opacity';
import CompressIcon from '@mui/icons-material/Compress';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import Co2Icon from '@mui/icons-material/Co2';
import AirIcon from '@mui/icons-material/Air';

export const getSlides = (formatValue, {
    temperature,
    humidity,
    pressure,
    light,
    motion,
    co2,
    hcho,
    tvoc,
    pm25,
    pm10
}) => [
    {
        title: "Environnement",
        measurements: [
            { 
                id: 0, 
                icon: <ThermostatIcon />, 
                value: formatValue(temperature), 
                unit: "°C", 
                label: "Température",
                min: -20,
                max: 60
            },
            { 
                id: 1, 
                icon: <OpacityIcon />, 
                value: formatValue(humidity), 
                unit: "%", 
                label: "Humidité",
                min: 0,
                max: 100
            },
            { 
                id: 3,
                icon: <CompressIcon />, 
                value: formatValue(pressure), 
                unit: "hPa", 
                label: "Pression",
                min: 800,
                max: 1200
            },
            { 
                id: 4, 
                icon: <WbSunnyIcon />, 
                value: formatValue(light), 
                unit: "lux", 
                label: "Luminosité",
                min: 0,
                max: 100000
            },
            { 
                id: 5,
                icon: <DirectionsRunIcon />, 
                value: motion ? "Détecté" : "Aucun", 
                label: "Mouvement" 
            }
        ]
    },
    {
        title: "Qualité de l'air",
        measurements: [
            { 
                id: 2,
                icon: <Co2Icon />, 
                value: formatValue(co2), 
                unit: "ppm", 
                label: "CO2",
                min: 400,
                max: 5000
            },
            { 
                id: 6,
                icon: <AirIcon />, 
                value: formatValue(hcho), 
                unit: "mg/m³", 
                label: "HCHO",
                min: 0,
                max: 1.0
            },
            { 
                id: 7,
                icon: <AirIcon />, 
                value: formatValue(tvoc), 
                unit: "ppb", 
                label: "TVOC",
                min: 0,
                max: 5000
            },
            { icon: <AirIcon />, value: formatValue(pm25), unit: "µg/m³", label: "PM2.5" },
            { icon: <AirIcon />, value: formatValue(pm10), unit: "µg/m³", label: "PM10" }
        ]
    }
];

export default getSlides;
