import { getHourFromTimestamp, isToday } from './NoiseChart';

export const getChartOptions = (alarms, dayData) => ({
    credits: {
        enabled: false,
    },
    chart: {
        type: "arearange",
        height: 300,
        backgroundColor: "transparent",
    },
    title: {
        text: null,
    },
    xAxis: {
        categories: Array.from({ length: 24 }, (_, i) => String(i)), // Suppression du padStart
        labels: {
            style: {
                fontSize: "12px",
            },
        },
        crosshair: {
            width: 40,
            color: "rgba(101, 241, 113, 0.56)",
            zIndex: 5,
            snap: false,
        },
        plotBands: (() => {
            const missingHours = [];
            for (let i = 0; i < 24; i++) {
                const hour = String(i).padStart(2, "0");
                if (!dayData.find((d) => d.hour === hour)) {
                    missingHours.push(i);
                }
            }
            
            // Group consecutive hours
            const bands = [];
            let currentBand = null;
            
            missingHours.forEach((hour) => {
                if (!currentBand || hour !== currentBand.to + 0.5) {
                    // Start new band
                    currentBand = {
                        color: "rgba(255, 0, 0, 0.3)",
                        from: hour - 0.5,
                        to: hour + 0.5,
                        zIndex: 1,
                        label: {
                            text: "Données<br>manquantes",
                            align: "center",
                            verticalAlign: "middle",
                            y: 0,
                            x: 0,
                            style: {
                                color: "#666666",
                                fontSize: "10px",
                                fontWeight: "bold",
                                textAlign: "center",
                                width: "100%",
                                lineHeight: "12px",
                            },
                            rotation: 0,
                            useHTML: true,
                            zIndex: 2,
                        }
                    };
                    bands.push(currentBand);
                } else {
                    // Extend current band
                    currentBand.to = hour + 0.5;
                }
            });
            
            return bands;
        })(),
    },
    yAxis: {
        labels: {
            enabled: true,  // Active les labels de l'axe Y
            style: {
                fontSize: '12px'
            }
        },
        title: {
            text: null  // Garde le titre vide
        },
        gridLineWidth: 1,  // Ajoute les lignes de grille
        gridLineColor: '#E0E0E0',  // Couleur claire pour les lignes
        tickInterval: 20  // Intervalle entre les graduations
    },
    tooltip: {
        shared: true,
        crosshairs: true,
        useHTML: true,
        followPointer: true,  // Suit le pointeur de la souris
        formatter: function () {
            const point = this.points?.[0];
            if (!point) return false;

            const hour = point.x;
            const nextHour = (hour + 1) % 24;
            const data = dayData.find(d => d.hour === String(hour).padStart(2, '0'));
            
            if (!data) {
                return `<div style="padding: 8px;">
                    <b>${hour}h-${nextHour}h</b><br/>
                    Données non disponibles
                </div>`;
            }

            return `<div style="padding: 8px;">
                <b>${hour}h-${nextHour}h</b><br/>
                Max: ${data.max} dBA<br/>
                Min: ${data.min} dBA<br/>
                Moyenne: ${data.avg} dBA
            </div>`;
        },
        backgroundColor: 'rgba(255, 255, 255, 0.9)',  // Fond blanc semi-transparent
        borderWidth: 1,
        borderColor: '#AAA',
        borderRadius: 4,
        shadow: true
    },
    series: [
        {
            showInLegend: false,
            name: "Plage",
            data: Array.from({ length: 24 }, (_, i) => {
                const hour = String(i).padStart(2, "0");
                const data = dayData.find((d) => d.hour === hour);
                return data ? [data.min, data.max] : [null, null];
            }),
            color: "rgba(150, 150, 150, 0.3)",
            fillOpacity: 0.3,
            zIndex: 0,
            marker: {
                enabled: false,
            }, 
        },
        {
            showInLegend: false,
            name: "Moyenne",
            type: "line",
            data: Array.from({ length: 24 }, (_, i) => {
                const hour = String(i).padStart(2, "0");
                const data = dayData.find((d) => d.hour === hour);
                return data ? data.avg : null;
            }),
            color: "black",
            zIndex: 1,
            marker: {
                enabled: true,
                symbol: "circle",
                radius: 2,
                fillColor: "black",
                lineWidth: 2,
                lineColor: "black",
            },
        },
    ],
    annotations: [
        {
            draggable: "",
            labelOptions: {
                backgroundColor: "rgba(255,255,255,0.8)",
                verticalAlign: "top",
                padding: 5,
                borderRadius: 3,
                borderWidth: 1,
                borderColor: "#AAA",
                distance: -35,
            },
            labels: alarms
                .filter((alarm) => isToday(alarm.timestamp))
                .map((alarm) => {
                    let color;
                    let text;
                    switch (alarm.severity) {
                        case "critical":
                            color = "#d32f2f";
                            text = "Critique";
                            break;
                        case "warning":
                            color = "#ed6c02";
                            text = "Attention";
                            break;
                        case "info":
                            color = "#0288d1";
                            text = "Info";
                            break;
                        default:
                            color = "gray";
                            text = "Info";
                    }
                    const hour = getHourFromTimestamp(alarm.timestamp);
                    return {
                        point: {
                            x: hour,
                            y: parseInt(alarm.value),
                            xAxis: 0,
                            yAxis: 0,
                        },
                        text: text,
                        style: {
                            fontSize: "12px",
                            color: color,
                            fontWeight: 500,
                        },
                    };
                }),
        },
    ],
    exporting: {
        enabled: false
    },
});
