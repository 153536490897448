import { useEffect, useState, createRef } from "react";
import Button from "@mui/material/Button";
import AlertDialog from "Components/ui/AlertDialog";
import AlertService from "./AlertService";
import _ from "underscore";
import { v4 as uuidv4 } from "uuid";
import UtilService from "Services/utilService";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";
import { DataGridPro, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarExport, GridToolbarDensitySelector } from "@mui/x-data-grid-pro";
import ToggleButton from "./ToggleButton";
import store from "store";
import { frFR } from "@mui/x-data-grid/locales";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("53264e234090f26bf650c109bcf9fc2fTz0xMDUxMDMsRT0xNzY5Mjg1NDAxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

const KEY_PREFIX = "sa.alert.";
const KEY_FILTER = "sa.alert.filter";

function createData(id, creationDate, level, message, fixedName, comment, lastUpdate, state, username) {
    return {
        id,
        creationDate: UtilService.formateDate(creationDate * 1000),
        level,
        message,
        fixedName,
        comment,
        lastUpdate: UtilService.formateDate(lastUpdate * 1000),
        state,
        username,
    };
}

let columns = [
    {
        field: "creationDate",
        headerName: "Creation Date",
        flex: 0.7,
        editable: false,
        sortable: true,
        filterable: false,
        // hide: false
        hide: store.get(KEY_PREFIX + "creationDate") == undefined ? false : !store.get(KEY_PREFIX + "creationDate"),
    },
    {
        field: "level",
        headerName: "Level",
        flex: 0.4,
        editable: false,
        sortable: true,
        filterable: false,
        renderCell: (params) => {
            let labelStyle = style.default;
            switch (params.value) {
                case "WARNING":
                    labelStyle = style.warning;
                    break;
                case "CRITICAL":
                    labelStyle = style.critical;
                    break;
                default:
                    break;
            }
            return <span className={`${style.badge} ${style.label} ${labelStyle}`}>{params.value}</span>;
        },
        // hide: false
        hide: store.get(KEY_PREFIX + "level") == undefined ? false : !store.get(KEY_PREFIX + "level"),
    },
    {
        field: "message",
        headerName: "Message",
        flex: 1.5,
        editable: false,
        sortable: true,
        filterable: false,
        // hide : false
        hide: store.get(KEY_PREFIX + "message") == undefined ? false : !store.get(KEY_PREFIX + "message"),
    },
    {
        field: "fixedName",
        headerName: "Thing",
        flex: 0.7,
        editable: false,
        sortable: true,
        filterable: false,
        // hide : false
        hide: store.get(KEY_PREFIX + "fixedName") == undefined ? false : !store.get(KEY_PREFIX + "fixedName"),
    },
    {
        field: "comment",
        headerName: "Comment",
        flex: 1,
        editable: false,
        sortable: true,
        filterable: false,
        // hide: true
        hide: store.get(KEY_PREFIX + "comment") == undefined ? false : !store.get(KEY_PREFIX + "comment"),
    },
    {
        field: "lastUpdate",
        headerName: "Last update",
        flex: 1,
        editable: false,
        sortable: true,
        filterable: false,
        // hide: true
        hide: store.get(KEY_PREFIX + "lastUpdate") == undefined ? false : !store.get(KEY_PREFIX + "lastUpdate"),
    },
    {
        field: "state",
        headerName: "State",
        flex: 0.4,
        editable: false,
        sortable: false,
        renderCell: (params) => {
            let stateStyle = style.default;
            if (params.value != undefined) {
                switch (params.value.toUpperCase()) {
                    case "OPENED":
                        stateStyle = style.critical;
                        break;
                    case "RESOLVED":
                        stateStyle = style.success;
                        break;
                    case "IN_PROGRESS":
                        stateStyle = style.in_progress;
                        break;
                    case "IGNORED":
                        stateStyle = style.ignored;
                        break;
                    default:
                        break;
                }
            }
            return <span className={`${style.badge} ${style.label} ${stateStyle}`}>{params.value == undefined ? "" : params.value}</span>;
        },
        // hide: false
        hide: store.get(KEY_PREFIX + "state") == undefined ? false : !store.get(KEY_PREFIX + "state"),
    },
    {
        field: "username",
        headerName: "User name",
        flex: 1,
        editable: false,
        sortable: true,
        filterable: false,
        // hide: true,
        hide: store.get(KEY_PREFIX + "username") == undefined ? false : !store.get(KEY_PREFIX + "username"),
    },
];

export default function Alerts(props) {
    const dataGrid = createRef();
    const { t, i18n } = useTranslation();

    const thingId = props.thingId; // single thing
    const attributeId = props.attributeId;
    const things = props.things; // multiple things

    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("creationDate");
    const [selected, setSelected] = useState([]);

    const [rows, setRows] = useState([]);
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 15 });
    const [rowCount, setRowCount] = useState(0);

    const [isOpenModal, setIsOpenModal] = useState(false);

    const [requestedState, setRequestedState] = useState("IGNORED");
    const [alertState, setAlertState] = useState("");
    const [comment, setComment] = useState("");

    const [filterState, setFilterState] = useState();

    const [selectionModel, setSelectionModel] = useState([]);
    const [loading, setLoading] = useState(false);
    const [on, setOn] = useState(false);

    const handlerIgnore = (e) => {
        setIsOpenModal(true);
        setRequestedState("IGNORED");
    };

    const handlerInprogress = (e) => {
        setIsOpenModal(true);
        setRequestedState("IN_PROGRESS");
    };

    const handlerResolve = (e) => {
        setIsOpenModal(true);
        setRequestedState("RESOLVED");
    };

    const handleValidate = (comment) => {
        AlertService.updateAlertState(selected, comment, requestedState, reload);
    };

    const reload = (reboot) => {
        if (reboot) {
            window.location.reload(false);
        }
    };

    useEffect(() => {
        // console.log("useEffect in Alert");
        // initializeRows();

        // let active = true;
        // (async () => {
        // 	setLoading(true);

        if (thingId == undefined && things == undefined) {
            AlertService.getEnableGenerateByCompany(setOn);
        } else if (thingId != undefined) {
            AlertService.getEnableGenerateByThingId(setOn, thingId);
        } else if (things && things[0]) {
            AlertService.getEnableGenerateByThingId(setOn, things[0].thingId);
        }

        let filterQuery = "";
        if (filterState != undefined && filterState != "") {
            if ("OPENED".indexOf(filterState) != -1) {
                filterQuery = "OPENED";
            } else if ("RESOLVED".indexOf(filterState) != -1) {
                filterQuery = "RESOLVED";
            } else if ("IN_PROGRESS".indexOf(filterState) != -1) {
                filterQuery = "IN_PROGRESS";
            } else if ("IGNORED".indexOf(filterState) != -1) {
                filterQuery = "IGNORED";
            } else {
                filterQuery = filterState;
            }
        }

        AlertService.requestAlerts(
            paginationModel.page,
            paginationModel.pageSize,
            createData,
            setRows,
            setRowCount,
            thingId,
            attributeId,
            order,
            orderBy,
            filterQuery,
            props.connectivity
        );

        // if (!active) {
        // 	return;
        // }

        // setLoading(false);
        setFilterState(store.get(KEY_FILTER));
        // })();
    }, [paginationModel.page, paginationModel.pageSize, filterState, columns, props.connectivity]);

    const initializeRows = () => {
        let arrInit = [];
        _.each(rows, function (row) {
            arrInit.push({ id: uuidv4() }); // id must be entered in the datagrid.
        });
        setRows(arrInit);
    };

    const handleSortModelChange = (param) => {
        let sortModel = param;

        if (Array.isArray(sortModel) && sortModel.length == 1) {
            setOrder(sortModel[0].sort);
            setOrderBy(sortModel[0].field);

            AlertService.requestAlerts(
                paginationModel.page,
                paginationModel.pageSize,
                createData,
                setRows,
                setRowCount,
                thingId,
                attributeId,
                sortModel[0].sort,
                sortModel[0].field,
                filterState
            );
        } else {
            AlertService.requestAlerts(
                paginationModel.page,
                paginationModel.pageSize,
                createData,
                setRows,
                setRowCount,
                thingId,
                attributeId,
                "desc",
                "creationDate",
                filterState
            );
        }
    };

    const handleRowClick = (params) => {
        setSelected([params.id]);
        setAlertState(() => params.row.state);
    };

    const handleSelectionModel = (selectionModel) => {
        setSelectionModel(selectionModel);
    };

    const handleFilterModelChange = (param) => {
        if (Array.isArray(param.items)) {
            const items = param.items;
            if (items.length > 0 && items[0].value != undefined) {
                const optValue = items[0].value.toUpperCase();
                store.set(KEY_FILTER, optValue);

                if (optValue != "") {
                    if ("OPENED".indexOf(optValue) != -1) {
                        setFilterState("OPENED");
                    } else if ("RESOLVED".indexOf(optValue) != -1) {
                        setFilterState("RESOLVED");
                    } else if ("IN_PROGRESS".indexOf(optValue) != -1) {
                        setFilterState("IN_PROGRESS");
                    } else if ("IGNORED".indexOf(optValue) != -1) {
                        setFilterState("IGNORED");
                    } else {
                        setFilterState(optValue);
                    }
                }
            } else {
                setFilterState(undefined);
                store.remove(KEY_FILTER);
            }
        } else {
            setFilterState(undefined);
            store.remove(KEY_FILTER);
        }
        // setFilterValue(filterModel.items[0].value);
    };

    const handleVisibilityChange = (params) => {
        store.set(KEY_PREFIX + params.field, params.isVisible); // (sa.alert.lastUpdate : true)
        resetColumns();
    };

    const resetColumns = () => {
        columns[0].hide = store.get(KEY_PREFIX + "creationDate") == undefined ? false : !store.get(KEY_PREFIX + "creationDate");
        columns[1].hide = store.get(KEY_PREFIX + "level") == undefined ? false : !store.get(KEY_PREFIX + "level");
        columns[2].hide = store.get(KEY_PREFIX + "message") == undefined ? false : !store.get(KEY_PREFIX + "message");
        columns[3].hide = store.get(KEY_PREFIX + "fixedName") == undefined ? false : !store.get(KEY_PREFIX + "fixedName");
        columns[4].hide = store.get(KEY_PREFIX + "comment") == undefined ? false : !store.get(KEY_PREFIX + "comment");
        columns[5].hide = store.get(KEY_PREFIX + "lastUpdate") == undefined ? false : !store.get(KEY_PREFIX + "lastUpdate");
        columns[6].hide = store.get(KEY_PREFIX + "state") == undefined ? false : !store.get(KEY_PREFIX + "state");
        columns[7].hide = store.get(KEY_PREFIX + "username") == undefined ? false : !store.get(KEY_PREFIX + "username");
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
                <ToggleButton callback={handleToggle} on={on} all={thingId == undefined && things == undefined ? true : false}></ToggleButton>
            </GridToolbarContainer>
        );
    }

    function handleToggle(param) {
        setOn(() => {
            if (thingId != undefined) {
                AlertService.enableGenerate(param, [thingId]);
            } else if (things != undefined) {
                let thingIds = [];
                _.forEach(things, (t) => thingIds.push(t.thingId));

                AlertService.enableGenerate(param, thingIds);
            } else {
                AlertService.enableGenerate(param);
            }
            return param;
        });
    }

    return (
        <div className={"d-flex flex-column"} style={{ marginTop: "30px" }}>
            <div className="container-fluid" style={{ width: "100%" }}>
                <div className="row" style={{ width: "100%" }}>
                    <div className={"col-4" + style.w_title} style={{ width: "50%" }}>
                        <span style={{ fontFamily: "Days One", fontSize: "24px", fontWeight: "normal", color: "#9B9B9B" }}>{t("smart_ag.alerts")}</span>
                    </div>
                    <div className="col" style={{ width: "50%", textAlign: "right" }}>
                        {alertState === "IN_PROGRESS" && (
                            <>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ textTransform: "unset", backgroundColor: "rgb(156,201,107)" }}
                                    onClick={(e) => handlerResolve(e)}
                                >
                                    Resolve
                                </Button>
                            </>
                        )}
                        {alertState != "IGNORED" && alertState != "RESOLVED" && alertState != "IN_PROGRESS" && (
                            <>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ textTransform: "unset", backgroundColor: "rgb(148,148,149)" }}
                                    onClick={(e) => handlerIgnore(e)}
                                >
                                    Ignore
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ textTransform: "unset", backgroundColor: "rgb(251,192,45)", marginLeft: "5px" }}
                                    onClick={(e) => handlerInprogress(e)}
                                >
                                    In progress
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column" style={{ overflow: "hidden", marginTop: "10px" }}>
                <div className="d-flex flex-column" style={{ height: 600, width: "100%", overflow: "hidden" }}>
                    <DataGridPro
                        ref={dataGrid}
                        rows={rows}
                        columns={columns}
                        paginationModel={paginationModel}
                        rowCount={rowCount}
                        pagination={true}
                        paginationMode="server"
                        sortingMode="server"
                        pageSizeOptions={[15, 50, 100]}
                        onPaginationModelChange={setPaginationModel}
                        onSortModelChange={handleSortModelChange}
                        onRowClick={handleRowClick}
                        checkboxSelection={false}
                        components={{
                            // Toolbar: GridToolbar,
                            Toolbar: CustomToolbar,
                        }}
                        onRowSelectionModelChange={(newSelection) => handleSelectionModel(newSelection)}
                        density="compact"
                        filterModel={{
                            items: [{ columnField: "state", operatorValue: "contains", value: filterState }],
                        }}
                        filterMode="server"
                        disableMultipleColumnsFiltering={true}
                        onFilterModelChange={handleFilterModelChange}
                        onColumnVisibilityChange={handleVisibilityChange}
                        disableColumnReorder={true}
                        sx={{
                            "& .MuiTablePagination-selectLabel": {
                                marginTop: 2,
                            },
                            "& .MuiTablePagination-displayedRows": {
                                marginTop: 2,
                            },
                        }}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                        }}
                        localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
                    />
                </div>
            </div>
            <AlertDialog
                onClose={() => setIsOpenModal(false)}
                title="Alerts follow-up"
                label="Comment"
                changeValues={handleValidate}
                open={isOpenModal}
                message={comment}
            />
        </div>
    );
}
