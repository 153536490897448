import { useState, useEffect, useRef, useContext } from "react";
import _ from "underscore";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Destination from "./Destination";
import SetupMQTT from "./SetupMQTT";
import SetupIotCore from "./SetupIotCore";
import SetupIotESRI from "./SetupIotESRI";
import SetupHTTP from "./SetupHTTP";
import DeviceExplorerConstant from "./DeviceExplorerConstant";
import SetupComplete from "./SetupComplete";
import style from "./style.module.css";
import SetupSQLserver from "./SetupSQLserver";
import { useTranslation } from "react-i18next";
import SettingContext from "Providers/SettingContext";
import SetupIcicle from "./SetupIcicle";
import UserService from "Services/userService";
import { useParams } from "react-router-dom";
import SetupThingsBoard from "./SetupThingsBoard";
import { AppId } from "Components/applications/ApplicationData";

export default function SenditContainer() {
    const { t, i18n } = useTranslation();
    const setting = useContext(SettingContext);

    const params = useParams();
    const appId = params.appId;

    const [forwardKey, setForwardKey] = useState(DeviceExplorerConstant.FORWARD_KEY_UNKNOWN);
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [on, setOn] = useState(false);

    const refMqtt = useRef(null);

    const [currentLanguage, setCurrentLanguage] = useState();
    const [steps, setSteps] = useState();
    const [userCompany, setUserCompany] = useState();

    useEffect(() => {
        setting.setGlobalSetting({
            appId: AppId.DEVICE_EXPLORER,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });

        const lang = i18n.language;
        // console.log("Current language:", lang);

        if (lang !== currentLanguage) {
            setCurrentLanguage(lang);
            setSteps([t("de.step_connectors"), t("de.step_settings"), t("de.step_share")]);
        }

        UserService.getAuthenticatedUser().then((u) => {
            // console.log(u);
            // setUsername(u.cognitoUserName);
            setUserCompany(u.company);
            return u.cognitoUserName;
        });

        // console.log(steps);
    }, [i18n, setting.language, steps, t]);

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleStep = (step) => () => {
        if (activeStep != 0) {
            setActiveStep(step);

            if (step == 0) {
                goFirstPage();
            }

            if (step == 1) {
                setForwardKey(forwardKey);
            }

            if (activeStep == 1 && step == 2) {
                goCompletePage();
            }
        }
    };

    const callback = (selectedForwardKey, on) => {
        setForwardKey(selectedForwardKey);
        setActiveStep(1);
        setOn(on);
    };

    const goFirstPage = () => {
        setActiveStep(0);
        setForwardKey(DeviceExplorerConstant.FORWARD_KEY_UNKNOWN);
    };

    const goCompletePage = () => {
        setActiveStep(2);
    };

    return (
        <div className={style.wrapper} style={{ width: "100%" }}>
            <Grid container spacing={3}>
                <Grid item xs={2}>
                    &nbsp;
                </Grid>
                <Grid item xs={8} margin={1} sx={{ display: "flex", alignItems: "center" }}>
                    <Stepper nonLinear activeStep={activeStep} sx={{ width: "100%" }}>
                        {steps &&
                            steps.map((label, index) => {
                                const stepProps = {};

                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepButton color="inherit" onClick={handleStep(index)}>
                                            {label}
                                        </StepButton>
                                    </Step>
                                );
                            })}
                    </Stepper>
                </Grid>
                <Grid item xs={2}>
                    &nbsp;
                </Grid>
            </Grid>

            <Grid container spacing={3} alignItems={"center"}>
                <Grid item xs={2}>
                    &nbsp;
                </Grid>
                <Grid item xs={8} margin={1} sx={{ display: "flex", width: "100%" }}>
                    {forwardKey === DeviceExplorerConstant.FORWARD_KEY_UNKNOWN && <Destination callback={callback} />}
                    {activeStep === 1 && forwardKey === DeviceExplorerConstant.FORWARD_KEY_MQTT && (
                        <SetupMQTT
                            ref={refMqtt}
                            goFirstPage={goFirstPage}
                            goCompletePage={goCompletePage}
                            setActiveStep={setActiveStep}
                            on={on}
                            userCompany={userCompany}
                        ></SetupMQTT>
                    )}
                    {activeStep === 1 && forwardKey === DeviceExplorerConstant.FORWARD_KEY_HTTP && (
                        <SetupHTTP
                            goFirstPage={goFirstPage}
                            goCompletePage={goCompletePage}
                            setActiveStep={setActiveStep}
                            on={on}
                            userCompany={userCompany}
                        ></SetupHTTP>
                    )}
                    {activeStep === 1 && forwardKey === DeviceExplorerConstant.FORWARD_KEY_AWS_IOT && (
                        <SetupIotCore
                            goFirstPage={goFirstPage}
                            goCompletePage={goCompletePage}
                            setActiveStep={setActiveStep}
                            on={on}
                            userCompany={userCompany}
                        ></SetupIotCore>
                    )}
                    {activeStep === 1 && forwardKey === DeviceExplorerConstant.FORWARD_KEY_ESRI_VELOCITY && (
                        <SetupIotESRI
                            goFirstPage={goFirstPage}
                            goCompletePage={goCompletePage}
                            setActiveStep={setActiveStep}
                            on={on}
                            userCompany={userCompany}
                        ></SetupIotESRI>
                    )}
                    {activeStep === 1 && forwardKey === DeviceExplorerConstant.FORWARD_KEY_SQL_SERVER && (
                        <SetupSQLserver
                            goFirstPage={goFirstPage}
                            goCompletePage={goCompletePage}
                            setActiveStep={setActiveStep}
                            on={on}
                            userCompany={userCompany}
                        ></SetupSQLserver>
                    )}
                    {activeStep === 1 && forwardKey === DeviceExplorerConstant.FORWARD_KEY_ICICLE && (
                        <SetupIcicle
                            goFirstPage={goFirstPage}
                            goCompletePage={goCompletePage}
                            setActiveStep={setActiveStep}
                            on={on}
                            userCompany={userCompany}
                        ></SetupIcicle>
                    )}
                    {activeStep === 1 && forwardKey === DeviceExplorerConstant.FORWARD_KEY_THINGSBOARD && (
                        <SetupThingsBoard
                            goFirstPage={goFirstPage}
                            goCompletePage={goCompletePage}
                            setActiveStep={setActiveStep}
                            on={on}
                            userCompany={userCompany}
                        ></SetupThingsBoard>
                    )}
                    {activeStep === 2 && <SetupComplete appId={appId} forwardKey={forwardKey} userCompany={userCompany}></SetupComplete>}
                </Grid>
                <Grid item xs={2}>
                    &nbsp;
                </Grid>
            </Grid>
        </div>
    );
}
