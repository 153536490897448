import { useEffect, useState, createRef } from "react";
import Button from "@mui/material/Button";
import AlertDialog from "./AlertDialog";
import AlertService from "./AlertService";
import _ from "underscore";
import { useTranslation } from "react-i18next";
import { DataGridPro, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarExport, GridToolbarDensitySelector } from "@mui/x-data-grid-pro";
import ToggleButton from "./ToggleButton";
import store from "store";
import Box from "@mui/material/Box";
import { LicenseInfo } from "@mui/x-license-pro";
import { frFR } from "@mui/x-data-grid/locales";
import Grid from "@mui/material/Grid";
import { columns } from "./AlertColumns";
LicenseInfo.setLicenseKey("53264e234090f26bf650c109bcf9fc2fTz0xMDUxMDMsRT0xNzY5Mjg1NDAxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

const KEY_PREFIX = "emera.alert.";
const KEY_FILTER = "emera.alert.filter";

export default function Alerts({ thingId, attributeId, things, connectivity, hideOnOff = false, companyId }) {
    const dataGrid = createRef();
    const { t, i18n } = useTranslation();

    const [selected, setSelected] = useState([]);

    const [paging, setPaginationModel] = useState({ page: 0, pageSize: 15 });
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [sortModel, setSortModel] = useState([{ field: "creationDate", sort: "desc" }]);

    const [open, setOpen] = useState(false);

    const [requestedState, setRequestedState] = useState("IGNORED");
    const [alertState, setAlertState] = useState("");
    const [comment, setComment] = useState("");

    const [filterState, setFilterState] = useState();

    const [selectionModel, setSelectionModel] = useState([]);
    const [on, setOn] = useState(false);

    const handlerIgnore = (e) => {
        setOpen(true);
        setRequestedState("IGNORED");
    };

    const handlerInprogress = (e) => {
        setOpen(true);
        setRequestedState("IN_PROGRESS");
    };

    const handlerResolve = (e) => {
        setOpen(true);
        setRequestedState("RESOLVED");
    };

    const handleValidate = (comment) => {
        AlertService.updateAlertState(selected, comment, requestedState, reload);
    };

    const reload = (reboot) => {
        if (reboot) {
            window.location.reload(false);
        }
    };

    useEffect(() => {
        console.log("useEffect in Alert");
        console.log(thingId, companyId);

        if (thingId == undefined && things == undefined) {
            AlertService.getEnableGenerateByCompany(setOn);
        } else if (thingId != undefined) {
            AlertService.getEnableGenerateByThingId(setOn, thingId);
        } else if (things && things[0]) {
            AlertService.getEnableGenerateByThingId(setOn, things[0].thingId);
        }

        let filterQuery = "";
        if (filterState != undefined && filterState != "") {
            if ("OPENED".indexOf(filterState) != -1) {
                filterQuery = "OPENED";
            } else if ("RESOLVED".indexOf(filterState) != -1) {
                filterQuery = "RESOLVED";
            } else if ("IN_PROGRESS".indexOf(filterState) != -1) {
                filterQuery = "IN_PROGRESS";
            } else if ("IGNORED".indexOf(filterState) != -1) {
                filterQuery = "IGNORED";
            } else {
                filterQuery = filterState;
            }
        }

        AlertService.requestAlerts(paging.page, paging.pageSize, setRows, setRowCount, thingId, attributeId, sortModel, filterQuery, connectivity, companyId);

        setFilterState(store.get(KEY_FILTER));
    }, [paging, filterState, columns, connectivity, sortModel, companyId]);

    const handleRowClick = (params) => {
        setSelected([params.id]);
        setAlertState(() => params.row.state);
    };

    const handleSelectionModel = (selectionModel) => {
        // console.log(selectionModel);
        // setSelectionModel(selectionModel);

        if (selectionModel.length > 1) {
            const selectedRow = selectionModel[selectionModel.length - 1];
            setSelectionModel([selectedRow]);
        } else {
            setSelectionModel(selectionModel);
        }
    };

    const handleFilterModelChange = (param) => {
        if (Array.isArray(param.items)) {
            const items = param.items;
            if (items.length > 0 && items[0].value != undefined) {
                const optValue = items[0].value.toUpperCase();
                store.set(KEY_FILTER, optValue);

                if (optValue != "") {
                    if ("OPENED".indexOf(optValue) != -1) {
                        setFilterState("OPENED");
                    } else if ("RESOLVED".indexOf(optValue) != -1) {
                        setFilterState("RESOLVED");
                    } else if ("IN_PROGRESS".indexOf(optValue) != -1) {
                        setFilterState("IN_PROGRESS");
                    } else if ("IGNORED".indexOf(optValue) != -1) {
                        setFilterState("IGNORED");
                    } else {
                        setFilterState(optValue);
                    }
                }
            } else {
                setFilterState(undefined);
                store.remove(KEY_FILTER);
            }
        } else {
            setFilterState(undefined);
            store.remove(KEY_FILTER);
        }
        // setFilterValue(filterModel.items[0].value);
    };

    const handleVisibilityChange = (params) => {
        store.set(KEY_PREFIX + params.field, params.isVisible); // (sa.alert.lastUpdate : true)
        resetColumns();
    };

    const resetColumns = () => {
        columns[0].hide = store.get(KEY_PREFIX + "creationDate") == undefined ? false : !store.get(KEY_PREFIX + "creationDate");
        columns[1].hide = store.get(KEY_PREFIX + "level") == undefined ? false : !store.get(KEY_PREFIX + "level");
        columns[2].hide = store.get(KEY_PREFIX + "message") == undefined ? false : !store.get(KEY_PREFIX + "message");
        columns[3].hide = store.get(KEY_PREFIX + "fixedName") == undefined ? false : !store.get(KEY_PREFIX + "fixedName");
        columns[4].hide = store.get(KEY_PREFIX + "comment") == undefined ? false : !store.get(KEY_PREFIX + "comment");
        columns[5].hide = store.get(KEY_PREFIX + "lastUpdate") == undefined ? false : !store.get(KEY_PREFIX + "lastUpdate");
        columns[6].hide = store.get(KEY_PREFIX + "state") == undefined ? false : !store.get(KEY_PREFIX + "state");
        columns[7].hide = store.get(KEY_PREFIX + "username") == undefined ? false : !store.get(KEY_PREFIX + "username");
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
                {!hideOnOff && <ToggleButton callback={handleToggle} on={on} all={thingId == undefined && things == undefined ? true : false}></ToggleButton>}
            </GridToolbarContainer>
        );
    }

    function handleToggle(param) {
        setOn(() => {
            if (thingId != undefined) {
                AlertService.enableGenerate(param, [thingId]);
            } else if (things != undefined) {
                let thingIds = [];
                _.forEach(things, (t) => thingIds.push(t.thingId));

                AlertService.enableGenerate(param, thingIds);
            } else {
                AlertService.enableGenerate(param);
            }
            return param;
        });
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={6}>
                    <span style={{ fontFamily: "Days One", fontSize: "24px", fontWeight: "normal", color: "#9B9B9B" }}>{t("emera_v2.alerts_title")}</span>
                </Grid>
                <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    {alertState === "IN_PROGRESS" && (
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ textTransform: "unset", backgroundColor: "rgb(156,201,107)" }}
                                onClick={(e) => handlerResolve(e)}
                            >
                                Resolve
                            </Button>
                        </>
                    )}
                    {alertState != "IGNORED" && alertState != "RESOLVED" && alertState != "IN_PROGRESS" && (
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ textTransform: "unset", backgroundColor: "rgb(148,148,149)" }}
                                onClick={(e) => handlerIgnore(e)}
                            >
                                Ignore
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ textTransform: "unset", backgroundColor: "rgb(251,192,45)", marginLeft: "5px" }}
                                onClick={(e) => handlerInprogress(e)}
                            >
                                In progress
                            </Button>
                        </>
                    )}
                </Grid>
                <Grid item xs={12} sx={{ height: 700 }}>
                    <DataGridPro
                        ref={dataGrid}
                        rows={rows}
                        columns={columns}
                        rowCount={rowCount}
                        pagination={true}
                        paginationMode="server"
                        sortingMode="server"
                        paginationModel={paging}
                        onPaginationModelChange={setPaginationModel}
                        pageSizeOptions={[15, 50, 100]}
                        onSortModelChange={setSortModel}
                        onRowClick={handleRowClick}
                        checkboxSelection={false}
                        components={{
                            // Toolbar: GridToolbar,
                            Toolbar: CustomToolbar,
                        }}
                        selectionModel={selectionModel}
                        onRowSelectionModelChange={(newSelection) => handleSelectionModel(newSelection)}
                        density="compact"
                        filterModel={{
                            items: [{ field: "state", operator: "contains", value: filterState }],
                        }}
                        filterMode="server"
                        disableMultipleColumnsFiltering={true}
                        onFilterModelChange={handleFilterModelChange}
                        onColumnVisibilityChange={handleVisibilityChange}
                        disableColumnReorder={true}
                        sx={{
                            "& .MuiTablePagination-selectLabel": {
                                marginTop: 2,
                            },
                            "& .MuiTablePagination-displayedRows": {
                                marginTop: 2,
                            },
                        }}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                    comment: false,
                                    username: false,
                                },
                            },
                        }}
                        localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
                    />
                </Grid>
            </Grid>

            <AlertDialog
                open={open}
                setOpen={(message) => {
                    setOpen(false);
                    if (message) {
                        handleValidate(message);
                    }
                }}
                message={comment}
            />
        </Box>
    );
}
