import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "underscore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import ConfirmDialog from "./ConfirmDialog";
import ShareService from "./ShareService";
import validator from "validator";
import CustomStyle from "Components/CustomStyle";
import Checkbox from "@mui/material/Checkbox";

export default function SetupIotESRI({ setActiveStep, goCompletePage, goFirstPage, on, userCompany }) {
    let history = useHistory();
    let errors = {
        url: false,
        headers: false,
        timezone: false,
    };

    const [companyName, setCompanyName] = useState(userCompany);

    const [flowId, setFlowId] = useState();
    const [forwardTargetId, setForwardTargetId] = useState();

    const [enabled, setEnabled] = useState(true);
    const [description, setDescription] = useState();
    const [url, setUrl] = useState();
    const [headers, setHeaders] = useState();
    const [timezone, setTimezone] = useState("");

    const [allTimezone, setAllTimezone] = useState([]);
    const [open, setOpen] = useState(false);
    const loading = open && allTimezone.length === 0;

    useEffect(() => {
        setActiveStep(1);

        let active = true;

        ShareService.requestTimezone(setAllTimezone);

        ShareService.getFlowTargetEsri(companyName, setFlowId, setForwardTargetId, setEnabled, setDescription, setUrl, setHeaders, setTimezone);
    }, [userCompany, companyName, loading]);

    const handleSave = () => {
        if (!isValidate()) {
            return;
        }

        ShareService.getFlowTargetEsri(companyName, setFlowId, setForwardTargetId, setEnabled, setDescription, setUrl, setHeaders, setTimezone).then(() => {
            if (!forwardTargetId) {
                // create a flow, enabled=false
                ShareService.createFlow(companyName, "Share-ESRI").then((flowId) => {
                    // Insert MQTT integration, enabled=false
                    ShareService.insertForwardEsri(enabled, description, url, headers, timezone).then((savedForwardTargetId) => {
                        // Insert Action
                        setForwardTargetId(savedForwardTargetId);
                        ShareService.createAction(flowId, savedForwardTargetId).then(() => goCompletePage());
                    });
                });
            } else {
                // Update configuration
                ShareService.updateForwardEsri(forwardTargetId, enabled, description, url, headers, timezone).then(() => goCompletePage());
            }
        });
    };

    const isValidate = () => {
        if (url == undefined || validator.isEmpty(url)) {
            errors.url = true;
            return false;
        }

        return true;
    };

    const handleValue = (e) => {
        const value = e.target.value;
        if (e.target.name == "txtDescription") {
            setDescription(value);
        } else if (e.target.name == "txtUrl") {
            setUrl(value);
        } else if (e.target.name == "txtHeaders") {
            setHeaders(value);
        } else if (e.target.name == "cboEnabled") {
            setEnabled(e.target.checked);
        }
    };

    const handleTimezone = (e) => {
        setTimezone(e.target.textContent);
    };

    const closeDialog = (answer) => {
        if (answer) {
            // Set false the forward target
            ShareService.setForwardTargetEnabled(forwardTargetId, false).then(() => setEnabled(false));
        } else {
            goFirstPage();
        }
    };

    return (
        <>
            <Paper elevation={6} spacing={2}>
                {on && <ConfirmDialog callback={closeDialog}></ConfirmDialog>}

                <Grid container padding={2}>
                    <Grid item xs={6} sm={6}>
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" style={{ backgroundColor: "#9CC96B" }} onClick={() => handleSave()}>
                                Save
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                        <Typography>Connection</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={2} sm={2}>
                                <Typography>URL</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtUrl"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={url}
                                    onChange={(e) => handleValue(e)}
                                    error={errors.url}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>Headers</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtHeaders"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={headers}
                                    multiline
                                    rows={4}
                                    onChange={(e) => handleValue(e)}
                                    error={errors.headers}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>Timezone</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    open={open}
                                    onOpen={() => {
                                        setOpen(true);
                                    }}
                                    onClose={() => {
                                        setOpen(false);
                                    }}
                                    getOptionLabel={(option) => option.title}
                                    value={{ title: timezone }}
                                    options={allTimezone}
                                    loading={loading}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                            sx={CustomStyle.RemoveLabel}
                                        />
                                    )}
                                    fullWidth
                                    onChange={(e) => handleTimezone(e)}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>Enable</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <Checkbox name="cboEnabled" checked={enabled} onChange={(e) => handleValue(e)} />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Paper>
        </>
    );
}
