import ApiService from "Services/apiService";

const ChartGeneralAllService = {
    requestThingModel    : async (thingId, setAttributes) => {
        let requestOptions = {
            url: `/api/things/${thingId}/model`     
        };

        const response = await ApiService.getData(requestOptions);
        // console.log("response.attributes", response);

        const sortedAttributes = response?.attributes.sort((a, b) => 
            a.name.localeCompare(b.name)
        );
        setAttributes(sortedAttributes);
        return response;
    }
}

export default ChartGeneralAllService;
