import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { CustomButton } from "Components/ui/CustomButton";
import RoomService from "./RoomService";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import validator from "validator";
import { useCallback } from "react";
import CardService from "./CardService";

export default function RoomContactorSetting({ open, setOpen, thingId, callback }) {
    const { t } = useTranslation();
    const [delayThreshold, setDelayThreshold] = useState(0);
    const [notifyOnClose, setNotifyOnClose] = useState(false);
    const [notifyOnOpen, setNotifyOnOpen] = useState(false);
    const [enableEmail, setEnableEmail] = useState(false);
    const [enableSms, setEnableSms] = useState(false);
    const [fixedName, setFixedName] = useState("");

    useEffect(() => {
        if (thingId) {
            CardService.requestThing(thingId).then((r) => {
                // console.log("r", r); 
                setFixedName(r.fixedName);
                // console.log("r.customFields", r.customFields);

                r.customFields?.forEach((customField) => {
                    if (customField.name === "EMERA_V2_CONTACT_SETTING") {
                        const contactSetting = JSON.parse(customField.value);
                        setDelayThreshold(contactSetting.delayThreshold);
                        setNotifyOnClose(contactSetting.notifyOnClose);
                        setNotifyOnOpen(contactSetting.notifyOnOpen);
                    }

                    if (customField.name === "NOTIFICATIONS") {
                        const notifications = JSON.parse(customField.value);
                        setEnableEmail(notifications.enableEmail);
                        setEnableSms(notifications.enableSms);
                    }
                });
            });
        }

        // RoomService.requestNotifications(setEmailNotify, setSmsNotify, AppId.EMERA_V2);
    }, [thingId, open]);

    const handleClose = () => {
        setOpen(false);
        // Reset all fields
        setDelayThreshold(0);
        setNotifyOnClose(false);
        setEnableEmail(false);
        setEnableSms(false);
        setFixedName("");
    };

    const handleSave = () => {
        // console.log("handleSave", delayThreshold);
        RoomService.saveSetting(thingId, delayThreshold, notifyOnClose, notifyOnOpen).then(() => setOpen(false));
    };

    const handleNotifyOnOpenChange = (e) => {
        setNotifyOnOpen(e.target.checked);
        RoomService.saveSetting(thingId, delayThreshold, notifyOnClose, e.target.checked);
    };

    const handleNotifyOnCloseChange = (e) => {
        setNotifyOnClose(e.target.checked);
        RoomService.saveSetting(thingId, delayThreshold, e.target.checked, notifyOnOpen);
    };

    const handleDelayChange = (e) => {
        const value = Math.min(Math.max(0, parseInt(e.target.value) || 0), 1440);
        setDelayThreshold(value);
    };

    const handleEmailNotifyChange = (e) => {
        setEnableEmail(e.target.checked);
        RoomService.saveNotifications(thingId, e.target.checked, enableSms);
    };

    const handleSmsNotifyChange = (e) => {
        setEnableSms(e.target.checked);
        RoomService.saveNotifications(thingId, enableEmail, e.target.checked);
    };

    const debounceChange = useCallback(
        debounce((thingId, fixedName) => {
            // console.log("debounceChange", thingId, fixedName);
            if (validator.isEmpty(fixedName)) {
                return;
            }
            RoomService.updateName(thingId, fixedName).then((r) => {
                // console.log("r", r);
                if (callback) {
                    callback();
                }
            });
        }, 500),
        []
    );

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
            <DialogTitle id="form-dialog-title">
                Setup
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{ position: "absolute", right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ flexGrow: 1, m: 2 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={5} sx={{ display: "flex", alignItems: "center", flexDirection: "row", gap: 2 }}>
                            <Typography variant="body1">{t("col.name")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label={""}
                                value={fixedName || ""}
                                onChange={(e) => {
                                    setFixedName(e.target.value);
                                    debounceChange(thingId, e.target.value);
                                }}
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={5} sx={{ display: "flex", alignItems: "center", flexDirection: "row", gap: 2 }}>
                            <Typography variant="body1">{t("emera_v2.open_delay_threshold")}</Typography>
                        </Grid>
                        <Grid item xs={7} sx={{ display: "flex", alignItems: "center", flexDirection: "row", gap: 2 }}>
                            <TextField
                                label=""
                                type="number"
                                value={delayThreshold}
                                onChange={handleDelayChange}
                                fullWidth
                                size="small"
                                sx={{ width: "100px" }}
                                inputProps={{
                                    style: { textAlign: "right" },
                                    min: 0,
                                    max: 1440,
                                }}
                            />
                            <Typography variant="body1">minutes</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1">Alert after delay</Typography>
                            <FormControlLabel
                                control={<Checkbox checked={notifyOnOpen} onChange={handleNotifyOnOpenChange} />}
                                label="On opening"
                                sx={{ ml: 0 }}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={notifyOnClose} onChange={handleNotifyOnCloseChange} />}
                                label="On closing"
                                sx={{ ml: 0 }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1">Notifications</Typography>
                            <Stack direction="row" spacing={2}>
                                <FormControlLabel
                                    control={<Checkbox checked={enableEmail} onChange={handleEmailNotifyChange} />}
                                    label="Email"
                                    sx={{ ml: 0 }}
                                />
                                <FormControlLabel control={<Checkbox checked={enableSms} onChange={handleSmsNotifyChange} />} label="SMS" sx={{ ml: 0 }} />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <CustomButton name="OK" callback={handleSave} />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
