import React, { useState, useEffect } from "react";
import { Box, Typography, Card, CardContent, CardActions, IconButton, Step, Stepper, StepLabel, StepConnector, stepConnectorClasses } from "@mui/material";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import UpdateIcon from "@mui/icons-material/Update";
import { styled } from "@mui/material/styles";
import SettingsIcon from "@mui/icons-material/Settings";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import CardService from "./CardService";
import CommonTop from "./CommonTop";
import CommonAlert from "./CommonAlert";
import CommonSetting from "./CommonSetting";
import { useHistory } from "react-router-dom";
import UtilService from "../../../services/utilService";
import { useTranslation } from 'react-i18next';  // Ajouter cet import

const sensorData = {
    batteryLevel: 85, // In pourcentage
    leakStatus: true, // True if leaking, False if dry
    alarms: [
        { severity: "critical", timestamp: "16/02/2025 15:30", value: "Leak detected" },
        { severity: "warning", timestamp: "17/02/2025 14:45", value: "High humidity" },
    ],
    history: [
        { time: "14:00", status: 0 },
        { time: "15:00", status: 1 },
        { time: "16:00", status: 1 },
        { time: "17:00", status: 0 },
    ],
};

const CustomConnector = styled(StepConnector)(({ theme, isDashed }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: "calc(-50% + 16px)",
        right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: "#784af4",
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: "#784af4",
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
        borderTopWidth: 3,
        borderRadius: 1,
        borderStyle: isDashed ? "dashed" : "solid",
    },
}));

// Créer un StepIcon personnalisé
const CustomStepIcon = (props) => {
    const { active, completed } = props;

    return (
        <WaterDropIcon
            sx={{
                fontSize: 24,
                color: active || completed ? "error.main" : "primary.main",
                opacity: active || completed ? 1 : 0.5,
            }}
        />
    );
};

// Modifier la fonction de calcul de durée
const calculateLeakDuration = (history) => {
    if (history.length < 2) return null;

    try {
        // Extraire la date et l'heure du format "JJ/MM/AAAA HH:mm"
        const getDateTime = (timeStr) => {
            const [datePart, timePart] = timeStr.split(" ");
            const [day, month, year] = datePart.split("/");
            const [hours, minutes] = timePart.split(":");

            // Créer un objet Date (mois commence à 0 en JavaScript)
            return new Date(year, month - 1, day, hours, minutes);
        };

        const firstDate = getDateTime(history[0].time);
        const lastDate = getDateTime(history[history.length - 1].time);

        // Calculer la différence en minutes
        const durationMinutes = Math.floor((lastDate - firstDate) / (1000 * 60));
        const hours = Math.floor(durationMinutes / 60);
        const minutes = durationMinutes % 60;

        if (hours > 0) {
            return `First leak alarm ${hours}h${minutes > 0 ? ` ${minutes}min` : ""} ago`;
        }

        return `First leak alarm ${minutes}min ago`;
    } catch (error) {
        console.error("Erreur dans le calcul de la durée:", error);
        return "Error calculating time";
    }
};

// Add this function at the top level, near other constants
const getBatteryIcon = (battery) => {
    if (!battery && battery === 100) return "fa-battery-full";

    if (battery <= 0) return "fa-battery-empty";
    if (battery <= 25) return "fa-battery-quarter";
    if (battery <= 50) return "fa-battery-half";
    if (battery <= 75) return "fa-battery-three-quarters";
    return "fa-battery-full";
};

// Modifier le nom de la fonction d'export
export default function WaterLeakCard({ asset, cardKey, setReloadParent }) {
    const { t } = useTranslation();
    const history = useHistory();
    const [leakHistory, setLeakHistory] = useState([]);
    const [timestamp, setTimestamp] = useState(null);
    const [leakStatus, setLeakStatus] = useState(false);
    const [battery, setBattery] = useState(sensorData.batteryLevel);
    const [leak, setLeak] = useState(leakStatus);
    const [alarms, setAlarms] = useState(sensorData.alarms);
    const [isFirstLeak, setIsFirstLeak] = useState(true);
    const [entity, setEntity] = useState({});
    const [site, setSite] = useState(null);
    const [fixedName, setFixedName] = useState(null);
    const [alertCount, setAlertCount] = useState(0);
    const [openSetting, setOpenSetting] = useState(false);
    const [moreFrequentPeriod, setMoreFrequentPeriod] = useState(null);
    const [batteryLevel, setBatteryLevel] = useState(100);
    const [showAlarms, setShowAlarms] = useState(false);

    useEffect(() => {
        
        const attributeIds = asset.assetAttribute.map((a) => a.attribute.id);
        const thingId = asset.assetAttribute[0].thing.id;

        // console.log(thingId, attributeIds);
        CardService.requestThing(thingId).then((r) => {
            if (r?.site) {
                setSite(r.site);
            }
        });

        CardService.requestLastMeasures(thingId, attributeIds).then((r) => {
            // console.log(r);

            r.forEach((ele) => {
                if (ele.attribute.name === "leakage_status") {
                    setEntity({
                        thingId: ele.thingId,
                        fixedName: ele.fixedName,
                        value: Number(ele.value),
                        unit: ele.attribute.unit,
                        date: ele.timestamp,
                        attributeId: ele?.attribute?.id,
                    });
                    setFixedName(ele.fixedName);
                    // setSoundLevel(Number(ele.value));
                } else if (ele.attribute.name === "battery") {
                    setBatteryLevel(ele.value);
                }
            });

            if (entity.attributeId) { // leakage_status
                CardService.requestWaterLeakHistory(thingId, entity.attributeId, setLeakHistory);
            }
        });

        CardService.requestAlerts(thingId, setAlarms, setAlertCount);
        // CardService.requestMaxToday(thingId, setMaxSoundLevel);
        const leakageAttrId = asset.assetAttribute.filter((a) => a.attribute.name === "leakage_status").map((a) => a.attribute.id).find(() => true);
        CardService.requestMoreFrequentPeriod(thingId, leakageAttrId, setMoreFrequentPeriod);
    }, [asset, cardKey]);

    // Ajouter l'état pour le timestamp
    const [currentTime, setCurrentTime] = useState(
        new Date().toLocaleTimeString("fr-FR", {
            hour: "2-digit",

            minute: "2-digit",
        })
    );

    // Ajouter l'effet pour mettre à jour le temps

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(
                new Date().toLocaleTimeString("fr-FR", {
                    hour: "2-digit",
                    minute: "2-digit",
                })
            );
        }, 60000);

        return () => clearInterval(timer);
    }, []);

    const handleGraphClick = () => {
        // console.log("📈 Graphique cliqué !");
        history.push(`/apps/emera/v2/chart_water_ws301/${entity.thingId}/${entity.attributeId}`);
    };

    // Add handler for alarm details click
    const handleAlarmDetailsClick = () => {
        // console.log("Alarm details clicked");
        setShowAlarms(!showAlarms);
    };

    return (
        <Card
            sx={{
                backgroundColor: "white",
                borderRadius: "12px",
                width: "400px",
                minHeight: "400px",
                display: "flex",
                flexDirection: "column",
                transition: "all 0.3s ease-in-out",
            }}
        >
            {/* CommonTop */}
            <CommonTop 
                thingId={asset?.assetAttribute[0]?.thing?.id} 
                showAlarms={showAlarms} 
                setShowAlarms={setShowAlarms} 
            />

            {/* CardContent */}
            <CardContent 
                sx={{ 
                    flex: 1,
                    py: 0,
                    '&:last-child': {
                        paddingBottom: 0
                    }
                }}
            >
                {!showAlarms ? (
                    <Box sx={{ 
                        display: "flex", 
                        flexDirection: "column", 
                        alignItems: "center", 
                        justifyContent: "center",
                        height: "100%",
                        px: 1
                    }}>
                        <CardContent sx={{ flex: 1, display: "flex", alignItems: leak ? "flex-start" : "center" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1, width: "100%" }}>
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    {leak && <WaterDropIcon sx={{ fontSize: 40, color: "error.main" }} />}

                                    <Typography variant="h4" color={leak ? "error" : "primary"}>
                                        {leak ? t('emera_v2.water_leak.leak') : t('emera_v2.water_leak.dry')}
                                    </Typography>
                                </Box>

                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <Typography variant="body2" sx={{ color: "black", fontWeight: 500 }}>
                                        {UtilService.formateHM(entity.date)}
                                    </Typography>

                                    <UpdateIcon sx={{ color: "black", fontSize: 20 }} />

                                    {moreFrequentPeriod && (
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                color: "gray",
                                                fontWeight: 400,
                                            }}
                                        >
                                            {moreFrequentPeriod}
                                        </Typography>
                                    )}
                                </Box>

                                {/* Stepper conditionnel */}

                                {leakHistory.length > 0 && (
                                    <Box sx={{ width: "100%", mt: 2 }}>
                                        <Stepper alternativeLabel activeStep={leakHistory.length - 1}>
                                            {leakHistory.map((step, index) => (
                                                <Step key={index}>
                                                    <StepLabel StepIconComponent={CustomStepIcon}>
                                                        <Typography variant="caption">{UtilService.formatMDYHm(step.start)}</Typography>
                                                    </StepLabel>

                                                    {/* Ne pas afficher le connecteur avant le premier step */}
                                                    {index > 0 && index < leakHistory.length && (
                                                        <CustomConnector isDashed={index === 2 && leakHistory.length >= 5} />
                                                    )}
                                                </Step>
                                            ))}
                                        </Stepper>

                                        {/* N'afficher la durée que s'il y a plus d'un message */}

                                        {leakHistory.length > 1 && (
                                            <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                                                <Typography variant="body2" sx={{ color: "text.secondary", fontStyle: "italic" }}>
                                                    {(() => {
                                                        const startTime = leakHistory[0].start * 1000; // Convert epoch seconds to milliseconds
                                                        const now = Date.now();
                                                        const diffMinutes = Math.floor((now - startTime) / (1000 * 60));
                                                        const hours = Math.floor(diffMinutes / 60);
                                                        const minutes = diffMinutes % 60;

                                                        if (hours > 0) {
                                                            return `${t('emera_v2.water_leak.first_alarm')} ${hours}${t('emera_v2.water_leak.time_ago.hours')}${
                                                                minutes > 0 ? ` ${minutes}${t('emera_v2.water_leak.time_ago.minutes')}` : ''
                                                            } ago`;
                                                        }
                                                        return `${t('emera_v2.water_leak.first_alarm')} ${minutes}${t('emera_v2.water_leak.time_ago.minutes')} ago`;
                                                    })()}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                )}
                            </Box>
                        </CardContent>
                    </Box>
                ) : (
                    <CommonAlert 
                        alarms={alarms} 
                        onAlarmDetailsClick={handleAlarmDetailsClick} 
                    />
                )}
            </CardContent>

            {/* CardActions */}
            <CardActions 
                disableSpacing 
                sx={{ 
                    display: "flex", 
                    justifyContent: "space-between", 
                    alignItems: "center", 
                    gap: 1 
                }}
            >
                <Box sx={{ display: "flex", gap: 1 }}>
                    <IconButton
                        onClick={() => setOpenSetting(true)}
                        aria-label="paramètres"
                        sx={{
                            transition: "transform 0.3s",
                            "&:hover": {
                                transform: "scale(1.1)",
                            },
                        }}
                    >
                        <SettingsIcon />
                    </IconButton>

                    <IconButton
                        onClick={handleGraphClick}
                        aria-label="graphique"
                        sx={{
                            transition: "transform 0.3s",
                            "&:hover": {
                                transform: "scale(1.1)",
                            },
                        }}
                    >
                        <AutoGraphIcon />
                    </IconButton>
                </Box>
            </CardActions>

            <CommonSetting 
                open={openSetting} 
                setOpen={setOpenSetting} 
                thingId={entity?.thingId} 
            />
        </Card>
    );
}
