const DeviceExplorerConstant = {
    // Forward key constants
    FORWARD_KEY_MQTT: 'MQTT',
    FORWARD_KEY_HTTP: 'HTTP',
    FORWARD_KEY_AWS_IOT: 'AWSIoT',
    FORWARD_KEY_ESRI_ARCGIS: 'ESRIArcGIS',
    FORWARD_KEY_ESRI_VELOCITY: 'ESRIVelocity',
    FORWARD_KEY_SQL_SERVER: 'SQLserver',
    FORWARD_KEY_ICICLE: 'ICICLE',
    FORWARD_KEY_THINGSBOARD: 'THINGSBOARD',
    FORWARD_KEY_UNKNOWN: 'UNKNOWN',

    // For LOG_ENTRY's scope
    toScope: (forwardKey) => {
        switch (forwardKey) {
            case DeviceExplorerConstant.FORWARD_KEY_MQTT:
                return 'MQTT';
            case DeviceExplorerConstant.FORWARD_KEY_HTTP:
                return 'HTTP';
            case DeviceExplorerConstant.FORWARD_KEY_AWS_IOT:
                return 'IOT_CORE';
            case DeviceExplorerConstant.FORWARD_KEY_ESRI_ARCGIS:
                return 'ESRIArcGIS';
            case DeviceExplorerConstant.FORWARD_KEY_ESRI_VELOCITY:
                return 'ESRI_VELOCITY';
            case DeviceExplorerConstant.FORWARD_KEY_SQL_SERVER:
                return 'SQL_SERVER';
            case DeviceExplorerConstant.FORWARD_KEY_ICICLE:
                return 'ICICLE';
            case DeviceExplorerConstant.FORWARD_KEY_THINGSBOARD:
                return 'THINGSBOARD';
            default:
                return 'UNKNOWN';
        }
    }
}

export default DeviceExplorerConstant