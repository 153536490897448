import _ from "underscore";
import store from "store";

const KEY_COLUMN_ORDER = "wm.column.order";
const KEY_COLUMN_VISIBILITY = "wm.column.visibility";

const Attributes = {
    battery: {
        field: "id_battery",
        headerName: "Battery",
        width: 110,
        sortable: false,
    },
};

const Coordinator = {
    getId: (name) => {
        let attribute = _.get(Attributes, name.toLowerCase());
        if (attribute == undefined) {
            return undefined;
        }
        return attribute.field;
    },
    getFixeColumns: (t) => {
        return [
            {
                field: "company",
                headerName: t("col.owner"),
                width: 150,
                editable: false,
            },
            {
                field: "thing",
                headerName: t("col.serial_number"),
                width: 200,
                editable: false,
            },
            {
                field: "serialNumber",
                headerName: t("col.lorawan_sn"),
                width: 200,
                editable: false,
            },
            {
                field: "size",
                headerName: t("col.size"),
                width: 120,
                editable: false,
            },
            {
                field: "radio",
                headerName: t("col.radio"),
                width: 120,
                editable: false,
                renderCell: (params) => {
                    if (params.value === "true") {
                        return "On";
                    } else {
                        return "Off";
                    }
                },
            },
            {
                field: "owner",
                headerName: t("col.tenant"),
                width: 130,
                editable: false,
            },
            {
                field: "timestamp",
                headerName: t("col.last_message"),
                width: 200,
                editable: false,
            },
            // {
            //     field: "model",
            //     headerName: "Model",
            //     width: 200,
            // },
            {
                field: "volume",
                headerName: t("col.readout"),
                width: 130,
                editable: false,
                renderCell: (params) => {
                    // console.log(params);
                    let vol = params.row.volume;
                    let volUnit = params.row.volumeUnit;

                    if (vol && vol != "") {
                        vol = vol + " " + volUnit;
                    }

                    return <div style={{ width: "100%", textAlign: "right" }}>{vol}</div>;
                },
                sortable: false,
            },
            {
                field: "manufacturer",
                headerName: t("col.mfr"),
                width: 160,
                editable: false,
                renderCell: (params) => {
                    // console.log("------------")
                    // console.log(params.row.manufacturer)
                    return (
                        <>{params.row.manufacturer}</>
                        // <Select
                        //     labelId="demo-simple-select-label"
                        //     id="demo-simple-select"
                        //     label="Age"
                        //     value={params.row.manufacturer}
                        //     sx={{
                        //         '& legend': { display: 'none' },
                        //         '& fieldset': { top: 0 },
                        //         width: '100%',
                        //         margin: 0,
                        //         padding: 0,

                        //     }}
                        //     style={{ fontFamily: 'Open Sans', paddingLeft: 5 }}
                        // >
                        //     <MenuItem value={'Axioma'} style={{ display: 'block', paddingLeft: 5, fontFamily: 'Open Sans', fontSize: 14 }}>Axioma</MenuItem>
                        //     <MenuItem value={'Zenner'} style={{ display: 'block', paddingLeft: 5, fontFamily: 'Open Sans', fontSize: 14 }}>Zenner</MenuItem>
                        //     <MenuItem value={'Kamstrup'} style={{ display: 'block', paddingLeft: 5, fontFamily: 'Open Sans', fontSize: 14 }}>Kamstrup</MenuItem>
                        //     <MenuItem value={'Badger'} style={{ display: 'block', paddingLeft: 5, fontFamily: 'Open Sans', fontSize: 14 }}>Badger</MenuItem>
                        //     <MenuItem value={'Neptune'} style={{ display: 'block', paddingLeft: 5, fontFamily: 'Open Sans', fontSize: 14 }}>Neptune</MenuItem>
                        //     <MenuItem value={'Sensus'} style={{ display: 'block', paddingLeft: 5, fontFamily: 'Open Sans', fontSize: 14 }}>Sensus</MenuItem>
                        //     <MenuItem value={'Next Century'} style={{ display: 'block', paddingLeft: 5, fontFamily: 'Open Sans', fontSize: 14 }}>Next Century</MenuItem>
                        //     <MenuItem value={'Master Meter'} style={{ display: 'block', paddingLeft: 5, fontFamily: 'Open Sans', fontSize: 14 }}>Master Meter</MenuItem>
                        // </Select>
                    );
                },
            },
            {
                field: "product",
                headerName: t("col.product"),
                width: 300,
                editable: false,
            },
            {
                field: "connectivityType",
                headerName: t("col.technology"),
                width: 200,
                editable: false,
            },
            // {
            //     field: "status",
            //     headerName: "Status",
            //     width: 150,
            //     renderCell: (params) => {
            //         return <CustomStatusChip status={params.value} />;
            //     },
            // },
            {
                field: "tags",
                headerName: t("col.tags"),
                width: 130,
                sortable: true,
                editable: false,
            },
            {
                field: "site",
                headerName: t("col.site"),
                width: 110,
                sortable: true,
                editable: false,
            },
            {
                field: "unit",
                headerName: t("col.unit"),
                width: 110,
                editable: false,
            },
            {
                field: "alertLastMessage",
                headerName: t("col.alert"),
                width: 110,
                sortable: false,
                editable: false,
            },
            {
                field: "alertLastDate",
                headerName: t("col.timestamp"),
                width: 200,
                sortable: false,
                editable: false,
            },
        ];
    },

    getDynamicColumns: () => {
        return Object.values(Attributes);
    },

    getColumnOrder: () => {
        let savedColumns = store.get(KEY_COLUMN_ORDER);

        if (savedColumns && savedColumns.find((f) => f.field == "thing" && f.headerName == "Serial Number")) {
            // this field's name and header have been changed.
            // it should be initalized  if you add a column on the grid in MeasurementView or SubMeasuremventView
            store.remove(KEY_COLUMN_ORDER);
            savedColumns = undefined;
        }

        return savedColumns ? savedColumns : [];
    },

    saveInitialOrder: (param) => {
        store.set(KEY_COLUMN_ORDER, param);
    },

    saveColumnOrder: (param) => {
        let savedColumns = store.get(KEY_COLUMN_ORDER);
        if (!savedColumns) {
            savedColumns = [];
        }

        // Move the element to param.targetIndex
        const tagsIndex = savedColumns.findIndex((r) => param.field === r.field);
        const tagsElement = savedColumns.splice(tagsIndex, 1)[0];
        savedColumns.splice(param.targetIndex - 1, 0, tagsElement);

        store.set(KEY_COLUMN_ORDER, savedColumns);
    },

    getColumnVisibility: () => {
        let savedVisibility = store.get(KEY_COLUMN_VISIBILITY);
        // console.log("savedVisibility...");
        // console.log(savedVisibility);
        return savedVisibility;
    },

    saveColumnVisibility: (param) => {
        store.set(KEY_COLUMN_VISIBILITY, param);
    },
};

export default Coordinator;
