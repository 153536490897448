import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { AppId } from "../../../components/applications/ApplicationData";
import GatewayService from "./GatewayService";
import validator from "validator";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { CustomButton } from "Components/ui/CustomButton";
import CreateGatewayDialog from "./CreateGatewayDialog";
import UserService from "../../../services/userService";
import moment from "moment";
import UpdateGatewayDialog from "./UpdateGatewayDialog";
import { frFR } from "@mui/x-data-grid/locales";
import { LicenseInfo } from "@mui/x-license-pro";
import { GatewayColumns } from "./GatewayColumns";
LicenseInfo.setLicenseKey("53264e234090f26bf650c109bcf9fc2fTz0xMDUxMDMsRT0xNzY5Mjg1NDAxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

export default function GatewayMain() {
    const { t, i18n } = useTranslation();

    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 15,
    });
    const [sort, setSort] = useState([{ field: "company", sort: "asc" }]);
    const [selectedGwId, setSelectedGwId] = useState();

    const [openCreate, setOpenCreate] = useState(false);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [reload, setReload] = useState();

    const appId = AppId.DEVICE_EXPLORER;

    useEffect(() => {
        UserService.getAuthenticatedUser().then((u) => {
            // console.log(u)

            if (u.authorities == "SUPER_ADMIN") {
                GatewayService.requestGateways(paginationModel.page, paginationModel.pageSize, setRows, sort, appId, true, "PTH", setRowCount);
            } else {
                GatewayService.requestGateways(paginationModel.page, paginationModel.pageSize, setRows, sort, appId, false, u.company, setRowCount);
            }
        });
    }, [appId, paginationModel, reload, sort]);

    const handleName = (p, e) => {
        if (p.field === "name" && e.target) {
            if (validator.isEmpty(e.target.value)) {
                return;
            }
            GatewayService.updateGatewayName(p.id, e.target.value);
        }
    };

    const handleClick = (event, cellValues) => {
        GatewayService.updateGatewayStatus(cellValues.id).then(() => {
            setReload(moment());
        });
    };

    const handleSortModelChange = (param) => {
        setSort(param);
    };

    const handleCell = (p, e) => {
        if (p.field === "model") {
            const gwId = p.row.id;
            // console.log("pwId", gwId);
            setSelectedGwId(gwId);
            setOpenUpdate(true);
        }
    };

    const columns = GatewayColumns(t, handleClick);

    return (
        <Box sx={{ flexGrow: 1, mt: 8, p: 2 }}>
            <Grid item xs={12} sx={{ mb: 2 }}>
                <CustomButton name={t("btn.add")} callback={() => setOpenCreate(true)} />
            </Grid>
            <Grid item xs={12} style={{ height: 700 }}>
                <DataGridPro
                    rows={rows}
                    rowCount={rowCount}
                    columns={columns}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pagination={true}
                    paginationMode="server"
                    sortingMode="server"
                    pageSizeOptions={[15, 50, 100]}
                    onSortModelChange={handleSortModelChange}
                    checkboxSelection={false}
                    density="compact" 
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                            },
                        },
                    }}
                    hideFooterSelectedRowCount
                    localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
                    onCellEditStop={handleName}
                    onCellClick={handleCell}
                />
            </Grid>
            <CreateGatewayDialog open={openCreate} setOpen={setOpenCreate} setReload={setReload} />
            <UpdateGatewayDialog open={openUpdate} setOpen={setOpenUpdate} setReload={setReload} selectedGwId={selectedGwId} />
        </Box>
    );
}
