import React, { useEffect, useState, useContext } from "react";
import AppContext from "Components/AppContext";
import SettingContext from "Providers/SettingContext";
import { AppId } from "Components/applications/ApplicationData";
import { Box, Grid, IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MeasuresMain from "../measuresGrid/MeasuresMain";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";

export default function SideManu({ position, hideOptions = false }) {
    const history = useHistory();
    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);
    // const [entity, setEntity] = useState();

    const [open, setOpen] = useState(false);

    useEffect(() => {
        setting.setGlobalSetting({
            appId: AppId.SUPERVIOT,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });

        if (position) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [position]);

    const handleMore = () => {
        history.push(`/apps/superviot/charts/${position.thingId}`);
    };

    return (
        <>
            {position && open && (
                <Box p={2} maxWidth={600} sx={{ borderLeft: "1px dotted", borderColor: "primary.main" }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={4}>
                            <IconButton
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>

                        <Grid item xs={4}>
                            <Typography variant="h6" gutterBottom>
                                {position?.name}
                            </Typography>
                        </Grid>

                        <Grid item xs={4}>
                            {!hideOptions && (
                                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button variant="outlined" onClick={() => handleMore()} sx={{ textTransform: "none" }}>
                                        Details...
                                    </Button>
                                </Box>
                            )}
                        </Grid>

                        <Grid item xs={12}>
                            <MeasuresMain selectedThingId={position.thingId} hideOptions={hideOptions} />
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
}
