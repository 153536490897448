import { FormControl, Select, MenuItem } from "@mui/material";
import ShareService from "./ShareService";
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UserService from "Services/userService";

export default function OrgPanel({ selectedOrg, callback }) {
    const [txtMessageFormat, setTxtMessageFormat] = useState();
    const [company, setCompany] = useState("");
    const [org, setOrg] = useState(selectedOrg);
    const [orgOptions, setOrgOptions] = useState([]);

    useEffect(() => {
        // console.log("uef in OrgPanel");
        // console.log("selectedOrg", selectedOrg);
        // console.log("current organizations:", orgOptions);

        UserService.getAuthenticatedUser().then((u) => {
            // console.log("user", u);
            setCompany(u.company);

            ShareService.requestSubCompanyWithMine(u.company).then((orgs) => {
                // console.log("subCompanies", orgs);
                if (Array.isArray(orgs)) {
                    let mapped = orgs.map((org) => org.name);
                    // console.log("mapped", mapped);
                    setOrgOptions(mapped);
                    
                    // Check if the selected org is not in the options
                    if (selectedOrg && !mapped.includes(selectedOrg)) {
                        setOrg(''); // Reset to empty if invalid
                    }
                }
            });
        });
    }, [selectedOrg, org]);

    // Update local state when props change
    useEffect(() => {
        setOrg(selectedOrg);
    }, [selectedOrg]);

    const handleOrgChange = (event) => {
        const value = event.target.value;
        setOrg(value);
        callback(event);
    };

    return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, pl: 1 }}>
            <Typography>Organization</Typography>
            <FormControl sx={{ width: "200px" }}>
                <Select value={org || ""} onChange={handleOrgChange} name="organization">
                    {orgOptions && orgOptions.length > 0 ? (
                        orgOptions.map((org) => (
                            <MenuItem key={org} value={org}>
                                {org}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem disabled>No organizations available</MenuItem>
                    )}
                </Select>
            </FormControl>
        </Box>
    );
}
