import ApiService from "Services/apiService";
import dayjs from "dayjs";

const AnalyticService = {
    requestSite: async (company) => {
        let requestOptions = {
            url: `/v2/sites`,
            params: {
                company,
                page: 0,
                pageSize: 100,
            },
        };

        return ApiService.getData(requestOptions).then((res) => {
            return res.content;
        });
    },

    requestAnalytic: async (startDate, endDate, siteId, attributeIds) => {
        let requestOptions = {
            url: `/v2/stats`,
            params: {
                start: dayjs(startDate).unix(),
                end: dayjs(endDate).unix(),
                attributeIds,
            },
        };

        if (!siteId.includes("all")) {
            requestOptions.params.siteId = siteId;
        }

        // console.log("requestOptions", requestOptions);
        return ApiService.getData(requestOptions).then((res) => {
            // console.log("res", res);

            const mapped = {
                categories: [],
                avg: [],
                min: [],
                max: []
            };

            res?.forEach((item) => {
                mapped.categories.push(item.hour);
                mapped.avg.push(Number(item.avg?.toFixed(2)) || 0);
                mapped.min.push(Number(item.min?.toFixed(2)) || 0);
                mapped.max.push(Number(item.max?.toFixed(2)) || 0);
            });

            return mapped;
        });
    },
};

export default AnalyticService;
