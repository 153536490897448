import React from 'react';
import { Box, Typography } from '@mui/material';

const TVOC_THRESHOLDS = {
    excellent: 0,      // Excellent
    good: 200,        // Bon
    moderate: 400,    // Modéré
    poor: 1000,       // Médiocre
    bad: 3000,        // Mauvais
    max: 5000        // Très mauvais
};

// Ajouter la fonction getTVOCLabel
const getTVOCLabel = (value) => {
    if (value <= TVOC_THRESHOLDS.excellent) return 'Excellent';
    if (value <= TVOC_THRESHOLDS.good) return 'Bon';
    if (value <= TVOC_THRESHOLDS.moderate) return 'Modéré';
    if (value <= TVOC_THRESHOLDS.poor) return 'Médiocre';
    if (value <= TVOC_THRESHOLDS.bad) return 'Mauvais';
    return 'Très mauvais';
};

// Ajouter la fonction pour obtenir la couleur en fonction du niveau
const getTVOCColor = (value) => {
    // Définition des couleurs pour chaque seuil
    const colors = {
        excellent: '#00ff00', // Vert vif
        good: '#90EE90',      // Vert clair
        moderate: '#ffff00',   // Jaune
        poor: '#ffa500',      // Orange
        bad: '#ff4500',       // Orange rouge
        danger: '#ff0000'     // Rouge vif
    };

    if (value <= TVOC_THRESHOLDS.excellent) return colors.excellent;
    if (value <= TVOC_THRESHOLDS.good) return colors.good;
    if (value <= TVOC_THRESHOLDS.moderate) return colors.moderate;
    if (value <= TVOC_THRESHOLDS.poor) return colors.poor;
    if (value <= TVOC_THRESHOLDS.bad) return colors.bad;
    return colors.danger;
};

// Ajouter une fonction pour calculer la position relative du curseur
const getRelativePosition = (value) => {
    const thresholds = [
        TVOC_THRESHOLDS.excellent,
        TVOC_THRESHOLDS.good,
        TVOC_THRESHOLDS.moderate,
        TVOC_THRESHOLDS.poor,
        TVOC_THRESHOLDS.bad,
        TVOC_THRESHOLDS.max
    ];

    // Trouver dans quelle section se trouve la valeur
    for (let i = 0; i < thresholds.length; i++) {
        if (value <= thresholds[i]) {
            const sectionWidth = 100 / (thresholds.length - 1); // Largeur d'une section en %
            if (i === 0) return 0;
            
            // Calculer la position relative dans la section
            const prevThreshold = thresholds[i - 1];
            const sectionProgress = (value - prevThreshold) / (thresholds[i] - prevThreshold);
            return ((i - 1) * sectionWidth) + (sectionProgress * sectionWidth);
        }
    }
    return 100;
};

// Modifier le composant IAQTVOC
const IAQTVOC = ({ tvoc, formatValue }) => {
    const tvocValue = parseFloat(tvoc);
    const currentColor = getTVOCColor(tvocValue);
    const position = getRelativePosition(tvocValue);
    
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flex: 1,
            width: '100%',
            gap: 2
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 0.5
            }}>
                <Typography sx={{
                    fontSize: '3rem',
                    fontWeight: 'bold',
                    color: 'black'
                }}>
                    {formatValue(tvoc)} ppb
                </Typography>
                
                <Typography sx={{
                    fontSize: '1.2rem',
                    color: 'black',  // Changé de currentColor à black
                    fontWeight: 500
                }}>
                    {getTVOCLabel(tvocValue)}
                </Typography>
            </Box>

            <Box sx={{ 
                position: 'relative',
                width: '80%',
                height: '40px',
                bgcolor: 'rgba(0, 0, 0, 0.05)',
                borderRadius: 2,
                background: `linear-gradient(to right, 
                    #00ff00 0%,
                    #00ff00 16%,
                    #90EE90 20%,
                    #90EE90 36%,
                    #ffff00 40%,
                    #ffff00 56%,
                    #ffa500 60%,
                    #ffa500 76%,
                    #ff4500 80%,
                    #ff4500 96%,
                    #ff0000 100%)`
            }}>
                <Box sx={{
                    position: 'absolute',
                    left: `${position}%`,
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    bgcolor: currentColor,
                    border: '2px solid white',
                    boxShadow: 1,
                    zIndex: 1
                }} />
            </Box>

            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                width: '80%',
                fontSize: '0.75rem',
                color: 'text.secondary'
            }}>
                {Object.entries(TVOC_THRESHOLDS).map(([level, value]) => (
                    <Typography key={level} variant="caption">
                        {value}
                    </Typography>
                ))}
            </Box>
        </Box>
    );
};

export default IAQTVOC;