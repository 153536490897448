import React, { useEffect, useState, useContext } from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import ThingService from "Services/thing/ThingService";
import ChartContactor from "./ChartContactor";
import ApplicationData, { AppId } from "Components/applications/ApplicationData";
import AppContext from "Components/AppContext";

export default function ChartContactorMain() {
    const ctx = useContext(AppContext);

    const { thingId, attributeId } = useParams();
    const [thing, setThing] = useState();

    useEffect(() => {
        ThingService.requestThing(thingId).then((t) => {
            setThing(t);
        });

        ctx.updateTitle(ApplicationData.find((a) => a.id === AppId.EMERA_V2).title);
    }, [thingId, attributeId]);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 10, ml: 4, mr: 4 }}>
            {thing && (
                <Box sx={{ flexGrow: 1, mt: 4, width: "100%" }}>
                    <span style={{ fontFamily: "Days One", fontSize: "24px", fontWeight: "normal", color: "#9B9B9B" }}>{thing.fixedName}</span>
                </Box>
            )}

            <ChartContactor thingId={thingId} attributeId={attributeId} />            
        </Box>
    );
}
