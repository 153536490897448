import React, { useState, useEffect } from "react";
import { Card, CardContent, CardActions, IconButton, Typography, Box, useTheme } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SettingsIcon from "@mui/icons-material/Settings";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import UpdateIcon from "@mui/icons-material/Update";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IAQCo2 from "./IAQ-Co2";
import IAQLight from "./IAQ-Light";
import IAQHCHO from "./IAQ-HCHO";
import IAQTVOC from "./IAQ-TVOC";
import getSlides from "./IAQ-Slides";
import CommonTop from "./CommonTop";
import CommonAlert from "./CommonAlert";
import CommonSetting from "./CommonSetting";
import { useHistory } from "react-router-dom";
import CardService from "./CardService";
// Ajout des constantes pour le capteur AM308
const TEMP_MIN = -20;
const TEMP_MAX = 60;

const getTemperatureColor = (value) => {
    // Conversion de la plage de température (-20 à 60) en pourcentage (0 à 100)
    const percentage = ((value - TEMP_MIN) / (TEMP_MAX - TEMP_MIN)) * 100;
    if (percentage <= 25) return "#0066ff"; // Bleu froid
    if (percentage <= 50) return "#00ff00"; // Vert tempéré
    if (percentage <= 75) return "#ff9900"; // Orange chaud
    return "#ff0000"; // Rouge très chaud
};

// Ajouter la fonction pour obtenir la couleur basée sur l'humidité
const getHumidityColor = (value) => {
    const percentage = value; // L'humidité est déjà en pourcentage
    if (percentage <= 30) return "#ff9900"; // Orange sec
    if (percentage <= 60) return "#00ff00"; // Vert optimal
    return "#0066ff"; // Bleu humide
};

// Ajouter les constantes pour les seuils de CO2
// Supprimer toutes les constantes et fonctions CO2
// Enlever CO2_THRESHOLDS, getCO2Color, getCO2Label

const IAQWidget = ({ thingId, temperature, humidity, pressure, light, co2, hcho, tvoc, pm25, pm10, motion, timestamp }) => {
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const [battery, setBattery] = useState(100);
    const [alarms, setAlarms] = useState([]);
    const [currentTime, setCurrentTime] = useState(
        new Date().toLocaleTimeString("fr-FR", {
            hour: "2-digit",
            minute: "2-digit",
        })
    );
    const [selectedMeasurement, setSelectedMeasurement] = useState(null);
    const [showAlarms, setShowAlarms] = useState(false);
    const history = useHistory();
    const [openSetting, setOpenSetting] = useState(false);
    const [moreFrequentPeriod, setMoreFrequentPeriod] = useState(null);

    useEffect(() => {
        CardService.requestMoreFrequentPeriod(thingId, undefined, setMoreFrequentPeriod, undefined);
    }, []);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (selectedMeasurement) {
            setSelectedMeasurement(null);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleMeasurementClick = (measurement) => {
        setSelectedMeasurement(measurement);
    };

    // Ajouter une fonction utilitaire pour formater les nombres
    const formatValue = (value) => {
        return typeof value === "number" ? value.toFixed(2) : value;
    };

    // Modifier la définition des slides pour inclure un id
    const slides = getSlides(formatValue, {
        temperature,
        humidity,
        pressure,
        light,
        motion,
        co2,
        hcho,
        tvoc,
        pm25,
        pm10,
    });

    const handleAlarmDetailsClick = () => {
        history.push("/apps/emera/v2/alerts");
    };

    return (
        <Card
            sx={{
                backgroundColor: "white",
                borderRadius: "12px",
                width: "400px",
                height: "400px",
                //p: 2,
                display: "flex",
                flexDirection: "column",
            }}
        >
            <CommonTop thingId={thingId} showAlarms={showAlarms} setShowAlarms={setShowAlarms} />

            <CardContent
                sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    py: 0, // Supprime le padding vertical par défaut de 16px
                    "&:last-child": {
                        pb: 0, // Supprime le padding bottom supplémentaire sur le dernier élément
                    },
                }}
            >
                {!showAlarms ? (
                    selectedMeasurement ? (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                pt: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 2,
                                    mb: 4,
                                }}
                            >
                                <IconButton onClick={handleBack} size="small">
                                    <KeyboardArrowLeft />
                                </IconButton>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 2,
                                    }}
                                >
                                    {selectedMeasurement.icon}
                                    <Typography variant="h6">{selectedMeasurement.label}</Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    gap: 2,
                                    width: "100%",
                                    flex: 1,
                                }}
                            >
                                {selectedMeasurement.id === 0 ? (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            flex: 1,
                                            width: "100%",
                                            gap: 2, // Augmente l'espacement entre les éléments
                                        }}
                                    >
                                        {/* Valeur de température */}
                                        <Typography
                                            sx={{
                                                fontSize: "3rem", // Doublé de 3rem à 6rem
                                                fontWeight: "bold",
                                                color: getTemperatureColor(parseFloat(temperature)),
                                            }}
                                        >
                                            {formatValue(temperature)}°C
                                        </Typography>

                                        {/* Barre de température */}
                                        <Box
                                            sx={{
                                                position: "relative",
                                                width: "80%",
                                                height: "40px",
                                                bgcolor: "rgba(0, 0, 0, 0.05)",
                                                borderRadius: 2,
                                                background: `linear-gradient(to right, #0066ff, #00ff00, #ff9900, #ff0000)`,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    position: "absolute",
                                                    left: `${
                                                        ((parseFloat(temperature) - selectedMeasurement.min) /
                                                            (selectedMeasurement.max - selectedMeasurement.min)) *
                                                        100
                                                    }%`,
                                                    top: "50%",
                                                    transform: "translate(-50%, -50%)",
                                                    width: "20px",
                                                    height: "20px",
                                                    borderRadius: "50%",
                                                    bgcolor: getTemperatureColor(parseFloat(temperature)),
                                                    border: "2px solid white",
                                                    boxShadow: 1,
                                                    zIndex: 1,
                                                }}
                                            />
                                        </Box>

                                        {/* Valeurs min et max */}
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "80%",
                                            }}
                                        >
                                            <Typography variant="body2" color="text.secondary">
                                                {selectedMeasurement.min}°C
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {selectedMeasurement.max}°C
                                            </Typography>
                                        </Box>
                                    </Box>
                                ) : selectedMeasurement.id === 1 ? (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            flex: 1,
                                            width: "100%",
                                            gap: 2,
                                        }}
                                    >
                                        {/* Valeur d'humidité */}
                                        <Typography
                                            sx={{
                                                fontSize: "3rem",
                                                fontWeight: "bold",
                                                color: getHumidityColor(parseFloat(humidity)),
                                            }}
                                        >
                                            {formatValue(humidity)}%
                                        </Typography>

                                        {/* Barre d'humidité */}
                                        <Box
                                            sx={{
                                                position: "relative",
                                                width: "80%",
                                                height: "40px",
                                                bgcolor: "rgba(0, 0, 0, 0.05)",
                                                borderRadius: 2,
                                                background: `linear-gradient(to right, #ff9900, #00ff00, #0066ff)`,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    position: "absolute",
                                                    left: `${
                                                        ((parseFloat(humidity) - selectedMeasurement.min) /
                                                            (selectedMeasurement.max - selectedMeasurement.min)) *
                                                        100
                                                    }%`,
                                                    top: "50%",
                                                    transform: "translate(-50%, -50%)",
                                                    width: "20px",
                                                    height: "20px",
                                                    borderRadius: "50%",
                                                    bgcolor: getHumidityColor(parseFloat(humidity)),
                                                    border: "2px solid white",
                                                    boxShadow: 1,
                                                    zIndex: 1,
                                                }}
                                            />
                                        </Box>

                                        {/* Valeurs min et max */}
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "80%",
                                            }}
                                        >
                                            <Typography variant="body2" color="text.secondary">
                                                {selectedMeasurement.min}%
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {selectedMeasurement.max}%
                                            </Typography>
                                        </Box>
                                    </Box>
                                ) : selectedMeasurement.id === 2 ? (
                                    <IAQCo2 co2={co2} formatValue={formatValue} />
                                ) : selectedMeasurement.id === 3 ? (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            flex: 1,
                                            width: "100%",
                                            gap: 2,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: "3rem",
                                                fontWeight: "bold",
                                                color: "black",
                                            }}
                                        >
                                            {formatValue(pressure)} hPa
                                        </Typography>

                                        <Box
                                            sx={{
                                                position: "relative",
                                                width: "80%",
                                                height: "40px",
                                                bgcolor: "rgba(0, 0, 0, 0.05)",
                                                borderRadius: 2,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    position: "absolute",
                                                    left: `${
                                                        ((parseFloat(pressure) - selectedMeasurement.min) /
                                                            (selectedMeasurement.max - selectedMeasurement.min)) *
                                                        100
                                                    }%`,
                                                    top: "50%",
                                                    transform: "translate(-50%, -50%)",
                                                    width: "20px",
                                                    height: "20px",
                                                    borderRadius: "50%",
                                                    bgcolor: "black",
                                                    border: "2px solid white",
                                                    boxShadow: 1,
                                                    zIndex: 1,
                                                }}
                                            />
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "80%",
                                            }}
                                        >
                                            <Typography variant="body2" color="text.secondary">
                                                {selectedMeasurement.min} hPa
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {selectedMeasurement.max} hPa
                                            </Typography>
                                        </Box>
                                    </Box>
                                ) : selectedMeasurement.id === 4 ? (
                                    <IAQLight light={light} formatValue={formatValue} />
                                ) : selectedMeasurement.id === 6 ? (
                                    <IAQHCHO hcho={hcho} formatValue={formatValue} />
                                ) : selectedMeasurement.id === 7 ? (
                                    <IAQTVOC tvoc={tvoc} formatValue={formatValue} />
                                ) : (
                                    // Affichage pour les autres mesures
                                    <Typography variant="h3" sx={{ textAlign: "center", mt: 4 }}>
                                        {selectedMeasurement.value} {selectedMeasurement.unit}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    mb: 0, // Suppression du margin bottom
                                }}
                            >
                                <IconButton
                                    size="small"
                                    onClick={handleBack}
                                    disabled={activeStep === 0 && !selectedMeasurement}
                                    sx={{ visibility: activeStep === 0 && !selectedMeasurement ? "hidden" : "visible" }}
                                >
                                    <KeyboardArrowLeft />
                                </IconButton>

                                <Typography
                                    variant="h6"
                                    sx={{
                                        textAlign: "center",
                                        flexShrink: 0,
                                    }}
                                >
                                    {slides[activeStep].title}
                                </Typography>

                                <IconButton
                                    size="small"
                                    onClick={handleNext}
                                    disabled={activeStep === slides.length - 1}
                                    sx={{ visibility: activeStep === slides.length - 1 ? "hidden" : "visible" }}
                                >
                                    <KeyboardArrowRight />
                                </IconButton>
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 1, // Réduction de 2 à 1 pour diviser l'espace par 2
                                    overflow: "auto", // Ajoute le défilement si nécessaire
                                    flexGrow: 1, // Prend l'espace restant
                                    pr: 1, // Espace pour la barre de défilement
                                    pt: 0, // Suppression du padding top
                                    pb: 0, // Suppression du padding bottom
                                }}
                            >
                                {slides[activeStep].measurements.map((measurement, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            gap: 2,
                                            p: 0.8, // Réduit de 1 à 0.8 (-20%)
                                            borderRadius: 1,
                                            bgcolor: "rgba(0, 0, 0, 0.02)",
                                            minHeight: "24px", // Réduit de 30px à 24px (-20%)
                                            cursor: "pointer",
                                            "&:hover": {
                                                bgcolor: "rgba(0, 0, 0, 0.04)",
                                            },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 1.6, // Réduit de 2 à 1.6 (-20%)
                                            }}
                                        >
                                            {measurement.icon}
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                sx={{
                                                    fontSize: "0.8rem", // Réduit la taille du texte de 20%
                                                }}
                                            >
                                                {measurement.label}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 1,
                                            }}
                                        >
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    fontWeight: 500,
                                                    fontSize: "0.8rem", // Réduit la taille du texte de 20%
                                                }}
                                            >
                                                {measurement.value} {measurement.unit}
                                            </Typography>
                                            <IconButton
                                                size="small"
                                                onClick={() => handleMeasurementClick(measurement)}
                                                sx={{
                                                    padding: 0.5,
                                                }}
                                            >
                                                <ChevronRightIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                    mt: 1, // Réduction du margin top
                                    justifyContent: "center",
                                }}
                            >
                                <Typography variant="body2" sx={{ color: "black", fontWeight: 500 }}>
                                    {currentTime}
                                </Typography>
                                <UpdateIcon sx={{ color: "black", fontSize: 20 }} />
                                <Typography variant="body2" sx={{ color: "gray", fontWeight: 400 }}>
                                    {moreFrequentPeriod ? moreFrequentPeriod : ""}
                                </Typography>
                            </Box>
                        </>
                    )
                ) : (
                    <CommonAlert alarms={alarms} onAlarmDetailsClick={handleAlarmDetailsClick} />
                )}
            </CardContent>

            <CardActions
                disableSpacing
                sx={{
                    display: "flex",
                    justifyContent: "space-between", // Changé de flex-start à space-between
                    alignItems: "center",
                    gap: 1,
                    // Supprimé mt: 'auto' et pt: 2
                }}
            >
                <Box sx={{ display: "flex", gap: 1 }}>
                    <IconButton onClick={() => setOpenSetting(true)} aria-label="paramètres" sx={{}}>
                        <SettingsIcon />
                    </IconButton>
                    <IconButton onClick={() => history.push(`/apps/emera/v2/chart_all/${thingId}`)} aria-label="graphique" sx={{}}>
                        <AutoGraphIcon />
                    </IconButton>
                </Box>
            </CardActions>

            <CommonSetting open={openSetting} setOpen={setOpenSetting} thingId={thingId} />
        </Card>
    );
};

export default IAQWidget;
