import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton, Badge, Tooltip } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CardService from "./CardService";
import { useHistory } from "react-router-dom";

export default function CommonTop({ thingId, showAlarms, setShowAlarms }) {
    const history = useHistory();

    const [thing, setThing] = useState(null);
    const [site, setSite] = useState(null);
    const [batteryLevel, setBatteryLevel] = useState(100);
    const [alertCount, setAlertCount] = useState(0);
    const [alarms, setAlarms] = useState([]);

    useEffect(() => {
        if (thingId) {
            CardService.requestThing(thingId).then((r) => {
                setThing(r);
                if (r?.site) {
                    setSite(r.site);
                }
            });

            CardService.requestLastMeasures(thingId).then((r) => {
                r.forEach((ele) => {
                    if (ele.attribute.name === "battery") {
                        setBatteryLevel(ele.value);
                    }
                });
            });

            CardService.requestAlerts(thingId, setAlarms, setAlertCount);
        }
    }, [thingId]);

    const getBatteryIcon = (battery) => {
        if (!battery && battery === 100) return "fa-battery-full";
        if (battery <= 0) return "fa-battery-empty";
        if (battery <= 25) return "fa-battery-quarter";
        if (battery <= 50) return "fa-battery-half";
        if (battery <= 75) return "fa-battery-three-quarters";
        return "fa-battery-full";
    };

    const handleSiteClick = () => {
        if (site) {
            history.push(`/apps/emera/v2/floor/${site.id}`);
            // console.log("🏢 Navigating to site:", site.name);
        }
    };

    const handleAlarmsClick = () => {
        setShowAlarms(!showAlarms);
        // console.log("⚠️ Alarmes cliquées");
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 1,
            }}
        >
            {/* Section gauche avec Home et titre */}
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <HomeIcon sx={{ color: "black", fontSize: 20 }} />
                {site && (
                    <Typography
                        variant="subtitle1"
                        sx={{
                            fontWeight: 500,
                            cursor: "pointer",
                            "&:hover": {
                                textDecoration: "underline",
                            },
                        }}
                        onClick={handleSiteClick}
                    >
                        {site.name}
                    </Typography>
                )}
                {thing?.fixedName}
            </Box>

            {/* Section droite avec les icônes */}
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Badge
                    badgeContent={alertCount}
                    color="error"
                    sx={{
                        "& .MuiBadge-badge": {
                            top: 5,
                            right: 5,
                        },
                    }}
                >
                    <IconButton
                        onClick={handleAlarmsClick}
                        sx={{
                            color: "black",
                            backgroundColor: showAlarms ? "rgba(0, 0, 0, 0.04)" : "transparent",
                            transform: showAlarms ? "scale(0.95)" : "scale(1)",
                            boxShadow: showAlarms ? "inset 0px 1px 2px rgba(0, 0, 0, 0.2)" : "none",
                            transition: "all 0.2s ease-in-out",
                            "&:hover": {
                                backgroundColor: showAlarms ? "rgba(0, 0, 0, 0.08)" : "rgba(0, 0, 0, 0.04)",
                            },
                        }}
                    >
                        <ErrorOutlineIcon />
                    </IconButton>
                </Badge>

                <Tooltip title={`Batterie: ${batteryLevel}%`} arrow placement="bottom">
                    <IconButton
                        aria-label="btn-battery"
                        sx={{
                            "&.MuiButtonBase-root:hover": {
                                cursor: "default",
                            },
                        }}
                        size="small"
                    >
                        <i className={`fa ${getBatteryIcon(batteryLevel)}`} aria-hidden="true" style={{ fontSize: "20px" }}></i>
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    );
}
