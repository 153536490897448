import _ from "underscore";
import UtilService from "Services/utilService";
import Auth from "@aws-amplify/auth";
import { getJwtToken } from "./basicAuthService";
import Cookies from "js-cookie";

// Get auth type from environment variable
const authType = process.env.REACT_APP_AUTH_TYPE;

let _csrfLoading = false;
let _csrfPromise;

const ApiService = {
    init: {
        headers: new Headers(),
    },

    getReady: async () => {
        if (authType === "basic") {
            var requestOptions = {
                method: "GET",
                headers: ApiService.init.headers,
            };
            // requestOptions.headers.set("Content-Type", "application/json");

            const result = await fetch(`/csrf`, requestOptions);
            const json = await result.json();

            requestOptions.headers.set("X-CSRF-TOKEN", json.token);

            const token = getJwtToken();
            requestOptions.headers.set("Authorization", `Bearer ${token}`);
        } else {
            return Auth.currentSession().then((res) => {
                const accessToken = res.getAccessToken();
                const idToken = res.getIdToken();

                ApiService.init.headers.set("Content-Type", `application/json`);
                ApiService.init.headers.set("Authorization", `Bearer ${accessToken.jwtToken}`);

                // console.log("accessToken.payload", accessToken.jwtToken);
                return {
                    username: accessToken.payload.username,
                    groups: accessToken.payload["cognito:groups"],
                    zoneinfo: idToken.payload.zoneinfo,
                    firstName: idToken.payload.name,
                    lastName: idToken.payload.family_name,
                    email: idToken.payload.email,
                    locale: idToken.payload.locale,
                };
            });
        }
    },

    getCsrf: async () => {
        if (!_csrfLoading) {
            _csrfLoading = true;
            _csrfPromise = new Promise((resolve, reject) => {
                fetch("/csrf")
                    .then((r) => {
                        if (r.status >= 200 && r.status < 300) {
                            return r.json();
                        } else {
                            window.location.href = "/";
                            return reject();
                        }
                    })
                    .then((json) => {
                        ApiService.init.headers.set("X-CSRF-TOKEN", json.token);
                        resolve();
                    })
                    .catch((e) => {
                        console.error(e);
                        reject(e);
                    });
            });
        }

        return _csrfPromise;
    },

    getValues: async (configurations) => {
        await ApiService.getReady();

        // console.log(configurations)

        return Promise.all(
            configurations.map(async ({ options, filters, url }) => {
                let mergedOptions = { ...options, ...ApiService.getFilterParameters(filters) };

                const finalUrl = ApiService.getUrl(url, mergedOptions);

                const result = await fetch(finalUrl, ApiService.init);
                const json = await result.json();

                return json;
            })
        ).catch((e) => console.error(e));
    },

    getParam: function (filter) {
        var result;
        var operator;

        if (filter.hasOwnProperty("field") && filter.hasOwnProperty("value")) {
            operator = filter.operator ? ":" + filter.operator + "_" : ":eq_";

            if (filter.operator.toLowerCase() === "in") {
                result = filter.field + operator + '"' + filter.value + '"';
            } else {
                result = filter.field + operator + filter.value;
            }
        }

        return result;
    },

    getFilterParameters: (filtersParam) => {
        var result = {};
        var filterObjects = {};
        var orFilters = [];
        var filters = [];

        if (Array.isArray(filtersParam)) {
            filtersParam.forEach(function (filter) {
                var key = filter.field + "_";
                var operator = filter.operator;

                if (filter.value && filter.value.includes(",")) {
                    operator = filter.operator === "eq" ? "in" : filter.operator;
                }

                key = key + operator;

                if (filterObjects.hasOwnProperty(key)) {
                    filterObjects[key].value = filterObjects[key].value + "," + filter.value;
                    filterObjects[key].filterType = "orFilter";
                } else {
                    filterObjects[key] = { ...filter };
                }

                filterObjects[key].operator = operator;
            });
        }

        Object.keys(filterObjects).forEach(function (key) {
            var filter = filterObjects[key];

            if (filter.filterType === "orFilter") {
                orFilters.push({
                    property: filter.field,
                    operator: filter.operator === "eq" ? "IN" : "NOT IN",
                    value: filter.value,
                });
            } else {
                filters.push(ApiService.getParam(filter));
            }
        });

        if (orFilters.length > 0) result.orFilter = JSON.stringify(orFilters);

        if (filters) result.filter = filters.join(",");

        return result;
    },

    getUrl: function (url, args) {
        let tmpArgs = { ...args };
        let resultUrl;

        tmpArgs = ApiService.calculateArguments(tmpArgs);

        const result = ApiService.executeUrlTemplate(url, tmpArgs);

        return ApiService.concatQueryParameter(result.url, result.queryParameters);
    },

    getFilter: function (filters) {
        var filterValue;

        if (Array.isArray(filters) && filters.length > 0) {
            filterValue = "";
            filters.forEach(function (filter, index) {
                if (index > 0) {
                    filterValue += ",";
                }

                // eslint-disable-next-line no-undef
                filterValue += Starter.model.dashboard.Filter.getParam(filter);
            });
        }

        return filterValue;
    },

    calculateArguments: function (args) {
        let result = {};
        let argsToDelete = [];

        if (!args) return;

        Object.keys(args).forEach(function (param) {
            if (args[param] && args[param].type === "DATE") {
                result[param] = ApiService.calculateDate(args[param]);
            } else if (typeof args[param] === "number" || Array.isArray(args[param])) {
                result[param] = args[param];
            } else {
                const newParametersObj = ApiService.executeParameterTemplate(args, param);
                result[param] = newParametersObj.result;
                argsToDelete = argsToDelete.concat(newParametersObj.argsToDelete);
            }
            // if(!result[param]) delete result[param]
        });

        result = { ...args, ...result };

        argsToDelete.forEach((elem) => delete result[elem]);

        return result;
    },

    executeParameterTemplate: function (args, key) {
        var matchedParam = args[key] || "";
        var result = args[key];
        const argsToDelete = [];

        matchedParam = matchedParam.match(/{([^"]*?)}/g) || [];

        matchedParam
            .map((elem) => {
                const field = elem.replace(/[{}]/g, "");
                return [elem, field, args[field]];
            })
            .forEach(function ([elem, field, value]) {
                if (!value) result = result.replace(elem, "");
                else result = result.replace(elem, value);

                argsToDelete.push(field);
            });

        if (!args[key]) argsToDelete.push(key);

        return { result, argsToDelete };
    },

    /**
     * Replace the element surrounded with {ELEMENT} inside the url. It does delete used element.
     * @param {String} url
     * @param {Object} args
     */
    executeUrlTemplate: function (url, args) {
        var matchedParam;
        const tmpArgs = { ...args };
        let urlResult = url;

        matchedParam = urlResult.match(/{(.*?)}/g) || [];

        matchedParam
            .map((elem) => {
                const field = elem.replace(/[{}]/g, "");

                return [elem, field, tmpArgs[field]];
            })
            .forEach(function ([elem, field, value]) {
                if (!value) urlResult = urlResult.replace(elem, "");
                else urlResult = urlResult.replace(elem, value);

                delete tmpArgs[field];
            });

        if (tmpArgs["agg"] !== undefined) {
            urlResult += encodeURI(tmpArgs["agg"]);
            delete tmpArgs.agg;
        }

        return {
            url: !urlResult.startsWith("/stats") && !urlResult.startsWith("/api") ? "/stats".concat(urlResult) : urlResult,
            queryParameters: tmpArgs,
        };
    },
    /**
     * Concat the parameters comming from the args into the url
     * @param {String} url
     * @param {Object} args
     */
    concatQueryParameter: function (url, args) {
        var result = url;

        if (Object.keys(args).length > 0) {
            var copy = { ...args };

            if (Object.keys(copy).length > 0) {
                if (result.indexOf("?") < 0) result += "?";

                Object.keys(args).forEach(function (key) {
                    if (args[key] === undefined || args[key] === null || key === "fn") return;

                    if (result[result.length - 1] !== "?") result += "&";
                    result += key + "=" + encodeURI(args[key]);
                });
            }
        }

        return result;
    },

    calculateDate: function (param) {
        if (!param) {
            return null;
        } else if (param.hasOwnProperty("start")) {
            return ApiService.getIntervalStart(param, param.offset);
        } else if (param.hasOwnProperty("end")) {
            return ApiService.getIntervalEnd(param, param.offset);
        }
    },

    getIntervalStart: function (interval, tmpOffset) {
        let multipliedOffset;
        let offset = tmpOffset ? tmpOffset : 0;
        let result;

        if (interval && Array.isArray(interval.parameters)) {
            multipliedOffset = { value: interval.parameters[0], unit: interval.parameters[1] };
        } else {
            multipliedOffset = { ...interval };
        }

        if (multipliedOffset.value > 0) multipliedOffset.value = multipliedOffset.value + multipliedOffset.value * offset;
        else multipliedOffset.value = offset;

        const date = new Date();

        if (multipliedOffset.hasOwnProperty("value") && multipliedOffset.hasOwnProperty("unit"))
            result = ApiService.convertIntervalToDate(date, multipliedOffset.value, multipliedOffset.unit);
        else result = date;

        return ApiService.getUtcTime(ApiService.endOf(result, "day"));
    },

    getIntervalEnd: function (interval, offset) {
        let multipliedOffset = { ...interval };
        let result;

        if (multipliedOffset.value > 0) multipliedOffset.value = multipliedOffset.value + multipliedOffset.value * (offset - 1);
        else multipliedOffset.value = offset;

        const date = new Date();

        if (multipliedOffset.hasOwnProperty("value") && multipliedOffset.hasOwnProperty("unit"))
            result = ApiService.convertIntervalToDate(date, multipliedOffset.value, multipliedOffset.unit);
        else result = date;

        return ApiService.getUtcTime(ApiService.endOf(result, "day"));
    },

    getUtcTime: (date) => {
        return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    },

    convertIntervalToDate: (date, value, unit) => {
        const dateFn = {
            month: ["getMonth", "setMonth"],
            day: ["getDate", "setDate"],
        };
        const getDateFn = dateFn[unit][0];
        const setDateFn = dateFn[unit][1];

        let result = new Date(date.getTime());

        result[setDateFn].apply(result, [result[getDateFn].call(result) - value]);

        return result;
    },

    endOf: (date, endOf) => {
        let result = new Date(date.getTime());

        switch (endOf) {
            case "day":
                result.setHours(23, 59, 59, 999);
                break;
            case "month":
                result.setHours(23, 59, 59, 999);
                result.setMonth(result.getMonth() + 1);
                result.setDate(-1);
                break;
            default:
                break;
        }

        return result;
    },

    getDashboards: async () => {
        await ApiService.getReady();

        var requestOptions = {
            method: "GET",
            headers: ApiService.init.headers,
        };

        return fetch(`/api/dashboards`, requestOptions).then((r) => r.json());
    },

    /**
     * Request data to the server by the GET method
     *
     * @param {*} config must have a url
     * @returns
     */
    getData: async (config) => {
        await ApiService.getReady();

        var requestOptions = {
            method: "GET",
            headers: ApiService.init.headers,
        };

        let params = [];
        if (!UtilService.isEmpty(config.params)) {
            const p = config.params;

            for (const key in p) {
                if (p.hasOwnProperty(key)) {
                    params.push(`${key}=${encodeURIComponent(p[key])}`);
                }
            }
        }

        let queryString = "";
        if (params.length != 0) {
            queryString += "?";
            queryString += params.join("&");
        }

        const result = await fetch(config.url + queryString, requestOptions);
        const json = await result.json();

        return json;
    },

    getDataWithParams: async (url, params) => {
        await ApiService.getReady();

        var requestOptions = {
            method: "GET",
            headers: ApiService.init.headers,
        };

        const keyValueArray = Object.entries(params);
        const keyValueStrings = keyValueArray.map(([key, value]) => `${key}=${value}`);
        const queryString = keyValueStrings.join("&");

        // console.log("queryString=" + queryString);
        const result = await fetch(url + "?" + queryString, requestOptions);
        const json = await result.json();

        return json;
    },

    /**
     * Put the data to the server by the PUT method
     *
     * @param {*} config must have a url and data, the data is a json object
     * @returns
     */
    putData: async (config) => {
        await ApiService.getReady();

        var requestOptions = {
            method: "PUT",
            headers: ApiService.init.headers,
            body: JSON.stringify(config.data),
        };
        requestOptions.headers.set("Content-Type", "application/json");

        const result = await fetch(config.url, requestOptions);
        const json = await result.json();

        return json;
    },

    postData: async (config) => {
        await ApiService.getReady();

        var requestOptions = {
            method: "POST",
            headers: ApiService.init.headers,
            body: JSON.stringify(config.data),
        };
        requestOptions.headers.set("Content-Type", "application/json");

        const result = await fetch(config.url, requestOptions);
        const json = await result.json();

        return json;
    },

    postDataFile: async (config, files) => {
        await ApiService.getReady();

        const formData = new FormData();

        if (files) {
            if (files[0]) {
                formData.append("file_ca", files[0]);
            }
            if (files[1]) {
                formData.append("file_cert", files[1]);
            }
            if (files[2]) {
                formData.append("file_private", files[2]);
            }
        }

        formData.append("json_data", JSON.stringify(config.data));

        var requestOptions = {
            method: "POST",
            headers: ApiService.init.headers,
            // body: JSON.stringify(config.data),
            body: formData,
        };

        // requestOptions.headers.set("Content-Type", "multipart/form-data");
        // Client set the type automatically. if the type is set manually, connecton couldn't be maid
        requestOptions.headers.delete("Content-Type");
        // console.log(requestOptions);

        const result = await fetch(config.url, requestOptions);
        const json = await result.json();

        return json;
    },

    deleteData: async (config) => {
        await ApiService.getReady();

        var requestOptions = {
            method: "DELETE",
            headers: ApiService.init.headers,
            body: JSON.stringify(config.data),
        };
        requestOptions.headers.set("Content-Type", "application/json");

        const result = await fetch(config.url, requestOptions);

        if (result.status == 200 && result.ok == true) {
            return "ok";
        } else {
            return result.error;
        }
    },

    deleteMultipleData: async (config) => {
        await ApiService.getReady();

        var requestOptions = {
            method: "DELETE",
            headers: ApiService.init.headers,
        };
        requestOptions.headers.set("Content-Type", "application/json");

        return fetch(config.url, requestOptions);
    },

    uploadFile: async (config, auth) => {
        await ApiService.getReady();

        let promiseResponse = new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", config.file);

            if (config.params) {
                for (const [key, value] of Object.entries(config.params)) {
                    formData.append(key, value);
                }
            }

            var xhr = new XMLHttpRequest();
            xhr.open("POST", config.url, true);
            // xhr.setRequestHeader("X-CSRF-TOKEN", ApiService.init.headers.get("X-CSRF-TOKEN"));

            if (auth === "basic") {
                const token = Cookies.get("jwtToken");

                // console.log("token", token);
                xhr.setRequestHeader("Authorization", `Bearer ${token}`);
            } else {
                xhr.setRequestHeader("Authorization", ApiService.init.headers.get("Authorization"));
            }

            xhr.onload = function (e) {
                if (this.status == 200) {
                    resolve(this.response);
                } else {
                    reject(this.response);
                }
            };
            xhr.send(formData);
        });

        return promiseResponse;
    },

    downloadFile: async (config) => {
        await ApiService.getReady();

        var requestOptions = {
            method: "GET",
            headers: ApiService.init.headers,
        };

        let params = [];
        if (!UtilService.isEmpty(config.params) && !UtilService.isEmpty(config.params.filter)) {
            params.push("filter=" + config.params.filter);
        }
        if (!UtilService.isEmpty(config.params) && !UtilService.isEmpty(config.params.orFilter)) {
            params.push("orFilter=" + config.params.orFilter);
        }

        let queryString = "";
        if (params.length != 0) {
            queryString += "?";
            queryString += params.join("&");
        }

        const result = await fetch(config.url + queryString, requestOptions);
        return result.blob();
    },

    downloadCsv: async (config) => {
        await ApiService.getReady();

        var requestOptions = {
            method: "GET",
            headers: ApiService.init.headers,
        };

        let params = [];
        // Convert params object to array of key=value strings
        if (config.params) {
            for (const [key, value] of Object.entries(config.params)) {
                params.push(`${key}=${encodeURIComponent(value)}`);
            }
        }

        let queryString = "";
        if (params.length != 0) {
            queryString += "?";
            queryString += params.join("&");
        }

        // console.log("config.url + queryString", config.url + queryString);

        const result = await fetch(config.url + queryString, requestOptions);
        // console.log("result", result);

        return result.text();
    },
};

export default ApiService;
