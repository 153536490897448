import ApiService from "Services/apiService";
import _ from "underscore";

const AssignService = {
    abortController: new AbortController(),

    requestCloudUser: async (page, pageSize, setRows, setRowCount, company, sort) => {
        let filter = "filter=cloud_user.domain_name:eq_iflowinc";
        if (company != "FLOWINC") {
            // filter += `,cloud_user.groups:ilike_${company}`;
            filter += `,company.name:eq_${company}`;
        }

        let sortParam = "sort=";
        if (sort) {
            sortParam += sort
                .map((s) => {
                    return (s.sort == "asc" ? "+" : "-") + s.field;
                })
                .join(",");
        }

        let requestOptions = {
            url: `/api/cloud_user?pageSize=${pageSize}&page=${page + 1}&${filter}&${sortParam}`,
        };

        // console.log(company)
        // console.log(requestOptions);

        return ApiService.getData(requestOptions).then((res) => {
            // console.log("company=" + company);
            // console.log("response in requestCloudUser");
            // console.log(res);

            let mapped = res.data.map((r) => {
                return {
                    id: r.id,
                    "cloud_user.email": r.email,
                    username: r.username,
                    company: r.company.name,
                    role: r.role,
                    enabled: r.enabled,
                };
            });

            setRows(mapped);
            setRowCount(res.totalSize);
        });
    },

    requestCloudUserThing: async (cloudUserId, page, pageSize, setRows, setRowCount, sort) => {
        let filter = `&filter=cloud_user.id:eq_${cloudUserId}`;

        let sortParam = "";
        if (!_.isEmpty(sort)) {
            sortParam = "&sort=";
            sortParam += sort.map((s) => (s.sort == "asc" ? "-" : "+") + s.field).join(",");

            // console.log(sortParam);
        }

        let requestOptions = {
            url: `/api/cloud_user/things?pageSize=${pageSize}&page=${page + 1}${filter}${sortParam}`,
        };

        return ApiService.getData(requestOptions).then((res) => {
            // console.log(res.data)

            let mapped = res.data.map((r) => {
                return {
                    id: r.thing.id,
                    "thing.name": r.thing.name,
                    "thing.fixedName": r.thing.fixedName,
                };
            });

            setRows(mapped);
            setRowCount(res.totalSize);
        });
    },

    requestCloudUserThingAll: async (cloudUserId, setAllAssignedThing) => {
        let requestOptions = {
            url: `/api/cloud_user/${cloudUserId}/things`,
        };

        return ApiService.getData(requestOptions).then((res) => {
            const thingIds = res.map((r) => {
                // console.log(r);
                return r.thing.id;
            });
            setAllAssignedThing(thingIds);
            return thingIds;
        });
    },

    requestCloudUserThingByUsername: async (keyword, username, page, pageSize, setRows, setRowCount) => {
        let orFilter = [
            {
                property: ["thing.name", "thing.fixedName", "site.name", "tags"],
                operator: "ilike",
                value: keyword,
            },
        ];
        let filter = `cloud_user.username:eq_${username}`;

        let requestOptions = {
            url: `/api/cloud_user/things?pageSize=${pageSize}&page=${page + 1}&orFilter=${encodeURI(
                JSON.stringify(orFilter)
            )}&filter=${filter}&sort=thing.name`,
        };

        // let temp = [];
        // console.log(requestOptions.url);
        // console.log(encodeURI(JSON.stringify(orFilter)));

        return ApiService.getData(requestOptions).then((res) => {
            // console.log(res);

            if (!res) return;

            let mapped = res.data.map((r) => {
                let formattedSize = "";

                if (r.thing && r.thing.attrSizeValue) {
                    switch (r.thing.attrSizeValue) {
                        case "0":
                            formattedSize = "";
                            break;
                        case "10":
                            formattedSize = "5/8 x ¾”";
                            break;
                        case "20":
                            formattedSize = "¾” x ¾”";
                            break;
                        case "30":
                            formattedSize = "1”";
                            break;
                        case "40":
                            formattedSize = "1 ½”";
                            break;
                        case "50":
                            formattedSize = "2";
                            break;
                        default:
                            formattedSize = "";
                            break;
                    }
                }

                return {
                    id: r.thing.id,
                    "thing.name": r.thing.name,
                    "thing.fixedName": r.thing.fixedName,
                    "thing.status": r.thing.status,
                    size: formattedSize,
                    radio: r.thing.attrRadioValue && r.thing.attrRadioValue == "true" ? "On" : "Off",
                };
            });

            // console.log(mapped);
            setRows(mapped);
            setRowCount(res.totalSize);
        });
    },

    requestThings: async (keyword, pageSize, page, setRows, setRowCount, sort) => {
        if (AssignService.abortController) {
            AssignService.abortController.abort(); // Cancel the previous request
        }

        AssignService.abortController = new AbortController(); // Create a new controller for the current request
        const { signal } = AssignService.abortController;

        let orFilter = [
            {
                property: [
                    "thing.name",
                    "thing.fixedName",
                    // 'thing.status',
                    // 'connectivity.status',
                    // 'connectivity.type',
                    // 'product.name',
                    // 'product.reference',
                    // 'product.model.name',
                    // 'product.manufacturer.name',
                    "site.name",
                    "tags",
                    // 'company'
                ],
                operator: "ilike",
                value: keyword,
            },
        ];

        // console.log(sort);

        let filter = "company.name:eq_FLOWINC";

        let sortParam = "";
        if (!_.isEmpty(sort)) {
            sortParam = "&sort=";
            sortParam += sort.map((s) => (s.sort == "asc" ? "-" : "+") + s.field).join(",");

            // console.log(sortParam);
        }

        let requestOptions = {
            url: `/api/things?pageSize=${pageSize}&page=${page + 1}&orFilter=${encodeURI(JSON.stringify(orFilter))}&filter=${filter}${sortParam}&detailed=true`,
            signal,
        };

        // console.log("-------")

        await ApiService.getData(requestOptions).then((res) => {
            // console.log(res);
            if (!res) return;

            let mapped = res.data.map((r) => {
                let formattedSize = "";
                switch (r.attrSizeValue) {
                    case "0":
                        formattedSize = "";
                        break;
                    case "10":
                        formattedSize = "5/8 x ¾”";
                        break;
                    case "20":
                        formattedSize = "¾” x ¾”";
                        break;
                    case "30":
                        formattedSize = "1”";
                        break;
                    case "40":
                        formattedSize = "1 ½”";
                        break;
                    case "50":
                        formattedSize = "2";
                        break;
                    default:
                        formattedSize = "";
                        break;
                }

                return {
                    id: r.id,
                    thing_name: r.name,
                    "thing.fixedName": r.fixedName,
                    "thing.status": r.status,
                    size: formattedSize,
                    radio: r.attrRadioValue == "true" ? "On" : "Off",
                };
            });

            setRows(mapped);
            setRowCount(res.totalSize);
        });
    },

    assignThings: async (cloudUserId, things) => {
        let requestOptions = {
            url: "/api/cloud_user/assign",
            data: {
                cloudUserId: cloudUserId,
                things: things,
            },
        };

        return ApiService.putData(requestOptions).then((res) => {
            // console.log(res);
        });
    },

    removeThings: async (cloudUserId, things) => {
        let requestOptions = {
            url: "/api/cloud_user/remove",
            data: {
                cloudUserId: cloudUserId,
                things: things,
            },
        };

        return ApiService.putData(requestOptions).then((res) => {
            // console.log(res);
        });
    },

    // The username must be passed along with
    importUsers: async () => {
        // emailDomain could be full email address or domanin name only
        // All user MUST are in the 'COMPANY_FLOWINC_XXX' group
        // ex) youngcheol.dev@gmail.com or iflowinc.com
        let requestOptions = {
            url: "/api/cloud_user/import_users",
            data: {
                emailDomain: ["youngcheol.dev@gmail.com", "eszymko@icloud.com", "iflowinc.com"],
            },
        };

        return ApiService.putData(requestOptions).then((res) => {
            // do nothing
            return;
        });
    },

    getSubGroups: async (setCompanies) => {
        let requestOptions = {
            url: "/api/cloud_user/getSubGroups?group=COMPANY_CALSUB",
        };

        return ApiService.getData(requestOptions).then((res) => {
            // console.log("getSubGroups...")
            // console.log(res)
            setCompanies(
                res.map((company) => {
                    return company;
                })
            );
        });
    },

    changeGroup: async (username, groups) => {
        let requestOptions = {
            url: "/api/cloud_user/change_group",
            data: {
                username: username,
                groups: [groups],
            },
        };

        return ApiService.putData(requestOptions).then((res) => {
            // do nothing
            return;
        });
    },

    changeRole: async (username, role) => {
        let requestOptions = {
            url: "/api/cloud_user/change_role",
            data: {
                username: username,
                role: role,
            },
        };

        // console.log("changeRole in AssignService")
        // console.log(requestOptions)

        return ApiService.putData(requestOptions).then((res) => {
            // do nothing
            return;
        });
    },

    changeEnabled: async (username, enabled) => {
        let requestOptions = {
            url: "/api/cloud_user/enabled",
            data: {
                username: username,
                enabled: enabled,
            },
        };

        // console.log("changeRole in AssignService")
        // console.log(requestOptions)

        return ApiService.putData(requestOptions).then((res) => {
            // do nothing
            // console.log(res);
            return;
        });
    },

    createUser: async (email, firstName, lastName, company, role, locale, zoneinfo) => {
        let requestOptions = {
            url: "/api/cloud_user/create",
            data: {
                email: email,
                name: lastName,
                familyName: firstName,
                company: company.replace("COMPANY_", ""),
                authority: ["PTH"],
                locale: locale,
                zoneinfo: zoneinfo,
                groups: ["PTH", "CLIENT_ADMIN", "APPLICATION_WATER_METERING", company],
                domainName: process.env.REACT_APP_DOMAIN_NAME,
                role: role.toUpperCase(),
            },
        };

        if (role === "user") {
            requestOptions.data.groups.push("TENANTS");
        }
        if (role === "manager") {
            requestOptions.data.groups.push("MANAGER");
        }

        let apps = ["APPLICATION_WATER_METERING"];
        requestOptions.data["applications"] = apps;

        // console.log("createUser in AssignService");
        // console.log(requestOptions);

        return ApiService.postData(requestOptions)
            .then((res) => {
                // console.log(res);
                return res;
            })
            .catch((e) => {
                // do nothing
                console.error(e);
            });
    },

    validateThings: async (cloudUserId, selectedThings) => {
        let requestOptions = {
            url: "/api/cloud_user/validate",
            data: {
                userId: cloudUserId,
                things: selectedThings,
            },
        };

        // console.log("validateThings in AssignService");
        // console.log(requestOptions);

        const result = await ApiService.putData(requestOptions);

        if (result && result.code == "failure") {
            throw result.message;
        }
        return result;
    },

    requestSubCompaines: async (setSubCompanies) => {
        let requestOptions = {
            url: `/v2/companies/sub`,
            params: {
                page: 0,
                pageSize: 100,
                sort: "name:asc",
                companyName: "FLOWINC",
            },
        };

        return ApiService.getData(requestOptions).then((res) => {
            // console.log(res);
            setSubCompanies(res.content);
        });
    },

    requestSusidiaryWithMine: async (setGroup) => {
        let requestOptions = {
            url: `/v2/companies/sub_with_mine`,
            params: {
                companyName: "FLOWINC",
            },
        };

        return ApiService.getData(requestOptions).then((res) => {
            // console.log(res);
            setGroup(res.map((r) => r.name));
        });
    },
};

export default AssignService;
