import ApiService from "Services/apiService";
import UtilService from "Services/utilService";
import dayjs from "dayjs";

const CardService = {
    requestLastMeasures: async (thingId, attributeIds) => {
        let requestOptions = {
            url: `/stats/last/things/${thingId}`,
        };

        // console.log(requestOptions);
        return ApiService.getData(requestOptions).then((response) => {
            // console.log(response);
            return attributeIds ? response.data.filter((r) => attributeIds.includes(r.attribute.id)) : response.data;
        });
    },

    requestThing: async (thingId) => {
        let requestOptions = {
            url: `/api/things/${thingId}?detailed=true`,
        };

        return ApiService.getData(requestOptions).then((response) => {
            // console.log(response);
            return response;
        });
    },

    requestAlerts: async (thingId, setAlarms, setAlertCount) => {
        let requestOptions = {
            url: `/api/alerts`,
            params: {
                filter: `thing.id:eq_${thingId}`,
                limit: 100,
                sort: "creationDate",
                dir: "DESC",
            },
        };

        return ApiService.getData(requestOptions).then((response) => {
            // console.log("response", response);

            const mapped = response.data.map((r) => {
                return {
                    severity: r.level.toLowerCase(),
                    timestamp: UtilService.formatMDYHm(r.creationDate, "fr"),
                    threshold: 0,
                    value: 0,
                };
            });
            // console.log(mapped);
            setAlarms(mapped);
            setAlertCount(response.totalSize);

            return response;
        });
    },

    request12HChartData: async (thingId, setDayData) => {
        const attributeId = "d26f8245-3310-42fe-9778-c2673e4f5d4f";

        let requestOptions = {
            url: `/stats/measurements`,
            params: {
                filter: `thing.id:eq_${thingId},attribute.id:eq_${attributeId}`,
                start: dayjs().subtract(12, "hours").valueOf(), // 12 hours ago
                end: dayjs().valueOf(), // current time
                interval: "hour",
                time: 1,
            },
        };
        // console.log(requestOptions);

        return ApiService.getData(requestOptions).then((r) => {
            // console.log(r);

            const mapped = r.map((ele) => {
                // console.log(ele);
                return {
                    min: ele.min,
                    max: ele.max,
                    value: ele.value,
                    hour: UtilService.formate24H(ele.start * 1000),
                };
            });
            // console.log(mapped);
            setDayData(mapped);

            return mapped;
        });
    },

    request24HChartData: async (thingId, setDayData) => {
        const attributeId = "d26f8245-3310-42fe-9778-c2673e4f5d4f";

        let requestOptions = {
            url: `/stats/measurements`,
            params: {
                filter: `thing.id:eq_${thingId},attribute.id:eq_${attributeId}`,
                start: dayjs().startOf("day").valueOf(),
                end: dayjs().endOf("day").valueOf(),
                interval: "hour",
                time: 1,
            },
        };
        // console.log(requestOptions);

        return ApiService.getData(requestOptions).then((r) => {
            // console.log(r);

            const mapped = r.map((ele) => {
                // console.log(ele);
                return {
                    min: ele.min,
                    max: ele.max,
                    value: ele.value,
                    hour: UtilService.formate24H(ele.start * 1000),
                };
            });
            // console.log(mapped);
            setDayData(mapped);

            return mapped;
        });
    },

    requestMaxToday: async (thingId, setMaxSoundLevel) => {
        const attributeId = "d26f8245-3310-42fe-9778-c2673e4f5d4f";

        let requestOptions = {
            url: `/stats/measurements`,
            params: {
                filter: `thing.id:eq_${thingId},attribute.id:eq_${attributeId}`,
                start: dayjs().startOf("day").valueOf(), // 24 hours ago in epoch seconds
                end: dayjs().endOf("day").valueOf(), // current time in epoch seconds
                interval: "hour",
                time: 1,
            },
        };
        // console.log(requestOptions);

        return ApiService.getData(requestOptions).then((r) => {
            // console.log(r);

            const maxElement = r.reduce((max, ele) => {
                return ele.max > max.max ? ele : max;
            }, r[0]);

            const mapped = {
                min: maxElement.min,
                max: maxElement.max,
                avg: maxElement.avg,
                hour: UtilService.formate24H(maxElement.start * 1000),
            };
            // console.log(mapped);
            setMaxSoundLevel(mapped?.max);

            return mapped;
        });
    },

    // requestMoreFrequentPeriod: async (thingId, setMoreFrequentPeriod) => {
    requestAvgToday: async (thingId, setAvgSoundLevel) => {
        try {
            // Appel à votre API pour obtenir la moyenne
            const response = await fetch(`/api/sound/average/${thingId}`);
            const data = await response.json();
            setAvgSoundLevel(Math.round(data.average));
        } catch (error) {
            console.error("Erreur lors de la récupération de la moyenne :", error);
            setAvgSoundLevel(0);
        }
    },

    requestMoreFrequentPeriod: async (thingId, attributeId, setMoreFrequentPeriod, setAvgSoundLevel) => {
        let requestOptions = {
            url: `/stats/measurements/all`,
            params: {
                filter: `thing.id:eq_${thingId}`,
                start: dayjs().startOf("day").valueOf(),
                end: dayjs().endOf("day").valueOf(),
            },
        };

        if (attributeId) {
            requestOptions.params.filter += `,attribute.id:eq_${attributeId}`;
        }

        return ApiService.getData(requestOptions).then((r) => {
            // console.log(r);

            // Calculate average of values
            const average = r.reduce((sum, measurement) => sum + measurement.value, 0) / r.length;

            // Sort measurements by start time
            const sortedData = [...r].sort((a, b) => a.start - b.start);

            // Calculate time differences between consecutive measurements
            const timeDiffs = [];
            for (let i = 1; i < sortedData.length; i++) {
                const diff = sortedData[i].start - sortedData[i - 1].start;
                if (diff > 0) {
                    timeDiffs.push(diff);
                }
            }
            // console.log(timeDiffs)

            // Find the most frequent time difference
            const frequencyMap = {};
            let maxFrequency = 0;
            let mostFrequentDiff = 0;

            timeDiffs.forEach((diff) => {
                frequencyMap[diff] = (frequencyMap[diff] || 0) + 1;
                if (frequencyMap[diff] > maxFrequency) {
                    maxFrequency = frequencyMap[diff];
                    mostFrequentDiff = diff;
                }
            });

            const mapped = {
                frequentPeriod: mostFrequentDiff, // in milliseconds
                frequency: maxFrequency,
                periodInMinutes: Math.round(mostFrequentDiff / (1000 * 60)), // Convert ms to minutes
                average: Math.round(average * 100) / 100, // Round to 2 decimal places
            };

            // console.log(mapped);
            if (setAvgSoundLevel) setAvgSoundLevel(mapped.average);

            if (mapped.periodInMinutes > 0) {
                setMoreFrequentPeriod(mapped.periodInMinutes + " min");
            }
            return mapped;
        });
    },

    requestWaterLeakHistory: async (thingId, attributeId, setLeakHistory) => {
        let requestOptions = {
            url: `/v2/stats/water_leak_history`,
            params: {
                thingId,
                attributeId,
                start: dayjs().subtract(1, "month").unix(),
                end: dayjs().unix(),
            },
        };
        // console.log(requestOptions);

        return ApiService.getData(requestOptions).then((r) => {
            // console.log(r);

            if (r && Array.isArray(r)) {
                const lastThreeElements = r.slice(-3);
                const mapped = lastThreeElements.map((e) => {
                    return {
                        start: e.start,
                        end: e.end,
                        durationInMinutes: (e.end - e.start) / 60,
                    };
                });

                // console.log(mapped);
                setLeakHistory(mapped);

                return lastThreeElements;
            }
        });
    },

    requestSites: async (company, setSites) => {
        let requestOptions = {
            url: `/v2/sites/by_company`,
            params: { company },
        };
        // console.log(requestOptions);

        return ApiService.getData(requestOptions).then((response) => {
            // console.log(response);
            setSites(response);
            return response;
        });
    },

    updateSite: async (thingId, siteId) => {
        let requestOptions = {
            url: `/v2/things/site`,
            data: { thingId },
        };

        if (siteId !== undefined) {
            requestOptions.data["siteId"] = siteId;
        }

        return ApiService.putData(requestOptions);
    },
};

export default CardService;
