import { useEffect, useState } from "react";
import _ from "underscore";
import FormControlLabel from "@mui/material/FormControlLabel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ConfirmDialog from "./ConfirmDialog";
import ShareService from "./ShareService";
import CustomStyle from "Components/CustomStyle";
import validator from "validator";
import MessageFormatPanel from "./MessageFormatPanel";
import { a } from "aws-amplify";

export default function SetupMQTT({ setActiveStep, goCompletePage, goFirstPage, on, userCompany }) {
    const [txtMessageFormat, setTxtMessageFormat] = useState();
    const [companyName, setCompanyName] = useState(userCompany);

    const [flowId, setFlowId] = useState();
    const [forwardTargetId, setForwardTargetId] = useState();

    const [url, setUrl] = useState("");
    const [topic, setTopic] = useState("");
    const [clientId, setClientId] = useState("");
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [enabled, setEnabled] = useState(false);

    const [chkMessage, setChkMessage] = useState(false);
    const [chkThing, setChkThing] = useState(false);
    const [chkProduct, setChkProduct] = useState(false);
    const [chkGateway, setChkGateway] = useState(false);

    const [errUrl, setErrUrl] = useState(false);
    const [errTopic, setErrTopic] = useState(false);
    const [errUsername, setErrUsername] = useState(false);
    const [errPassword, setErrPassword] = useState(false);

    useEffect(() => {
        setActiveStep(1);

        ShareService.getFlowTargetMqtt(
            companyName,
            setFlowId,
            setForwardTargetId,
            setUrl,
            setTopic,
            setClientId,
            setUserName,
            setPassword,
            setEnabled,
            setChkMessage,
            setChkThing,
            setChkProduct,
            setChkGateway
        );

        ShareService.requestMessageFormat("MQTT", setTxtMessageFormat, [chkMessage, chkThing, chkProduct, chkGateway]);
    }, [userCompany, companyName]);

    const handleSave = () => {
        if (validator.isEmpty(url)) {
            setErrUrl(true);
            return;
        }
        setErrUrl(false);
        if (validator.isEmpty(topic)) {
            setErrTopic(true);
            return;
        }
        setErrTopic(false);
        if (validator.isEmpty(username)) {
            setErrUsername(true);
            return;
        }
        setErrUsername(false);
        if (validator.isEmpty(password)) {
            setErrPassword(true);
            return;
        }
        setErrPassword(false);

        ShareService.getFlowTargetMqtt(
            companyName,
            setFlowId,
            setForwardTargetId,
            setUrl,
            setTopic,
            setClientId,
            setUserName,
            setPassword,
            setEnabled,
            setChkMessage,
            setChkThing,
            setChkProduct,
            setChkGateway
        ).then(() => {
            if (!forwardTargetId) {
                // create a flow, enabled=false
                ShareService.createFlow(companyName, "Share-MQTT").then((flowId) => {
                    // Insert MQTT integration, enabled=false
                    ShareService.insertForwardMqtt(url, topic, clientId, username, password, [chkMessage, chkThing, chkProduct, chkGateway], enabled).then(
                        (savedForwardTargetId) => {
                            // Insert Action
                            setForwardTargetId(savedForwardTargetId);
                            ShareService.createAction(flowId, savedForwardTargetId).then(() => goCompletePage());
                        }
                    );
                });
            } else {
                // Update configuration
                ShareService.updateForwardMqtt(forwardTargetId, url, topic, clientId, username, password, [chkMessage, chkThing, chkProduct, chkGateway], enabled).then(
                    () => goCompletePage()
                );
            }
        });
    };

    const handleMsgFormat = (e) => {
        const value = e.target.checked;
        if (e.target.name === "chkMessage") {
            setChkMessage(value);
        } else if (e.target.name === "chkThing") {
            setChkThing(value);
        } else if (e.target.name === "chkProduct") {
            setChkProduct(value);
        } else if (e.target.name === "chkGateway") {
            setChkGateway(value);
        }
    };

    const handleValue = (e) => {
        const value = e.target.value;

        if (e.target.name == "txtUrl") {
            setUrl(value);
        } else if (e.target.name == "txtTopic") {
            setTopic(value);
        } else if (e.target.name == "txtClientId") {
            setClientId(value);
        } else if (e.target.name == "txtUserName") {
            setUserName(value);
        } else if (e.target.name == "txtPassword") {
            setPassword(value);
        } else if (e.target.name == "cboEnabled") {
            setEnabled(e.target.checked);
        }
    };

    const closeDialog = (answer) => {
        if (answer) {            
            // Set false the forward target
            ShareService.setForwardTargetEnabled(forwardTargetId, false).then(() => setEnabled(false));
        } else {
            goFirstPage();
            
        }
    };

    return (
        <>
            <Paper elevation={6} spacing={2}>
                {on && <ConfirmDialog callback={closeDialog}></ConfirmDialog>}

                <Grid container padding={2}>
                    <Grid item xs={6} sm={6}>
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" style={{ backgroundColor: "#9CC96B" }} onClick={() => handleSave()}>
                                Save
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>

                <Accordion defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                        <Typography>MQTT broker connection details​</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={2} sm={2}>
                                <Typography>URL*</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtUrl"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={url}
                                    onChange={(e) => handleValue(e)}
                                    error={errUrl}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>Topic*</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtTopic"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={topic}
                                    onChange={(e) => handleValue(e)}
                                    error={errTopic}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>Client ID</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtClientId"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={clientId}
                                    onChange={(e) => handleValue(e)}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>Username*</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtUserName"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={username}
                                    onChange={(e) => handleValue(e)}
                                    error={errUsername}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>Password*</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtPassword"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={password}
                                    onChange={(e) => handleValue(e)}
                                    error={errPassword}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>Enable</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <Checkbox name="cboEnabled" checked={enabled} onChange={(e) => handleValue(e)} />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                        <Typography>Message format</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <MessageFormatPanel
                            chkMessage={chkMessage}
                            chkThing={chkThing}
                            chkProduct={chkProduct}
                            chkGateway={chkGateway}
                            callback={handleMsgFormat}
                        />
                    </AccordionDetails>
                </Accordion>
            </Paper>
        </>
    );
}
