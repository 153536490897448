import React, { useEffect, useState } from "react";
import { Box, Typography, Card, CardContent, CardActions, IconButton, Tooltip } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SensorDetailDialog from "../thingDetail/SensorDetailDialog";
import CardService from "./CardService";
import dayjs from "dayjs";
import CommonTop from "./CommonTop";
import CommonAlert from "./CommonAlert";

import UtilService from "../../../services/utilService"; // Ajout de l'import UtilService

import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import UpdateIcon from "@mui/icons-material/Update";
import ShareIcon from "@mui/icons-material/Share";
import SettingsIcon from "@mui/icons-material/Settings";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";

// Ajouter après les imports et avant le composant
const MONTHS = ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aoû', 'Sep', 'Oct'];
const MAX_VALUE = Math.floor(Math.random() * (1200 - 800 + 1)) + 800;
const VALUES = Array(10).fill(0).map((_, index) => {
    const decrease = (MAX_VALUE * (index * 0.1));
    return Math.max(MAX_VALUE - decrease, 100);
});

export default function RoomElectricityCard({ asset, setReloadParent }) {
    const { t, i18n } = useTranslation();
    let history = useHistory();
    const [open, setOpen] = useState(false);
    const [reload, setReload] = useState(0);

    const [entity, setEntity] = useState({});
    const [battery, setBattery] = useState(100);
    const [site, setSite] = useState(null);
    const [fixedName, setFixedName] = useState(null);

    const [showAlarms, setShowAlarms] = useState(false);
    const [alarms, setAlarms] = useState([]);
    const [alertCount, setAlertCount] = useState(0);

    // Ajouter l'état pour le temps actuel
    const [currentTime, setCurrentTime] = useState(
        new Date().toLocaleTimeString("fr-FR", {
            hour: "2-digit",
            minute: "2-digit",
        })
    );

    // Ajouter l'état moreFrequentPeriod au début du composant
    const [moreFrequentPeriod, setMoreFrequentPeriod] = useState(null);

    // Ajouter l'état pour la vue détaillée
    const [showDetail, setShowDetail] = useState(false);

    useEffect(() => {
        const attributeId = asset.assetAttribute[0].attribute.id;
        const thingId = asset.assetAttribute[0].thing.id;

        CardService.requestLastMeasures(thingId, [attributeId]).then((r) => {
            r.forEach((ele) => {
                setEntity({
                    thingId: ele.thingId,
                    fixedName: ele.fixedName,
                    value: ele.value,
                    unit: ele.attribute.unit,
                    date: ele.timestamp / 1000,
                    attributeId: ele?.attribute?.id,
                });
                setFixedName(ele.fixedName);
            });

            setSite(asset.site ? asset.site : null);
        });

        // Ajout de l'appel pour moreFrequentPeriod
        CardService.requestMoreFrequentPeriod(
            thingId, 
            attributeId, 
            setMoreFrequentPeriod
        );
    }, [asset, reload]);

    useEffect(() => {
        if (entity.thingId) {
            CardService.requestAlerts(
                entity.thingId,
                setAlarms,
                setAlertCount
            ).catch((error) => {
                console.error('Erreur lors du chargement des alertes:', error);
                setAlarms([]);
                setAlertCount(0);
            });
        }
    }, [entity.thingId, reload]);

    // Ajouter l'effet pour mettre à jour le temps
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(
                new Date().toLocaleTimeString("fr-FR", {
                    hour: "2-digit",
                    minute: "2-digit",
                })
            );
        }, 60000); // Mise à jour toutes les minutes

        return () => clearInterval(timer);
    }, []);

    const handleButton = (thingId, attributeId) => {
        history.push(`${process.env.PUBLIC_URL}/emera/v2/cardDetails/electricity/${entity.thingId}/${attributeId}`);
    };

    const handleFloor = () => {
        history.push(`${process.env.PUBLIC_URL}/emera/v2/floor/${site.id}`);
    };

    return (
        <Card sx={{
            backgroundColor: "white",
            borderRadius: "12px",
            width: "400px",
            minHeight: "400px",
            display: "flex",
            flexDirection: "column"
        }}>
            <CommonTop 
                thingId={entity.thingId}
                showAlarms={showAlarms}
                setShowAlarms={setShowAlarms}
                alarms={alarms}
                alertCount={alertCount}
                title={fixedName}  // Ajout du titre
            />

            <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                {!showAlarms ? (
                    <CardContent sx={{
                        flex: 1,
                        py: 0,
                        "&:last-child": {
                            paddingBottom: 0,
                        }
                    }}>
                        {!showAlarms ? (
                            showDetail ? (
                                // Vue détaillée
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    height: "100%",
                                }}>
                                    <Box 
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "100%",
                                            px: 2,
                                            position: "relative"
                                        }}
                                    >
                                        <Typography variant="h6" sx={{ color: "black" }}>
                                            {t("emera_v2.elec.consumption")}
                                        </Typography>
                                        <IconButton
                                            onClick={() => setShowDetail(false)}
                                            size="small"
                                            sx={{
                                                p: 0,
                                                position: "absolute",
                                                left: 16
                                            }}
                                        >
                                            <KeyboardArrowLeft />
                                        </IconButton>
                                    </Box>
                 
                                    {/* ... reste du contenu ... */}
                                    <Box sx={{ 
                                        display: "flex", 
                                        flexDirection: "column", 
                                        alignItems: "center" 
                                    }}>
                                        {/* Valeur principale */}
                                        <Box sx={{ display: "flex", alignItems: "baseline", gap: 1, my: 1 }}>
                                            <Typography variant="h3" sx={{ color: "#70AD47" }}>
                                                {"0.8"}
                                            </Typography>
                                            <Typography variant="h6" sx={{ color: "#70AD47" }}>
                                                {"kWh"}
                                            </Typography>
                                        </Box>

                                        {/* Timestamp et période */}
                                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                            <Typography variant="body2" sx={{ color: "black", fontWeight: 500 }}>
                                                {UtilService.formateHM(entity.date)}
                                            </Typography>
                                            <UpdateIcon sx={{ color: "black", fontSize: 20 }} />
                                            {moreFrequentPeriod && (
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        color: "gray",
                                                        fontWeight: 400,
                                                    }}
                                                >
                                                    {moreFrequentPeriod}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            ) : (
                                // Vue normale
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%",
                                    px: 1
                                }}>
                             
                                        
                                            <Box    
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center", // Ajout de justifyContent center
                                                    width: "100%",
                                                    px: 2,
                                                    position: "relative" // Ajout de position relative pour l'IconButton
                                                }}
                                            >
                                                <Typography variant="h6" sx={{ color: "black" }}>
                                                    {t("emera_v2.elec.instant_demanded_power")}
                                                </Typography>
                                                <IconButton
                                                    onClick={() => setShowDetail(true)}
                                                    size="small"
                                                    sx={{
                                                        p: 0,
                                                        position: "absolute", // Position absolute pour le bouton
                                                        right: 16 // 16px de la droite (même que px: 2)
                                                    }}
                                                >
                                                    <KeyboardArrowRight />
                                                </IconButton>
                                            </Box>

                                            <Box sx={{ display: "flex", alignItems: "baseline", gap: 1, my:1, justifyContent: "center" }}>
                                                <Typography variant="h3" sx={{ color: "#70AD47" }}>
                                                    {entity.value >= 1000 
                                                        ? UtilService.truncate2Digits(entity.value / 1000)
                                                        : UtilService.truncate2Digits(entity.value)
                                                    }
                                                </Typography>
                                                <Typography variant="h6" sx={{ color: "#70AD47" }}>
                                                    {entity.value >= 1000 ? 'kW' : 'W'}
                                                </Typography>
                                           
                                       
                                    </Box>
                                    
                                    
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                        <Typography variant="body2" sx={{ color: "black", fontWeight: 500 }}>
                                            {UtilService.formateHM(entity.date)}
                                        </Typography>

                                        <UpdateIcon sx={{ color: "black", fontSize: 20 }} />
                                        {moreFrequentPeriod && (
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    color: "gray",
                                                    fontWeight: 400,
                                                }}
                                            >
                                                {moreFrequentPeriod}
                                            </Typography>
                                        )}
                                    </Box>

                                    
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "flex-end",
                                                gap: 0.8,
                                                height: "100%",
                                            }}
                                        >
                                            {VALUES.map((value, index) => (
                                                <Box
                                                    key={index}
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        gap: 1,
                                                        position: 'relative'
                                                    }}
                                                >
                                                    {/* Valeur visible au-dessus pour première et dernière barre */}
                                                    {(index === 0 || index === VALUES.length - 1) && (
                                                        <Typography
                                                            sx={{
                                                                fontSize: "10px",
                                                                color: "black",
                                                                whiteSpace: "nowrap",
                                                                height: "14px"
                                                            }}
                                                        >
                                                            {value >= 1000 
                                                                ? `${UtilService.truncate2Digits(value / 1000)} kW`
                                                                : `${UtilService.truncate2Digits(value)} W`
                                                            }
                                                        </Typography>
                                                    )}
                                                    
                                                    {/* La barre */}
                                                    <Box
                                                        sx={{
                                                            width: 10,
                                                            height: `${(value / MAX_VALUE) * 70}px`,
                                                            backgroundColor: index === 0 ? "#FF0000" : "#E0E0E0", // Rouge pour la première barre
                                                            borderRadius: 50,
                                                            position: "relative",
                                                            cursor: "pointer",
                                                            transition: "background-color 0.3s",
                                                            "&:hover": {
                                                                backgroundColor: index === 0 ? "#FF0000" : "#70AD47",
                                                                "& + .hover-value": {
                                                                    display: "block"
                                                                }
                                                            }
                                                        }}
                                                    />

                                                    {/* Valeur au survol pour les barres du milieu */}
                                                    {index !== 0 && index !== VALUES.length - 1 && (
                                                        <Typography
                                                            className="hover-value"
                                                            sx={{
                                                                position: "absolute",
                                                                bottom: "-25px",
                                                                fontSize: "10px",
                                                                color: "black",
                                                                whiteSpace: "nowrap",
                                                                display: "none"
                                                            }}
                                                        >
                                                            {value >= 1000 
                                                                ? `${UtilService.truncate2Digits(value / 1000)} kW`
                                                                : `${UtilService.truncate2Digits(value)} W`
                                                            }
                                                        </Typography>
                                                    )}
                                                </Box>
                                            ))}
                                        </Box>
                                        <Typography
                                            sx={{
                                                fontSize: "14px", // Augmentation de la taille de police
                                                color: "text.secondary",
                                                marginTop: "24px",
                                            }}
                                        >
                                            {dayjs(entity.date * 1000).locale(i18n.language).format('MMMM')}
                                        </Typography>
                                    
                                </Box>
                            )
                        ) : (
                            <CommonAlert
                                open={showAlarms}
                                setOpen={setShowAlarms}
                                alarms={alarms}
                                title={t("emera_v2.common.alerts")}
                            />
                        )}
                    </CardContent>
                ) : (
                    <CommonAlert
                        open={showAlarms}
                        setOpen={setShowAlarms}
                        alarms={alarms}
                        title={t("emera_v2.common.alerts")}
                    />
                )}
            </Box>

            <CardActions sx={{ 
                display: "flex", 
                justifyContent: "space-between",
                p: 1
            }}>
                <Box sx={{ display: "flex", gap: 1 }}>
                    <IconButton
                        onClick={() => setOpen(true)}
                        aria-label="settings"
                        sx={{
                            transition: "transform 0.3s",
                            "&:hover": {
                                transform: "scale(1.1)",
                            },
                        }}
                    >
                        <SettingsIcon />
                    </IconButton>

                    <IconButton
                        onClick={() => handleButton(entity.thingId, entity.attributeId)}
                        aria-label="graph"
                        sx={{
                            transition: "transform 0.3s",
                            "&:hover": {
                                transform: "scale(1.1)",
                            },
                        }}
                    >
                        <AutoGraphIcon />
                    </IconButton>
                </Box>

                <Box sx={{ display: "flex", gap: 1 }}>
                    {entity.isMaster && (
                        <IconButton 
                            aria-label="share" 
                            style={{ filter: "grayscale(50%)" }}
                        >
                            <ShareIcon />
                        </IconButton>
                    )}
                </Box>
            </CardActions>

            <SensorDetailDialog
                open={open}
                setOpen={setOpen}
                thingId={entity.thingId}
                entity={entity}
                setReload={() => {
                    setReload(dayjs().unix());
                    setReloadParent(dayjs().unix());
                }}
            />
        </Card>
    );
}
