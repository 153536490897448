import { useState, useEffect } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import _ from "underscore";
import DetailService from "../DetailService";
import { frFR } from "@mui/x-data-grid/locales";
import { useTranslation } from "react-i18next";
import MeasureColumn from "./MeasureColumn";

export default function TabMeasure({ selectedThingId, selectedAttributeId }) {
    const [previousSortQuery, setPreviousSortQuery] = useState("");

    const [paging, setPaging] = useState({ page: 0, pageSize: 10 });
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const { t, i18n } = useTranslation();
    const columns = MeasureColumn.getColumns(t);

    const handleSortModelChange = (param) => {
        let sortModel = param;
        let qParams = [];

        _.each(sortModel, function (sm) {
            let result = "";

            switch (sm.field) {
                case "timestamp":
                    result += "measure.timestamp";
                    break;
                case "measurement":
                    result += "attribute.name";
                    break;
                default:
                    break;
            }

            switch (sm.sort) {
                case "asc":
                    result += "&dir=ASC";
                    break;
                case "desc":
                    result += "&dir=DESC";
                    break;
                default:
                    break;
            }

            qParams.push(result);
        });

        let sortQuery = "";
        if (qParams.length > 0) {
            sortQuery = "&sort=" + qParams.join(",");
        }

        if (previousSortQuery != sortQuery) {
            setPreviousSortQuery(sortQuery);
            DetailService.requestMeasurements(paging.page, paging.pageSize, setRows, selectedThingId, sortQuery, selectedAttributeId, setRowCount);
        }
    };

    useEffect(() => {
        DetailService.requestMeasurements(paging.page, paging.pageSize, setRows, selectedThingId, previousSortQuery, selectedAttributeId, setRowCount);
    }, [paging, selectedThingId, previousSortQuery, selectedAttributeId]);

    return (
        <DataGridPro
            rows={rows}
            rowCount={rowCount}
            columns={columns}
            paginationModel={paging}
            pagination={true}
            paginationMode="server"
            sortingMode="server"
            pageSizeOptions={[10, 50, 100]}
            onPaginationModelChange={setPaging}
            checkboxSelection={false}
            onSortModelChange={handleSortModelChange}
            density="compact"
            filterMode="server"
            disableMultipleColumnsFiltering={true}
            disableColumnReorder={true} 
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        id: false,
                    },
                },
            }}
            localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
            autoHeight={false}
        />
    );
}
