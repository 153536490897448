import _ from "underscore";
import style from "./style.module.css";

const FixedColumns = [
    {
        field: "company",
        headerName: "Company",
        width: 150,
        hide: true,
    },
    {
        field: "thing",
        headerName: "Name",
        width: 200,
    },
    {
        field: "timestamp",
        headerName: "Last Message",
        width: 200,
        hide: true,
    },
    {
        field: "model",
        headerName: "Model",
        width: 200,
        hide: true,
    },
    {
        field: "manufacturer",
        headerName: "Vendor",
        width: 150,
    },
    {
        field: "product",
        headerName: "Product",
        width: 150,
    },
    {
        field: "connectivityType",
        headerName: "Technology",
        width: 200,
        hide: true,
    },
    {
        field: "status",
        headerName: "Status",
        width: 150,
        renderCell: (params) => {
            let classStyle = style.error;

            switch (params.value) {
                case "ACTIVE":
                case "CONNECTED":
                    classStyle = style.active;
                    break;
                case "INACTIVE":
                case "NOT_CONNECTED":
                case "JOIN_RECEIVED":
                    classStyle = style.inactive;
                    break;
                default:
                    classStyle = style.inactive;
                    break;
            }

            return <span className={`${style.badge} ${classStyle}`}>{params.value}</span>;
        },
    },
    {
        field: "tags",
        headerName: "Tags",
        width: 110,
        sortable: false,
        hide: true,
    },
    {
        field: "site",
        headerName: "Site",
        width: 110,
        sortable: false,
        hide: true,
    },
    {
        field: "fridge",
        headerName: "Rack",
        width: 150,
        sortable: false,
    },
];

const Coordinator = {
    getFixeColumns: () => {
        return FixedColumns;
    },
};

export default Coordinator;
