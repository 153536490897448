const MeasuresColumns = {
    getColumns: function (t) {
        const columns = [
            {
                field: "id",
                headerName: "ID",
                flex: 1,
                editable: false,
                sortable: true,
                filterable: false,
            },
            {
                field: "timestamp",
                headerName: t("col.timestamp"),
                flex: 1,
                editable: false,
                sortable: true,
                filterable: true,
            },
            {
                field: "attribute.name",
                headerName: t("col.measurement"),
                flex: 1,
                editable: false,
                sortable: true,
                filterable: true,
            },
            {
                field: "value",
                headerName: t("col.value"),
                flex: 1,
                editable: false,
                sortable: true,
                filterable: true,
            },
        ];
        return columns;
    },
};

export default MeasuresColumns;
