import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import _ from "underscore";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import validator from "validator";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import CircularProgress from "@mui/material/CircularProgress";
import AttributeComboBox from "./AttributeComboBox";
import Tags from "../tag/Tags";
import Chip from "@mui/material/Chip";
import DetailService from "./DetailService";
import CloseIcon from "@mui/icons-material/Close";
import GatewayStatusPagination from "./network/GatewayStatusPagination";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MapIcon from "@mui/icons-material/Map";
import { IoWaterOutline } from "react-icons/io5";
import TabMonthlyConsumption from "./TabMonthlyConsumption";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarRateIcon from "@mui/icons-material/StarRate";
import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";
import Item from "../../../../components/ui/Item";
import { useTranslation } from "react-i18next";
import NetworkInfo from "./network/NetworkInfo";
import BatteryIcon from "Components/ui/BatteryIcon";
import DownlinkMain from "./downlink/DownlinkMain";
import SendIcon from "@mui/icons-material/Send";
import Typography from "@mui/material/Typography";
import moment from "moment";
import MsgFrequencyChart from "./network/MsgFrequencyChart";
import { LicenseInfo } from "@mui/x-license-pro";
import TabMeasure from "./measures/TabMeasure";
import TabLocation from "./site/TabLocation";
LicenseInfo.setLicenseKey("53264e234090f26bf650c109bcf9fc2fTz0xMDUxMDMsRT0xNzY5Mjg1NDAxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

const ATIM_THX_PRODUCT_ID = "e60ffd73-1aa7-4029-bc21-cbe678dd2f7d";

export default function SensorDetailDialog({ open, setOpen, user, selectedThingId }) {
    const { t, i18n } = useTranslation();

    const [thing, setThing] = useState();
    const [thingName, setThingName] = useState("");
    const [thingTags, setThingTags] = useState([]);
    const [deveui, setDeveui] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [selectedAttributeId, setSelectedAttributeId] = useState("all");
    const [selectedCompany, setSelectedCompany] = useState();
    const [lastGatewayStatus, setLastGatewayStatus] = useState([]);
    const [isAtimTHX, setIsAtimTHX] = useState(false);

    const [tabIndex, setTabIndex] = useState(0);

    const [position, setPosition] = useState([45.49573, -73.60635]);
    const [gps, setGps] = useState();
    const [monthlyConsumption, setMonthlyConsumption] = useState();

    const [star, setStar] = useState(false);
    const [allAttributes, setAllAttributes] = useState([]);
    const [sinceInactive, setSinceInactive] = useState("");

    const handleTabs = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleClose = () => {
        setOpen(isSaved);
        setGps(undefined);
        setStar(false);
        setSinceInactive("");
    };

    useEffect(() => {
        if (open) {
            DetailService.requestThing(selectedThingId, setThingInfo).then((t) => {
                setIsAtimTHX(t.product.id === ATIM_THX_PRODUCT_ID);

                if (t.status === "INACTIVE") {
                    const now = moment();
                    const creationDate = moment.unix(t.creationDate);

                    if (t.lastActivityDate) {
                        const lastAciveDate = moment.unix(t.lastActivityDate);
                        if (now.diff(lastAciveDate, "hours") < 24) {
                            setSinceInactive("Since " + now.diff(lastAciveDate, "hours") + " hours");
                        } else {
                            setSinceInactive("Since " + now.diff(lastAciveDate, "days") + " days");
                        }
                    } else {
                        setSinceInactive("Since " + now.diff(creationDate, "days") + " days");
                    }
                }
            });

            DetailService.requestLastGatewayStatus(selectedThingId, setLastGatewayStatus);

            // for map
            DetailService.requestLastGps(selectedThingId, setPosition, setGps);

            DetailService.requestMonthlyConsumption(selectedThingId, setMonthlyConsumption, setTabIndex);

            if (allAttributes) {
                DetailService.requestFavoriteAttribute(user.cognitoUserName, selectedThingId, undefined, setStar).then((r) => {
                    const all = allAttributes.length - 1;
                    const registered = r.length;

                    if (all == registered) {
                        setStar(true);
                    }
                });
            }
        }
    }, [open, selectedAttributeId, user, star, allAttributes]);

    const setThingInfo = (thing) => {
        setThing(thing);
        setThingName(thing.displayName);

        if (thing.customFields != undefined) {
            thing.customFields.forEach((cf) => {
                if (cf.name == "devEui") {
                    setDeveui(cf.value);
                }
            });
        }

        setThingTags(
            thing.tags.map((ele) => {
                return {
                    id: ele.id,
                    label: ele.tag,
                };
            })
        );

        setSelectedCompany(thing.company);
    };

    const handleChange = (event) => {
        setThingName(event.target.value);
        setIsSaved(true);
    };

    const handleUpdate = (event) => {
        if (validator.isEmpty(thingName)) {
            return;
        }
        setIsSaving(true);
        DetailService.updateName(selectedThingId, thingName, thing, thingNameCallback);
    };

    const thingNameCallback = (name) => {
        setIsSaving(false);
        setThingName(name);
    };

    const handleSelectedAttributeId = (attribute_id) => {
        // console.log("handleSelectedAttributeId...///...")
        setSelectedAttributeId(attribute_id);

        // console.log(props.user.cognitoUserName)
        // console.log(selectedThingId)
        // console.log(attribute_id)

        DetailService.requestFavoriteAttribute(user.cognitoUserName, selectedThingId, attribute_id, setStar);
    };

    const handleFavorite = (e) => {
        // console.log(e)
        // console.log("selectedAttributeId");
        // console.log(props.user.cognitoUserName)
        // console.log(selectedAttributeId)
        DetailService.updateFavoriteAttribute(star, user.cognitoUserName, selectedAttributeId, allAttributes, selectedThingId).then(() => setStar(!star));
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md">
                <DialogTitle id="form-dialog-title">
                    {t("de.sensor_detail")}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{ position: "absolute", right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="d-flex flex-fill" style={{ overflow: "hidden" }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={4} style={{ marginTop: "10px" }}>
                                <Stack spacing={1} alignItems="center" justifyContent="center">
                                    <Item>
                                        <TextField
                                            id="standard-basic"
                                            variant="standard"
                                            fullWidth
                                            editable={true}
                                            value={thingName}
                                            onChange={handleChange}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton edge="end" color="primary" onClick={handleUpdate} size="large">
                                                            {!isSaving && <SaveIcon />}
                                                            {isSaving && (
                                                                <Box sx={{ display: "flex" }}>
                                                                    <CircularProgress size={20} />
                                                                </Box>
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Item>
                                    <Item style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            {getStatus(thing)}
                                            <Typography variant="caption" sx={{ pl: 1 }}>
                                                {sinceInactive}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <BatteryIcon thing={thing} />
                                        </Box>
                                    </Item>
                                    <Item>
                                        {thing != undefined && thing.product && (
                                            <img
                                                src={`/static/catalog/products/${thing.product.id}/image.png`}
                                                alt="Product"
                                                style={{ display: "block", margin: "auto", maxHeight: "150px", width: "98%", height: "auto" }}
                                            ></img>
                                        )}
                                    </Item>
                                </Stack>
                                <Stack>
                                    {/* {deveui != undefined && deveui != "" && (
                                        <Item style={{ width: "100%", display: "flex", alignContent: "flex-start" }}>DevEUI: {deveui}</Item>
                                    )} */}
                                    <Tags selectedThingId={selectedThingId} selectedCompany={selectedCompany} thingTags={thingTags}></Tags>
                                </Stack>
                            </Grid>
                            <Grid item xs={8} style={{ marginTop: "10px" }}>
                                <Stack spacing={0}>
                                    <Grid container spacing={0} sx={{ p: 0, m: 0 }}>
                                        <Grid item xs={12}>
                                            <Box sx={{ width: "100%" }}>
                                                <Tabs value={tabIndex} onChange={handleTabs} aria-label="list or map" sx={{ m: 0 }}>
                                                    <Tab
                                                        icon={<IoWaterOutline size={21} color={"#666666"} />}
                                                        sx={{ flexGrow: 1 }}
                                                        hidden={monthlyConsumption ? false : true}
                                                    />
                                                    <Tab icon={<ListAltIcon />} sx={{ flexGrow: 1 }} />
                                                    <Tab icon={<MapIcon />} sx={{ flexGrow: 1 }} />
                                                    <Tab icon={<NetworkCheckIcon />} sx={{ flexGrow: 1 }} />
                                                    <Tab icon={<SendIcon />} sx={{ flexGrow: 1 }} hidden={isAtimTHX ? false : true} />
                                                </Tabs>
                                            </Box>
                                            {tabIndex === 0 && <Box sx={{ marginTop: "0px", width: "200px", height: "0px" }}>&nbsp;</Box>}
                                            {tabIndex === 1 && (
                                                <Box sx={{ marginTop: "20px", width: "250px", display: "flex" }}>
                                                    <AttributeComboBox
                                                        selectedThingId={selectedThingId}
                                                        open={open}
                                                        callbackSelectedAttributeId={handleSelectedAttributeId}
                                                        callbackAllAttributes={setAllAttributes}
                                                    ></AttributeComboBox>
                                                    <IconButton aria-label="favorite" onClick={() => handleFavorite()} size="large">
                                                        {star && <StarRateIcon />}
                                                        {!star && <StarOutlineIcon />}
                                                    </IconButton>
                                                </Box>
                                            )}
                                            {tabIndex === 3 && <Box sx={{ marginTop: "0px", width: "200px", height: "0px" }}>&nbsp;</Box>}
                                        </Grid>

                                        {tabIndex === 0 && monthlyConsumption && (
                                            <Grid item xs={12} style={{ padding: "0px", width: 400, height: 570 }}>
                                                <TabMonthlyConsumption monthlyConsumption={monthlyConsumption} />
                                            </Grid>
                                        )}
                                        {tabIndex === 1 && (
                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    padding: "0px",
                                                    width: 400,
                                                    maxHeight: 500,
                                                    height: 452,
                                                }}
                                            >
                                                <TabMeasure selectedThingId={selectedThingId} selectedAttributeId={selectedAttributeId} />
                                            </Grid>
                                        )}
                                        {tabIndex === 2 && (
                                            <Grid item xs={12} style={{ marginTop: "20px", padding: "0px", width: 400, height: 556 }}>
                                                {/* <TabPlace selectedThingId={selectedThingId} /> */}
                                                <TabLocation selectedThingId={selectedThingId} />
                                            </Grid>
                                        )}
                                        {tabIndex === 3 && (
                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    width: 400,
                                                    height: 520,
                                                    padding: "0px",
                                                    overflow: "auto",
                                                }}
                                            >
                                                <NetworkInfo selectedThingId={selectedThingId} />
                                                {/* <PacketLoss selectedThingId={selectedThingId} /> */}
                                                <GatewayStatusPagination lastGatewayStatus={lastGatewayStatus} selectedThingId={selectedThingId} />
                                                <MsgFrequencyChart selectedThingId={selectedThingId} />
                                            </Grid>
                                        )}
                                        {tabIndex === 4 && (
                                            <Grid item xs={12} style={{ width: 400, height: 520, padding: "0px" }}>
                                                <DownlinkMain thing={thing} />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
}

function getStatus(thing) {
    if (thing != undefined) {
        // console.log(thing.status);
        switch (thing.status) {
            case "ACTIVE":
            case "CONNECTED":
                 return <Chip label={thing.status} size={"small"} style={{ fontSize: "11px", fontWeight: 400, backgroundColor: "#9cc96b", color: "#fff" }} />;
            case "INACTIVE":
            case "NOT_CONNECTED":
            case "JOIN_RECEIVED":
                 return <Chip label={thing.status} size={"small"} style={{ fontSize: "11px", fontWeight: 400, backgroundColor: "#E7E955", color: "#404044" }} />;
            case "ERROR":
                 return <Chip label={thing.status} size={"small"} style={{ fontSize: "11px", fontWeight: 400, backgroundColor: "#e44959", color: "#fff" }} />;
            default:
                return <Chip label={thing.status} size={"small"} style={{ fontSize: "11px", fontWeight: 400, backgroundColor: "#E7E955", color: "#404044" }} />;
        }
    }
}
