import React, { useState, useEffect } from "react";
import { Select, MenuItem, FormControl } from "@mui/material";
import CardService from "./CardService";
import dayjs from "dayjs";

export default function SiteSelector({ selectedSiteId, company, setReload, thingId }) {
    const [sites, setSites] = useState([]);

    useEffect(() => {
        // console.log(selectedSiteId, company);

        if (company) {
            CardService.requestSites(company, setSites);
        }
    }, [selectedSiteId, company]);

    const handleSiteChange = (siteId) => {
        // console.log(siteId, thingId);
        CardService.updateSite(thingId, siteId).then(() => setReload(dayjs())); 
    };

    return (
        <FormControl fullWidth size="small">
            <Select
                fullWidth
                value={selectedSiteId || ""}
                displayEmpty
                renderValue={(selected) => {
                    if (!selected) return "Select a site";
                    const site = sites.find((site) => site.id === selected);
                    return site ? site.name : "";
                }}
                onChange={(e) => handleSiteChange(e.target.value)}
            >
                <MenuItem value="">None</MenuItem>
                {sites.map((site) => (
                    <MenuItem key={site.id} value={site.id}>
                        {site.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
