import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import SearchBox from "../../../components/ui/SearchBox";
import Item from "../../../components/ui/Item";
import Stack from "@mui/material/Stack";
import { DataGridPro } from "@mui/x-data-grid-pro";
import AssignService from "./AssignService";
import { LicenseInfo } from "@mui/x-license-pro";
import { columns } from "./columns";
import UserService from "Services/userService";
LicenseInfo.setLicenseKey("53264e234090f26bf650c109bcf9fc2fTz0xMDUxMDMsRT0xNzY5Mjg1NDAxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

export default function ThingList({ callback, cloudUserId, reload }) {
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 20 });
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [keyword, setKeyword] = useState();
    const [sort, setSort] = useState([{ field: "thing.name", sort: "asc" }]);

    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [allAssignedThing, setAllAssignedThing] = useState([]);

    useEffect(() => {
        // console.log("uef in ThingList");
        UserService.getAuthenticatedUser().then((u) => {
            // console.log(u.authorities);
            setRowSelectionModel([]);

            if (u.authorities == "MANAGER") {
                AssignService.requestCloudUserThingByUsername(keyword, u.username, paginationModel.page, paginationModel.pageSize, setRows, setRowCount);
            } else {
                AssignService.requestThings(keyword, paginationModel.pageSize, paginationModel.page, setRows, setRowCount, sort);
            }
        });

        if (cloudUserId) {
            AssignService.requestCloudUserThingAll(cloudUserId, setAllAssignedThing).then((r) => setRowSelectionModel(r));
        }
    }, [paginationModel.page, paginationModel.pageSize, keyword, cloudUserId, reload, sort]);

    const handleSelectionModel = (selectedIds) => {
        // console.log(selectedIds);

        const rowIds = rows.map((r) => r.id);
        const notDisplayedIds = allAssignedThing.filter((item) => !rowIds.includes(item));
        const updatedSelection = selectedIds.concat(notDisplayedIds);

        setRowSelectionModel(selectedIds);
        callback(updatedSelection);
    };

    const handleSearch = (keyword) => {
        setKeyword(keyword);
    };

    const handleSortModelChange = (sortModel) => {
        setSort(sortModel);
    };

    return (
        <Box sx={{ flexGrow: 1, height: "100%" }}>
            <Stack spacing={2} sx={{ height: "100%" }}>
                <Item sx={{ height: "40px", width: "200px" }} elevation={0}>
                    <SearchBox callback={handleSearch} placeholder="Serial, site, tag..." />
                </Item>
                <Item elevation={0} sx={{ height: "100%" }}>
                    <DataGridPro
                        rows={rows}
                        rowCount={rowCount}
                        columns={columns}
                        disableColumnFilter
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        pagination={true}
                        paginationMode="server"
                        sortingMode="server"
                        pageSizeOptions={[20, 50, 100, 200, 500]}
                        rowSelectionModel={rowSelectionModel}
                        onRowSelectionModelChange={(newSelection) => handleSelectionModel(newSelection)}
                        density="compact"
                        checkboxSelection
                        // disableSelectionOnClick
                        style={{ height: "100%" }}
                        // headerHeight={0}
                        hideFooterSelectedRowCount
                        onSortModelChange={handleSortModelChange}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                        }}
                    />
                </Item>
            </Stack>
        </Box>
    );
}
