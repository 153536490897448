import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "underscore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ConfirmDialog from "./ConfirmDialog";
import ShareService from "./ShareService";
import CustomStyle from "Components/CustomStyle";
import MessageFormatPanel from "./MessageFormatPanel";
import Checkbox from "@mui/material/Checkbox";

export default function SetupIotCore({ setActiveStep, goCompletePage, goFirstPage, on, userCompany }) {
    let history = useHistory();
    let errors = {
        url: false,
        topic: false,
        accessKey: false,
        secretKey: false,
        region: false,
        message: "",
    };

    const [companyName, setCompanyName] = useState(userCompany);

    const [flowId, setFlowId] = useState();
    const [forwardTargetId, setForwardTargetId] = useState();

    const [url, setUrl] = useState(); // endpoint
    const [topic, setTopic] = useState();
    const [accessKey, setAccessKey] = useState();
    const [secretKey, setSecretKey] = useState();
    const [region, setRegion] = useState();
    const [enabled, setEnabled] = useState(false);

    const [chkMessage, setChkMessage] = useState(false);
    const [chkThing, setChkThing] = useState(false);
    const [chkProduct, setChkProduct] = useState(false);
    const [chkGateway, setChkGateway] = useState(false);

    useEffect(() => {
        setActiveStep(1);

        ShareService.getFlowTargetAwsIot(
            companyName,
            setFlowId,
            setForwardTargetId,
            setUrl,
            setTopic,
            setAccessKey,
            setSecretKey,
            setRegion,
            setEnabled,
            setChkMessage,
            setChkThing,
            setChkProduct,
            setChkGateway
        );
    }, [userCompany, companyName]);

    const handleSave = () => {
        ShareService.getFlowTargetAwsIot(
            companyName,
            setFlowId,
            setForwardTargetId,
            setUrl,
            setTopic,
            setAccessKey,
            setSecretKey,
            setRegion,
            setEnabled,
            setChkMessage,
            setChkThing,
            setChkProduct,
            setChkGateway
        ).then(() => {
            if (!forwardTargetId) {
                // create a flow, enabled=false
                ShareService.createFlow(companyName, "Share-AWS-IoT").then((flowId) => {
                    // Insert AWSIoT integration, enabled=false
                    ShareService.insertForwardAwsIot(url, topic, accessKey, secretKey, region, [chkMessage, chkThing, chkProduct, chkGateway], enabled).then(
                        (savedForwardTargetId) => {
                            // Insert Action
                            setForwardTargetId(savedForwardTargetId);
                            ShareService.createAction(flowId, savedForwardTargetId).then(() => goCompletePage());
                        }
                    );
                });
            } else {
                // Update configuration
                ShareService.updateForwardAwsIot(
                    forwardTargetId,
                    url,
                    topic,
                    accessKey,
                    secretKey,
                    region,
                    [chkMessage, chkThing, chkProduct, chkGateway],
                    enabled
                ).then(() => goCompletePage());
            }
        });
    };

    const handleValue = (e) => {
        const value = e.target.value;

        if (e.target.name == "txtUrl") {
            setUrl(value);
        } else if (e.target.name == "txtTopic") {
            setTopic(value);
        } else if (e.target.name == "txtAccessKey") {
            setAccessKey(value);
        } else if (e.target.name == "txtSecretKey") {
            setSecretKey(value);
        } else if (e.target.name == "txtRegion") {
            setRegion(value);
        }
    };

    const closeDialog = (answer) => {
        if (answer) {
            // Set false the forward target
            ShareService.setForwardTargetEnabled(forwardTargetId, false).then(() => setEnabled(false));
        } else {
            goFirstPage();
        }
    };

    return (
        <>
            <Paper elevation={6} spacing={2}>
                {on && <ConfirmDialog callback={closeDialog}></ConfirmDialog>}

                <Grid container padding={2}>
                    <Grid item xs={6} sm={6}>
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" style={{ backgroundColor: "#9CC96B" }} onClick={() => handleSave()}>
                                Save
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>

                <Accordion defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                        <Typography>Connection</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={2} sm={2}>
                                <Typography>Endpoint</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtUrl"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={url}
                                    onChange={(e) => handleValue(e)}
                                    error={errors.url ? true : false}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>Topic</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtTopic"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={topic}
                                    onChange={(e) => handleValue(e)}
                                    error={errors.topic ? true : false}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>Access Key</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtAccessKey"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={accessKey}
                                    onChange={(e) => handleValue(e)}
                                    error={errors.accessKey ? true : false}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>Secret Key</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtSecretKey"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={secretKey}
                                    onChange={(e) => handleValue(e)}
                                    error={errors.secretKey ? true : false}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>Region</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtRegion"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={region}
                                    onChange={(e) => handleValue(e)}
                                    error={errors.region ? true : false}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>Enable</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <Checkbox name="cboEnabled" checked={enabled} onChange={(e) => handleValue(e)} />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                        <Typography>Message format</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <MessageFormatPanel
                            chkMessage={chkMessage}
                            chkThing={chkThing}
                            chkProduct={chkProduct}
                            chkGateway={chkGateway}
                            callback={(e) => {
                                const value = e.target.checked;
                                if (e.target.name === "chkMessage") {
                                    setChkMessage(value);
                                } else if (e.target.name === "chkThing") {
                                    setChkThing(value);
                                } else if (e.target.name === "chkProduct") {
                                    setChkProduct(value);
                                } else if (e.target.name === "chkGateway") {
                                    setChkGateway(value);
                                }
                            }}
                        />
                    </AccordionDetails>
                </Accordion>
            </Paper>
        </>
    );
}
