import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import ThresholdDialogV2 from "../../../components/chart/ThresholdDialogV2";
import { ChartPirOptions } from "./ChartPirOptions";
import SensorDetailService from "../../../components/chart/SensorDetailService";
import Highcharts from "highcharts/highstock";
import highchartsMore from "highcharts/highcharts-more.js";
import HighchartsReact from "highcharts-react-official";
import HighchartsXRange from "highcharts/modules/xrange";
import SiteDialogue from "Components/site/SiteDialogue";
import dayjs from "dayjs";
import SiteService from "Components/site/SiteService";
import ToggleButton from "@mui/material/ToggleButton";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import Button from "@mui/material/Button";
import ChartPirService from "./ChartPirService";
highchartsMore(Highcharts);
HighchartsXRange(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

export default function ChartPir({ thingId, attributeId, parentReload, showThreshold = true }) {
    const { t, i18n } = useTranslation();

    const componentRef = useRef();

    const [isOpenThresholdModal, setIsOpenThresholdModal] = useState(false);

    const [name, setName] = useState("magnet_status");
    const [thing, setThing] = useState();
    const [attribute, setAttribute] = useState();

    const [series, setSeries] = useState([]);
    const [openSite, setOpenSite] = useState(false);
    const [location, setLocation] = useState("");
    const [reload, setReload] = useState();

    const [selected, setSelected] = useState("spline");

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (componentRef.current) {
                    componentRef.current.chart.showLoading();
                }
                window.scrollTo(0, 0);

                const thing = await SensorDetailService.requestThing(thingId);
                setThing(thing);
                if (thing.site) {
                    setLocation(thing.site.name);
                } else {
                    setLocation("");
                }

                // console.log("thingId", thingId, "attributeId", attributeId);

                // First attribute
                const attribute = await SensorDetailService.requestAttribute(thing.product.model.id, attributeId);
                setAttribute(attribute);
                // console.log("attribute", attribute);

                const chartData = await ChartPirService.requestMeasures(thingId, attributeId);
                const seriesData = { attribute, chartData };

                // console.log("seriesData", seriesData);
                setSeries(seriesData);

                if (componentRef.current) {
                    componentRef.current.chart.hideLoading();
                }
            } catch (error) {
                console.error(error);
                if (componentRef.current) {
                    componentRef.current.chart.hideLoading();
                }
            }
        };

        fetchData();
    }, [attributeId, thingId, parentReload, showThreshold, reload]);

    const handleThreshold = () => {
        setIsOpenThresholdModal(true);
    };

    const handleSite = (siteId) => {
        SiteService.assign(thingId, siteId).then(() => {
            setReload(dayjs());
        });
    };

    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
        var path = [],
            h = h / 3 - 2;
        path = path.concat(this.circle(w - h, y, h, h), this.circle(w - h, y + h + 4, h, h), this.circle(w - h, y + 2 * (h + 4), h, h));
        return path;
    };

    return (
        <Box sx={{ flexGrow: 1, marginTop: 2, width: "100%" }}>
            <Grid container spacing={2}>
                {thing && (
                    <Grid item xs={12}>
                        <span style={{ fontFamily: "Days One", fontSize: "24px", fontWeight: "normal", color: "#9B9B9B" }}>
                            {thing.fixedName} {attribute ? ` - ${attribute.name}` : ""}
                        </span>
                    </Grid>
                )}
                <Grid item xs={12}>
                    {/* <ToggleButton value="check" selected={selected === "spline" ? true : false} onChange={() => handleChartType("spline")} size="small">
                        <ShowChartIcon />
                    </ToggleButton>
                    <ToggleButton value="check" selected={selected === "column" ? true : false} onChange={() => handleChartType("column")} size="small">
                        <EqualizerIcon />
                    </ToggleButton> */}

                    {/* {attributeId !== ATTRIBUTE_BATTERY && (
                        <IconButton value="check" size="small" onClick={() => setOpenSecond(true)}>
                            <AddchartIcon />
                        </IconButton>
                    )} */}

                    {showThreshold && (
                        <Button variant="contained" color="primary" style={{ textTransform: "none", margin: "5px" }} onClick={() => handleThreshold()}>
                            {t("btn.threshold")}
                        </Button>
                    )}
                </Grid>

                {/* <Grid item xs={12}>
                    <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row", width: "300px" }}>
                        <TextField
                            required
                            name="txtLocation"
                            fullWidth
                            margin="dense"
                            size="small"
                            value={location}
                            sx={CustomStyle.RemoveLabel}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <IconButton aria-label="delete" size="large" variant="contained" onClick={() => setOpenSite(true)}>
                            <MoreHorizIcon fontSize="inherit" />
                        </IconButton>
                    </Box>
                </Grid> */}
                <Grid item xs={12}>
                    <HighchartsReact ref={componentRef} highcharts={Highcharts} constructorType={"stockChart"} options={ChartPirOptions(series, t, i18n)} />
                </Grid>
            </Grid>

            <ThresholdDialogV2
                onClose={() => setIsOpenThresholdModal(false)}
                open={isOpenThresholdModal}
                thingId={thingId}
                attributeId={attributeId}
                title="Alerts"
                attributeName={name}
            />

            <SiteDialogue
                open={openSite}
                setOpen={setOpenSite}
                selectedThingId={thingId}
                setReloadParent={(siteId) => {
                    handleSite(siteId);
                }}
            />
        </Box>
    );
}
