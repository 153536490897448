import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Item from "../../../components/ui/Item";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import _ from "underscore";
import CompanyService from "./CompanyService";
import Button from "@mui/material/Button";

export default function CreateCompanyDialog({ open, setOpen, callback, company, authorities }) {
    const { t, i18n } = useTranslation();

    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleClose = () => {
        setName("");

        setOpen(false);
        setLoading(false);
        setError(null);
    };

    const handleSubmit = () => {
        // console.log("handleSubmit...")

        if (_.isEmpty(name)) {
            setError("Please enter a name");
            return;
        }

        setLoading(true);
        setError(null);

        // company = user's company
        CompanyService.createGroup(company, name, authorities)
            .then((r) => {
                setLoading(false);

                if (r.code && r.code == "failure" && r.message.indexOf("already exists") != -1) {
                    setError("The name is already used");
                    return;
                }
                setError(null);

                // console.log(r)
                setOpen(false);
                callback();

                if (r && r.code && r.code == "success") {
                    setName("");
                    setOpen(false);
                    setError("");
                } else if (r && r.code && r.code == "failure") {
                    setError(r.message);
                }
            })
            .catch((e) => {
                // console.log(e);
                setOpen(false);
                setLoading(false);
            });
    };

    useEffect(() => {
        // console.log("useEffect...in CreateCompanyDialog");
        // console.log(company);
    }, [company]);

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="sm" fullWidth>
                <Stack direction={"row"}>
                    <Item elevation={0}>
                        <DialogTitle id="alert-dialog-title">{t("de.add_company")}</DialogTitle>
                    </Item>
                    <Item sx={{ display: "flex", flexDirection: "row-reverse", flexGrow: 1 }} elevation={0}>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 0,
                                color: (theme) => theme.palette.grey[500],
                            }}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Item>
                </Stack>

                <Box sx={{ pt: 2, pl: 10, pr: 10 }}>
                    <Stack spacing={2}>
                        <TextField
                            variant="standard"
                            label={t("de.name")}
                            type="name"
                            fullWidth
                            required
                            disabled={loading}
                            value={name}
                            onChange={(e) => setName(e.target.value.toUpperCase())}
                            helperText={t("de.name_helper")}
                        />

                        <Alert severity="error" sx={{ opacity: error ? 1 : 0 }}>
                            {error}
                        </Alert>
                    </Stack>
                </Box>

                <DialogActions>
                    <Button variant="contained" style={{ backgroundColor: "#5694B5", color: "#FFFFFF", textTransform: "none" }} onClick={handleSubmit}>
                        {t("btn.add")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
