import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import AssignService from "./AssignService";
import { DataGridPro } from "@mui/x-data-grid-pro";
import CompanyCheckBoxes from "./CompanyCheckBoxes";
import UserService from "Services/userService";
import RoleCheckBoxes from "./RoleCheckBoxes";
import CustomSwitch from "../../../components/ui/CustomSwitch";
import moment from "moment";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("53264e234090f26bf650c109bcf9fc2fTz0xMDUxMDMsRT0xNzY5Mjg1NDAxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

export default function UserList({ reload, callback, hideCompanyColumn }) {
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 20 });
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [user, setUser] = useState();
    const [sort, setSort] = useState([{ field: "cloud_user.email", sort: "asc" }]);
    const [reloadThis, setReloadThis] = useState();

    const handleEnabled = (e) => {
        AssignService.changeEnabled(e.target.name, e.target.checked).then(() => {
            setReloadThis(moment());
        });
    };

    const columns = getColumns(hideCompanyColumn, handleEnabled, user);

    useEffect(() => {
        // console.log("useEffect in UserList")
        UserService.getAuthenticatedUser().then((user) => {
            // console.log("useEffect in UserList");
            // console.log(user);
            setUser(user);

            AssignService.requestCloudUser(paginationModel.page, paginationModel.pageSize, setRows, setRowCount, user.company, sort);
            // }
        });
    }, [paginationModel.page, paginationModel.pageSize, reload, sort]);

    const handleRowClick = (params) => {
        // console.log("handleRowClick...")
        // console.log(params.row)
        callback(params.row.id);
    };

    const handleSelectionModel = (param) => {};

    const handleSortModelChange = (sortModel) => {
        // console.log(sortModel);
        setSort(sortModel);
    };

    return (
        <Box sx={{ flexGrow: 1, height: "100%" }}>
            <DataGridPro
                rows={rows}
                rowCount={rowCount}
                columns={columns}
                disableColumnFilter
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pagination={true}
                paginationMode="server"
                sortingMode="server"
                pageSizeOptions={[20, 50, 100, 200, 500]}
                onRowSelectionModelChange={(newSelection) => handleSelectionModel(newSelection)}
                // density="compact"
                // checkboxSelection
                disableMultipleSelection={true}
                // disableSelectionOnClick
                // style={{ height: '100%' }}
                // headerHeight={0}
                hideFooterSelectedRowCount
                onRowClick={handleRowClick}
                sortModel={sort}
                onSortModelChange={handleSortModelChange}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                            username: false,
                        },
                    },
                }}
            />
        </Box>
    );
}

function getColumns(hideCompanyColumn, handleEnabled, user) {
    return [
        {
            field: "id",
            headerName: "ID",
            flex: 1,
        },
        {
            field: "cloud_user.email",
            headerName: "Email",
            width: 200,
            editable: false,
        },
        {
            field: "role",
            headerName: "Role",
            width: 140,
            editable: false,
            sortable: false,
            renderCell: (params) => {
                const isManager = user && user.authorities == "MANAGER" ? true : false;
                return <RoleCheckBoxes username={params.row.username} role={params.row.role} readOnly={isManager}></RoleCheckBoxes>;
            },
        },
        {
            field: "company",
            headerName: "Company",
            width: 150,
            editable: false,
            renderCell: (params) => {
                // console.log("-----//-----")
                // console.log(params)
                const isManager = user && user.authorities == "MANAGER" ? true : false;
                // const companyOnly = params.row.company.filter((c) => c != "TENANTS");
                const companyOnly = params.row.company;
                return <CompanyCheckBoxes username={params.row.username} groups={companyOnly} readOnly={isManager} />;
            },
            hide: hideCompanyColumn,
            sortable: false,
        },
        {
            field: "username",
            headerName: "Username",
            width: 140,
            editable: false,
        },
        {
            field: "enabled",
            headerName: "Sign-in",
            width: 140,
            editable: false,
            sortable: false,
            renderCell: (params) => {
                // console.log("///-------------------params")
                // console.log(params)
                return (
                    <>
                        <span style={{ marginRight: 5 }}>Enable</span>
                        <CustomSwitch checked={params.row.enabled} name={params.row.username} onChange={handleEnabled} />
                    </>
                );
            },
        },
    ];
}
