import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MeasurementService from "../island/measuresGrid/MeasurementService";
import Switch from "@mui/material/Switch";
import UserService from "Services/userService";
import { debounce } from "lodash";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("53264e234090f26bf650c109bcf9fc2fTz0xMDUxMDMsRT0xNzY5Mjg1NDAxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

export default function CommSettings({ selectedThingId }) {
    const { t, i18n } = useTranslation();
    const [number, setNumber] = useState("");
    const [smsOn, setSmsOn] = useState(false);

    const [cfgTxGB, setCfgTxGB] = useState("");
    const [cfgMtrGB, setCfgMtrGB] = useState("");

    const [cfgTxYOR, setCfgTxYOR] = useState("");
    const [cfgMtrY, setCfgMtrY] = useState("");
    const [cfgMtrG, setCfgMtrG] = useState("");
    const [cfgMtrR, setCfgMtrR] = useState("");

    const [errNumber, setErrNumber] = useState(false);
    const [user, setUser] = useState(null);
    const [original, setOriginal] = useState({});

    useEffect(() => {
        let isMounted = true;

        UserService.getAuthenticatedUser().then((user) => {
            setUser(user);
        });

        MeasurementService.requestSetting(selectedThingId, setNumber, undefined, undefined).then((r) => {
            if (!isMounted) return;
            setOriginal(r);

            const mappings = {
                CFG_TX_FREQ_GREEN_BLUE: setCfgTxGB,
                CFG_TX_FREQ_RED_ORANGE_YELLOW: setCfgTxYOR,
                CFG_PAS_MESURE_GREEN_BLUE: setCfgMtrGB,
                CFG_PAS_MESURE_YELLOW: setCfgMtrY,
                CFG_PAS_MESURE_ORANGE: setCfgMtrG,
                CFG_PAS_MESURE_RED: setCfgMtrR,
                CFG_SMS_ON: setSmsOn,
            };

            Object.keys(mappings).forEach((key) => {
                if (!isMounted) return;

                if (key === "CFG_SMS_ON") {
                    const smsOnValue = r[key] === "true" || r[key] === true;
                    setSmsOn(smsOnValue);
                } else if (r[key]) {
                    let t = timeStringToMinutes(r[key]);
                    mappings[key](t);
                }
            });
        });

        return () => {
            isMounted = false;
        };
    }, [selectedThingId]);

    const timeStringToMinutes = (timeString) => {
        const timeParts = timeString.split(":");

        // Handle different formats
        if (timeParts.length === 3) {
            // HH:mm:ss
            const hours = parseInt(timeParts[0], 10);
            const minutes = parseInt(timeParts[1], 10);
            return hours * 60 + minutes;
        } else if (timeParts.length === 2) {
            // HH:mm
            const hours = parseInt(timeParts[0], 10);
            const minutes = parseInt(timeParts[1], 10);
            return hours * 60 + minutes;
        }

        return 0;
    };

    const handleValues = (e) => {
        setNumber(e.target.value);
        debounceNumber(e.target.value, user, original);
    };

    const debounceNumber = useCallback(
        debounce((number, user, original) => {
            // Perform save operation here
            // console.log(number);
            MeasurementService.saveSettings(selectedThingId, "HL_NUMBER", number, user.username, original);
        }, 500),
        []
    );

    const handleTimeChange = (name, newValue) => {
        switch (name) {
            case "CFG_TX_FREQ_GREEN_BLUE":
                setCfgTxGB(newValue);
                break;
            case "CFG_PAS_MESURE_GREEN_BLUE":
                setCfgMtrGB(newValue);
                break;
            case "CFG_TX_FREQ_RED_ORANGE_YELLOW":
                setCfgTxYOR(newValue);
                break;
            case "CFG_PAS_MESURE_YELLOW":
                setCfgMtrY(newValue);
                break;
            case "CFG_PAS_MESURE_ORANGE":
                setCfgMtrG(newValue);
                break;
            case "CFG_PAS_MESURE_RED":
                setCfgMtrR(newValue);
                break;
            default:
                break;
        }
        debounceTime(name, formatMinutesToHHMM(newValue), user, original); // Debounced save
    };

    const formatMinutesToHHMM = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
    };

    const debounceTime = useCallback(
        debounce((name, newValue, user, original) => {
            // Perform save operation here
            if (newValue == "Invalid Date") {
                return;
            }

            MeasurementService.saveSettings(selectedThingId, name, newValue, user.username, original);
        }, 500),
        []
    );

    const handleSmsChange = (event) => {
        setSmsOn(event.target.checked);
        MeasurementService.saveSettings(selectedThingId, "CFG_SMS_ON", event.target.checked, user.username, original);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                            {t("superviot.communication")}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1} sx={{ pl: 5, pr: 5 }}>
                                <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", alignItems: "flex-end", gap: 1 }} height={45}>
                                    <Typography variant="body" gutterBottom width={90}>
                                        {t("label.number")}
                                    </Typography>
                                    <TextField
                                        id="txtNumber"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        variant="outlined"
                                        size="small"
                                        sx={{ width: 150 }}
                                        value={number}
                                        onChange={handleValues}
                                        error={errNumber}
                                    />

                                    <Typography variant="body" gutterBottom width={90} sx={{ ml: 10 }}>
                                        {t("label.activation")}
                                    </Typography>
                                    <Switch checked={smsOn} onChange={handleSmsChange} inputProps={{ "aria-label": "controlled" }} />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                            {t("superviot.frequences")}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1} sx={{ pl: 5, pr: 5 }}>
                                {/* GREEN and BLUE */}
                                <Grid item xs={12}>
                                    <Typography variant="body" gutterBottom>
                                        {t("superviot.level_1")}
                                    </Typography>
                                </Grid>

                                {/* GREEN and BLUE : Transmission */}
                                <Grid item xs={6} sx={{ display: "flex", flexDirection: "row", alignItems: "flex-end" }} height={45}>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 2, pl: 4 }}>
                                        <Typography variant="body" gutterBottom width={100}>
                                            {t("label.transmission")}
                                        </Typography>
                                        <TextField
                                            id="cfgTxGB"
                                            type="number"
                                            value={cfgTxGB}
                                            onChange={(e) => handleTimeChange("CFG_TX_FREQ_GREEN_BLUE", parseInt(e.target.value, 10))}
                                            size="small"
                                            sx={{ width: 150 }}
                                            inputProps={{ min: 0 }}
                                        />
                                    </Box>
                                </Grid>

                                {/* GREEN and BLUE : Measures */}
                                <Grid item xs={6} sx={{ display: "flex", flexDirection: "row", alignItems: "flex-end" }} height={45}>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                        <Typography variant="body" gutterBottom width={90}>
                                            {t("superviot.measures")}
                                        </Typography>
                                        <TextField
                                            id="txtMeasure"
                                            type="number"
                                            value={cfgMtrGB}
                                            onChange={(e) => handleTimeChange("CFG_PAS_MESURE_GREEN_BLUE", parseInt(e.target.value, 10))}
                                            size="small"
                                            sx={{ width: 150 }}
                                            inputProps={{ min: 0 }}
                                        />
                                    </Box>
                                </Grid>

                                {/* YELLOW, ORANGE, RED */}
                                <Grid item xs={12}>
                                    <Typography variant="body" gutterBottom>
                                        {t("superviot.level_2")}
                                    </Typography>
                                </Grid>

                                {/* YELLOW, ORANGE, RED :: Transmission */}
                                <Grid item xs={6} sx={{ display: "flex", flexDirection: "row", alignItems: "flex-end" }} height={45}>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 2, pl: 4 }}>
                                        <Typography variant="body" gutterBottom width={100}>
                                            {t("label.transmission")}
                                        </Typography>
                                        <TextField
                                            id="txtTransmission"
                                            type="number"
                                            value={cfgTxYOR}
                                            onChange={(e) => handleTimeChange("CFG_TX_FREQ_RED_ORANGE_YELLOW", parseInt(e.target.value, 10))}
                                            size="small"
                                            sx={{ width: 150 }}
                                            inputProps={{ min: 0 }}
                                        />
                                    </Box>
                                </Grid>

                                {/* YELLOW, ORANGE, RED :: Measures */}
                                <Grid item sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                        <Typography variant="body" gutterBottom width={90}>
                                            {t("superviot.measures")}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 2, pl: 4 }}>
                                        <Typography variant="body" gutterBottom width={120}>
                                            {t("superviot.level_yellow")}
                                        </Typography>
                                        <TextField
                                            id="txtMeasureYellow"
                                            type="number"
                                            value={cfgMtrY}
                                            onChange={(e) => handleTimeChange("CFG_PAS_MESURE_YELLOW", parseInt(e.target.value, 10))}
                                            size="small"
                                            sx={{ width: 150 }}
                                            inputProps={{ min: 0 }}
                                        />
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 2, pl: 4 }}>
                                        <Typography variant="body" gutterBottom width={120}>
                                            {t("superviot.level_orange")}
                                        </Typography>
                                        <TextField
                                            id="txtMeasureOrange"
                                            type="number"
                                            value={cfgMtrG}
                                            onChange={(e) => handleTimeChange("CFG_PAS_MESURE_ORANGE", parseInt(e.target.value, 10))}
                                            size="small"
                                            sx={{ width: 150 }}
                                            inputProps={{ min: 0 }}
                                        />
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 2, pl: 4 }}>
                                        <Typography variant="body" gutterBottom width={120}>
                                            {t("superviot.level_red")}
                                        </Typography>
                                        <TextField
                                            id="txtMeasureRed"
                                            type="number"
                                            value={cfgMtrR}
                                            onChange={(e) => handleTimeChange("CFG_PAS_MESURE_RED", parseInt(e.target.value, 10))}
                                            size="small"
                                            sx={{ width: 150 }}
                                            inputProps={{ min: 0 }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </Box>
    );
}
