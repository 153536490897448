import { useState } from "react";
import moment from "moment-timezone";
import UtilService from "Services/utilService";
import { ChartLangOption } from "../../../components/chart/ChartLangOption";

export function ChartWaterWS301Options(series, t, i18n) {
    const [groupEnable, setGroupEnable] = useState(true);

    // console.log(series);
    // console.log(series.chartData);

    const langSettings = ChartLangOption(i18n);

    return {
        chart: {
            type: "xrange",
            // width: '100vw'
        },
        title: {
            text: null,
        },
        xAxis: {
            type: "datetime",
            ordinal: false,
        },
        yAxis: {
            title: {
                text: "",
            },
            categories: ["Leak", "Normal"],
            reversed: true,
            opposite: false
        },
        tooltip: {
            shared: true,
            split: false,
            formatter: function () {
                let result = "";

                // console.log("this.points", this.points);
                this.points.forEach((point) => {
                    let x = point.x;
                    let x2 = point.x2;

                    // console.log("point", point);
                    let status = point.y === 0 ? "Leak" : "Normal";

                    let start = UtilService.formatMDHms(x);
                    let end = UtilService.formatMDHms(x2);
                    let duration = UtilService.toDuration(x2 - x);

                    result += `<br/><b>${status}</b><br/>${start} - ${end}<br/>Duration: ${duration}`;
                });

                return result;
            },
        },
        credits: {
            enabled: false,
        },
        time: {
            timezone: moment.tz.guess(),
            useUTC: false,
        },
        series: [
            {
                name: "Status",
                // pointPadding: 0,
                // groupPadding: 0,
                borderColor: "gray",
                pointWidth: 20,
                data: series.chartData,
                dataLabels: {
                    enabled: true,
                },
                // color: function(point) {
                //     console.log(point);
                //     return point.y === 0 ? '#FF4444' : '#44FF44'; // Red for Close, Green for Open
                // } 
            },
        ],
        rangeSelector: {
            buttons: [
                {
                    type: "day",
                    count: 1,
                    text: "1d raw",
                    events: {
                        click: function () {
                            setGroupEnable(false);
                        },
                    },
                },
                {
                    type: "week",
                    count: 1,
                    text: "1w",
                    events: {
                        click: function () {
                            setGroupEnable(true);
                        },
                    },
                },
                {
                    type: "month",
                    count: 1,
                    text: "1m",
                    events: {
                        click: function () {
                            setGroupEnable(true);
                        },
                    },
                },
                // {
                //     type: "1y",
                //     count: 1,
                //     text: "12m",
                //     events: {
                //         click: function () {
                //             setGroupEnable(true);
                //         },
                //     },
                // },
                {
                    type: "all",
                    text: "All",
                    events: {
                        click: function () {
                            setGroupEnable(true);
                        },
                    },
                },
            ],
            selected: 5,
            inputEnabled: true,
        },
        lang: langSettings,
    };
}
