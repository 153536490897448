import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import Box from '@mui/material/Box';

highchartsMore(Highcharts);

/**
 * ElecChartStacked Component
 * 
 * Affiche un graphique empilé de consommation électrique
 * @param {object} selectedDate - Date sélectionnée (objet dayjs)
 */
export default function ElecChartStacked({ selectedDate }) {
    const getChartOptions = () => {
        const startOfMonth = selectedDate.startOf('month');
        const daysInMonth = selectedDate.daysInMonth();
        // Modification pour avoir 2 points par jour (24h/12h = 2)
        const data = Array.from({ length: daysInMonth * 2 }, (_, index) => {
            const timestamp = startOfMonth
                .add(Math.floor(index/2), 'day')
                .add((index % 2) * 12, 'hour')  // Multiplié par 12 pour avoir des intervalles de 12h
                .valueOf();
            return [
                timestamp,
                Math.random() * 100, // Poste 1 (Jour)
                Math.random() * 150, // Poste 2 (Nuit)
                Math.random() * 80,  // Poste 3 (Weekend)
            ];
        });

        return {
            chart: {
                type: 'area',
                height: 400,
                width: '100%', // Forcer la largeur à 100%
                showAxes: true,
                options3d: {
                    enabled: false
                },
                spacingTop: 0,
                spacingLeft: 0,  // Réduire les marges
                spacingRight: 0, // Réduire les marges
                style: {
                    width: '100%'  // S'assurer que le conteneur prend toute la largeur
                }
            },
            // Ajouter cette configuration pour masquer la légende
            legend: {
                enabled: false
            },
            title: {
                text: undefined  // Suppression du titre
            },
            credits: {
                enabled: false  // Suppression du watermark Highcharts
            },
            exporting: {
                enabled: false  // Suppression du menu d'export
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: {
                    day: '%e %b',
                    hour: '%H:%M'
                }
            },
            yAxis: {
                title: {
                    text: 'kWh'
                },
                stackLabels: {
                    enabled: false
                },
                labels: {
                    enabled: true, // Activer l'affichage des labels
                    style: {
                        color: '#666666'
                    }
                },
                gridLineWidth: 1 // Ajouter les lignes de grille
            },
            tooltip: {
                shared: true,
                valueDecimals: 2,
                valueSuffix: ' kWh',
                enabled: true // S'assurer que le tooltip est activé
            },
            plotOptions: {
                area: {
                    stacking: 'normal',
                    marker: {
                        enabled: true, // Activer les marqueurs
                        radius: 2,
                        states: {
                            hover: {
                                enabled: true
                            }
                        }
                    },
                    fillOpacity: 0.7,
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            series: [{
                name: 'Heures Creuses',
                data: data.map(d => [d[0], d[1]]),
                color: '#70AD47',
                stack: 'consumption'
            }, {
                name: 'Heures Pleines',
                data: data.map(d => [d[0], d[2]]),
                color: '#4472C4',
                stack: 'consumption'
            }, {
                name: 'Heures Pointe',
                data: data.map(d => [d[0], d[3]]),
                color: '#ED7D31',
                stack: 'consumption'
            }]
        };
    };

    return (
        <Box >
            <HighchartsReact
                highcharts={Highcharts}
                options={getChartOptions()}
            />
        </Box>
    );
}