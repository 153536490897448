import { useState, useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import _ from "underscore";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import AttributeComboBox from "./AttributeComboBox";
import DetailService from "./DetailService";
import CloseIcon from "@mui/icons-material/Close";
import GatewayStatusPagination from "./GatewayStatusPagination";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { IoWaterOutline } from "react-icons/io5";
import TabMeterSummary from "./TabMeterSummary";
import Button from "@mui/material/Button";
import CatalogDialog from "./CatalogDialog";
import Alert from "@mui/material/Alert";
import { useMediaQuery, useTheme } from "@mui/material";
import { LicenseInfo } from "@mui/x-license-pro";
import moment from "moment";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarRateIcon from "@mui/icons-material/StarRate";
import DialogContent from "@mui/material/DialogContent";
import BarChartIcon from "@mui/icons-material/BarChart";
import GraphMain from "./graph/GraphMain";
import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";
import NetworkInfo from "./NetworkInfo";
import SendIcon from "@mui/icons-material/Send";
import SendDownlink from "./SendDownlink";
import UserService from "Services/userService";
import TabPlace from "./TabPlace";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
LicenseInfo.setLicenseKey("53264e234090f26bf650c109bcf9fc2fTz0xMDUxMDMsRT0xNzY5Mjg1NDAxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

export default function SensorDetailDialog({ open, setOpen, callback, selectedThingId, user }) {
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 20 });

    const [thingName, setThingName] = useState("");
    const [thingDetail, setThingDetail] = useState();
    const [thingTags, setThingTags] = useState([]);
    const [deveui, setDeveui] = useState("");
    const [previousSortQuery, setPreviousSortQuery] = useState("");
    const [selectedAttributeId, setSelectedAttributeId] = useState("all");
    const [selectedCompany, setSelectedCompany] = useState("");
    const [lastGatewayStatus, setLastGatewayStatus] = useState([]);
    const [product, setProduct] = useState();

    const [tabIndex, setTabIndex] = useState(0);

    const [position, setPosition] = useState([45.49573, -73.60635]);
    const [gps, setGps] = useState();

    const [openCatalog, setOpenCatalog] = useState(false);
    const [reload, setReload] = useState();

    const theme = useTheme();
    const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [star, setStar] = useState(false);
    const [allAttributes, setAllAttributes] = useState([]);
    const [disableAttributeTab, setDisableAttributeTab] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const columns = [
        {
            field: "id",
            headerName: "ID",
            flex: 1,
            editable: false,
            sortable: false,
            hide: true,
        },
        {
            field: "timestamp",
            headerName: "Timestamp",
            flex: 1,
            editable: false,
            sortable: true,
        },
        {
            field: "measurement",
            headerName: "Measurement",
            flex: 1,
            editable: false,
            sortable: true,
        },
        {
            field: "value",
            headerName: "Value",
            flex: 1,
            editable: false,
            sortable: false,
        },
    ];

    const handleClose = () => {
        setOpen(false);
        callback();

        setGps(undefined);
        setThingName("");
        setDeveui("");
        setThingTags([]);
        setStar(false);
        setTabIndex(0);
    };

    useEffect(() => {
        // console.log("useEffect in SensorDetailDialog");
        // console.log(user);
        // console.log(isSmScreen)

        if (open) {
            DetailService.requestThing(selectedThingId, setThing);
            DetailService.requestMeasurements(
                paginationModel.page,
                paginationModel.pageSize,
                setRows,
                selectedThingId,
                previousSortQuery,
                selectedAttributeId,
                setRowCount
            );

            DetailService.requestLastGatewayStatus(selectedThingId, setLastGatewayStatus);

            // for map
            DetailService.requestLastGps(selectedThingId, setPosition, setGps);

            if (allAttributes) {
                DetailService.requestFavoriteAttribute(user.cognitoUserName, selectedThingId, undefined, setStar).then((r) => {
                    const all = allAttributes.length - 1;
                    const registered = r.length;

                    if (all == registered) {
                        setStar(true);
                    }
                });
            }

            if (user.authorities && user.authorities === "TENANT") {
                setDisableAttributeTab(true);
            }

            UserService.getAuthenticatedUser().then((u) => {
                // console.log(u);
                if (u.authorities == "SUPER_ADMIN" || u.authorities == "CLIENT_ADMIN") {
                    setIsAdmin(true);
                }
            });
        }
    }, [open, paginationModel.page, paginationModel.pageSize, selectedAttributeId, reload, star, allAttributes, selectedThingId, previousSortQuery]);

    const setThing = (thing) => {
        setThingDetail(thing);
        setThingName(thing.displayName);

        if (thing.customFields != undefined) {
            thing.customFields.forEach((cf) => {
                if (cf.name == "devEui") {
                    setDeveui(cf.value);
                }
            });
        }

        setThingTags(
            thing.tags.map((ele) => {
                return {
                    id: ele.id,
                    label: ele.tag,
                };
            })
        );

        setSelectedCompany(thing.company);
        setProduct(thing.product);
    };

    const handleSortModelChange = (param) => {
        let sortModel = param;

        let qParams = [];
        _.each(sortModel, function (sm) {
            let result = "";

            switch (sm.field) {
                case "timestamp":
                    result += "measure.timestamp";
                    break;
                case "measurement":
                    result += "attribute.name";
                    break;
                default:
                    break;
            }

            switch (sm.sort) {
                case "asc":
                    result += "&dir=ASC";
                    break;
                case "desc":
                    result += "&dir=DESC";
                    break;
                default:
                    break;
            }

            qParams.push(result);
        });

        let sortQuery = "";
        if (qParams.length > 0) {
            sortQuery = "&sort=" + qParams.join(",");
        }

        if (previousSortQuery != sortQuery) {
            setPreviousSortQuery(sortQuery);
            DetailService.requestMeasurements(paginationModel.page, paginationModel.pageSize, setRows, selectedThingId, sortQuery, selectedAttributeId);
        }
    };

    const handleTabs = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleAssociate = () => {
        setOpenCatalog(true);
    };

    const handleCatalog = (selectedProduct) => {
        setOpenCatalog(false);
        DetailService.associateThing(selectedThingId, selectedProduct.id).then(() => {
            setReload(moment().unix());
        });
    };

    const handleSelectedAttributeId = (attribute_id) => {
        // console.log("handleSelectedAttributeId...///...");
        setSelectedAttributeId(attribute_id);

        // console.log(user.cognitoUserName);
        // console.log(selectedThingId);
        // console.log(attribute_id);

        DetailService.requestFavoriteAttribute(user.cognitoUserName, selectedThingId, attribute_id, setStar);
    };

    const handleFavorite = () => {
        // console.log("selectedAttributeId");
        // console.log(user.cognitoUserName)
        // console.log(selectedAttributeId)
        DetailService.updateFavoriteAttribute(star, user.cognitoUserName, selectedAttributeId, allAttributes, selectedThingId).then(() => setStar(!star));
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={isSmScreen ? "sm" : "md"} fullWidth scroll="paper">
                <DialogTitle id="form-dialog-title" sx={{ pt: 1, pb: 1 }}>
                    Meter's details
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{ position: "absolute", right: 6, top: 2, color: (theme) => theme.palette.grey[500] }}
                        style={{ position: "absolute" }}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers sx={{ p: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ marginTop: "10px" }}>
                            <Stack spacing={0}>
                                <Grid container spacing={0} sx={{ p: 0, m: 0 }}>
                                    <Grid item xs={12} sm={12}>
                                        <Box sx={{ width: "100%" }}>
                                            <Tabs value={tabIndex} onChange={handleTabs} aria-label="list or map" sx={{ m: 0 }}>
                                                <Tab icon={<IoWaterOutline size={21} color={"#666666"} />} />
                                                <Tab icon={<HomeWorkIcon />} />
                                                <Tab icon={<BarChartIcon />} />
                                                <Tab icon={<ListAltIcon />} disabled={disableAttributeTab} />
                                                <Tab icon={<NetworkCheckIcon />} />
                                                {isAdmin && <Tab icon={<SendIcon />} />}
                                            </Tabs>
                                        </Box>
                                        {tabIndex == 0 && <Box sx={{ marginTop: "0px", width: "200px", height: "0px" }}>&nbsp;</Box>}

                                        {tabIndex == 3 && <Box sx={{ marginTop: "0px", width: "200px", height: "0px" }}>&nbsp;</Box>}
                                    </Grid>

                                    {tabIndex == 0 && (
                                        <Grid item xs={12} style={{ marginTop: "20px", padding: "0px", width: 400, height: 556 }}>
                                            <TabMeterSummary selectedThingId={selectedThingId} callback={callback} />
                                        </Grid>
                                    )}

                                    {tabIndex == 1 && (
                                        <Grid item xs={12} style={{ marginTop: "20px", padding: "0px", width: 400, height: 556 }}>
                                            <TabPlace selectedThingId={selectedThingId} />
                                        </Grid>
                                    )}

                                    {tabIndex == 2 && (
                                        <Grid item xs={12} style={{ marginTop: "20px", padding: "0px", width: 400, height: 556 }}>
                                            <GraphMain selectedThingId={selectedThingId} product={product} user={user} />
                                        </Grid>
                                    )}

                                    {tabIndex == 3 && (
                                        <Grid item xs={12} style={{ marginTop: "20px", padding: "0px", width: 400, height: 556 }}>
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                {thingDetail && !thingDetail.product && (
                                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mr: 2 }}>
                                                        <Alert severity="warning">Associate sensor with a product to get measurements</Alert>
                                                        <Button
                                                            variant="contained"
                                                            onClick={handleAssociate}
                                                            style={{
                                                                width: "100px",
                                                                backgroundColor: "#5694B5",
                                                                color: "#FFFFFF",
                                                                textTransform: "none",
                                                                mt: 2,
                                                            }}
                                                        >
                                                            Associate
                                                        </Button>
                                                    </Box>
                                                )}

                                                <AttributeComboBox
                                                    selectedThingId={selectedThingId}
                                                    open={open}
                                                    callbackSelectedAttributeId={handleSelectedAttributeId}
                                                    callbackAllAttributes={setAllAttributes}
                                                ></AttributeComboBox>
                                                <IconButton aria-label="favorite" onClick={() => handleFavorite()} size="large">
                                                    {star && <StarRateIcon />}
                                                    {!star && <StarOutlineIcon />}
                                                </IconButton>
                                            </Box>

                                            <DataGridPro
                                                rows={rows}
                                                rowCount={rowCount}
                                                columns={columns}
                                                pagination={true}
                                                paginationMode="server"
                                                sortingMode="server"
                                                paginationModel={paginationModel}
                                                pageSizeOptions={[20, 50, 100, 500]}
                                                onPaginationModelChange={setPaginationModel}
                                                checkboxSelection={false}
                                                onSortModelChange={handleSortModelChange}
                                                density="compact"
                                                filterMode="server"
                                                disableMultipleColumnsFiltering={true}
                                                disableColumnReorder={true}
                                                initialState={{
                                                    columns: {
                                                        columnVisibilityModel: {
                                                            id: false,
                                                        },
                                                    },
                                                }}
                                                style={{ height: 500 }}
                                            />
                                        </Grid>
                                    )}
                                    {tabIndex == 4 && (
                                        <Grid item xs={12} style={{ marginTop: "20px", padding: "0px", width: 400, height: 556 }}>
                                            <NetworkInfo selectedThingId={selectedThingId} />
                                            <GatewayStatusPagination lastGatewayStatus={lastGatewayStatus} selectedThingId={selectedThingId} />
                                        </Grid>
                                    )}
                                    {tabIndex == 5 && isAdmin && (
                                        <Grid item xs={12} style={{ marginTop: "20px", padding: "0px", width: 400, height: 556 }}>
                                            <SendDownlink thingDetail={thingDetail} />
                                        </Grid>
                                    )}
                                </Grid>
                            </Stack>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <CatalogDialog open={openCatalog} setOpen={setOpenCatalog} callback={handleCatalog} />
        </div>
    );
}
