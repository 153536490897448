import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import DetailService from "./DetailService";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";

export default function NetworkInfo({ selectedThingId }) {
    const { t, i18n } = useTranslation();
    const [thing, setThing] = useState();
    const [lorawan, setLorawan] = useState();
    const [devEui, setDeveui] = useState();
    const [otaaVersion, setOttaVersion] = useState();
    const [reagion, setRegion] = useState();
    const [serial, setSerial] = useState();

    const [isSensingNetwork, setIsSensingNetwork] = useState(false);

    useEffect(() => {
        DetailService.requestThing(selectedThingId, setThing).then((r) => {
            console.log(r);
            if (r && r.customFields) {
                const findField = (name) => r.customFields.find((c) => c.name === name);

                setDeveui(findField("devEui")?.value);
                setOttaVersion(findField("OtaaV1_0") ? "v1.0.1" : findField("OtaaV1_1") ? "v1.1" : undefined);
                setRegion(findField("lorawanRegion")?.value);
            }

            if (r.lorawanWirelessDeviceId) {
                setIsSensingNetwork(true);
            }
            if (r.product) {
                const lora = r.product.connectivityTypes.find((c) => c == "LORA");
                if (lora) {
                    setLorawan("LoRaWAN");
                }
            }

            if (r.serialNumber) setSerial(r.serialNumber);
        });
    }, []);

    const handleSerial = (e) => {
        setSerial(e.target.value);
        DetailService.updateSerialNumber(selectedThingId, e.target.value);
    };

    return (
        <Box sx={{ flexGrow: 1, p: 2 }}>
            <Paper elevation={3} sx={{ p: 2 }}>
                <Grid container>
                    {lorawan && (
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" gutterBottom>
                                {lorawan}
                            </Typography>
                        </Grid>
                    )}
                    {isSensingNetwork && (
                        <>
                            <Grid item xs={1}>
                                &nbsp;
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body2" gutterBottom>
                                    Network server
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography variant="body2" gutterBottom>
                                    {isSensingNetwork ? "Device Explorer" : "Unknown"}
                                </Typography>
                            </Grid>
                        </>
                    )}
                    {devEui && (
                        <>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    DEVEUI
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {devEui}
                                </Typography>
                            </Grid>
                        </>
                    )}
                    {otaaVersion && isSensingNetwork && (
                        <>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    OTAA
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {otaaVersion}
                                </Typography>
                            </Grid>
                        </>
                    )}
                    {reagion && isSensingNetwork && (
                        <>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    Region
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {reagion}
                                </Typography>
                            </Grid>
                        </>
                    )}

                    <>
                        <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="body1" gutterBottom>
                                {t("col.lorawan_sn")}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                id="txtSerial"
                                label=""
                                variant="outlined"
                                value={serial}
                                size="small"
                                onChange={(e) => handleSerial(e)}
                                inputProps={{ maxLength: 20 }}
                            />
                        </Grid>
                    </>
                </Grid>
            </Paper>
        </Box>
    );
}
