import React, { useState, useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { BootstrapDialog } from "../../../../../components/ui/BootstrapDialog";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Item from "../../../../../components/ui/Item";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { DesktopDatePicker } from "@mui/x-date-pickers-pro";
import TextField from "@mui/material/TextField";
import { CustomButton } from "../../../../../components/ui/CustomButton";
import TenantService from "./TenantService";
import moment from "moment";
import UtilService from "Services/utilService";

import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("53264e234090f26bf650c109bcf9fc2fTz0xMDUxMDMsRT0xNzY5Mjg1NDAxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

export default function CheckOutDialog({ open, setOpen, selectedThingId, data, attUnits, setReload }) {
    const [checkOutDate, setCheckoutDate] = useState(new Date());
    const [fullName, setFullName] = useState("");
    const [checkInDate, setCheckInDate] = useState();
    const [period, setPeriod] = useState();
    const [checkInVolume, setCheckInVolume] = useState();
    const [checkOutVolume, setCheckOutVolume] = useState();
    const [unit, setUnit] = useState();
    const [flag, setFlag] = useState(true);

    useEffect(() => {
        // console.log("uef in CheckOutDialog");
        // console.log(data);
        // console.log(attUnits);

        if (data && checkInVolume == undefined) {
            // console.log("data", data);
            setFullName(data.cloudUser.firstName + " " + data.cloudUser.lastName);

            let cIn = moment(data.checkInDate * 1000);
            setCheckInDate(UtilService.formatYearMonthDay(cIn));
            setCheckInVolume(data.checkInVolume);

            if (data.checkOutDate && flag) {
                let cOut = moment(data.checkOutDate * 1000);
                if (data.checkOutDate && !checkOutVolume) {
                    setCheckoutDate(cOut);
                    setCheckOutVolume(data.checkOutVolume);
                }

                const duration = cOut.diff(cIn, "days");
                const diffDays = duration > 1 ? duration + " days" : duration + "day";
                // setPeriod(`${UtilService.formatYearMonthDay(cIn)} - ${UtilService.formatYearMonthDay(cOut)},  ${diffDays}`);
                setPeriod(`${diffDays}`);
            } else {
                let cOut = moment(checkOutDate);

                const duration = cOut.diff(cIn, "days");
                const diffDays = duration > 1 ? duration + " days" : duration + "day";
                // setPeriod(`${UtilService.formatYearMonthDay(cIn)} - ${UtilService.formatYearMonthDay(cOut)},  ${diffDays}`);
                setPeriod(`${diffDays}`);

                TenantService.requestVolume(selectedThingId, moment(checkOutDate).hour(0).minute(0).second(0).unix()).then((r) => {
                    if (r.value) {
                        setCheckOutVolume(r.value);
                    } else {
                        setCheckOutVolume(0);
                    }
                });
            }
        }

        if (attUnits && attUnits.volume) {
            setUnit(attUnits.volume);
        }
    }, [data, checkOutDate, checkOutVolume, attUnits, flag, selectedThingId]);

    const handleClose = () => {
        setOpen(false);
        setFlag(true);
        setCheckoutDate(undefined);
        setCheckOutVolume(undefined);
    };

    const handleCheckOut = (e) => {
        // console.log(e);
        setFlag(false);
        setCheckoutDate(e);
    };

    const handleCheckout = () => {
        // console.log("handleCheckout..");
        // console.log(data.id);
        // console.log(checkOutDate);

        TenantService.checkOut(data.id, checkOutDate, checkOutVolume).then((r) => {
            setOpen(false);
            setCheckOutVolume();
            setReload(moment().unix());
        });
    };

    return (
        <div>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={"xs"} fullWidth>
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Check-out
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box sx={{ flexGrow: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Grid container spacing={0}>
                                <Grid item xs={5} sx={{ display: "flex", justifyContent: "right" }}>
                                    <Item>Tenant</Item>
                                </Grid>
                                <Grid item xs={7} sx={{ display: "flex", justifyContent: "left" }}>
                                    <Item>{fullName}</Item>
                                </Grid>
                                <Grid item xs={5} sx={{ display: "flex", justifyContent: "right" }}>
                                    <Item>Period</Item>
                                </Grid>
                                <Grid item xs={7} sx={{ display: "flex", justifyContent: "left" }}>
                                    <Item>{period}</Item>
                                </Grid>
                                <Grid item xs={5} sx={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                                    <Item>Current consumption</Item>
                                </Grid>
                                <Grid item xs={7} sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                                    <Item>
                                        {checkOutVolume - checkInVolume} {unit}
                                    </Item>
                                </Grid>
                                <Grid item xs={5} sx={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                                    <Item>Check-out date</Item>
                                </Grid>
                                <Grid item xs={7} sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                                    <Item>
                                        <DesktopDatePicker
                                            label="Date"
                                            inputFormat="MM/dd/yyyy"
                                            value={checkOutDate}
                                            minDate={checkInDate}
                                            onChange={handleCheckOut}
                                            slotProps={{ textField: { size: "small" } }}
                                        />
                                    </Item>
                                </Grid>
                            </Grid>
                        </LocalizationProvider>
                    </Box>
                </DialogContent>
                <DialogActions>
                    {/* <Button autoFocus onClick={handleClose}>
                        Save changes
                    </Button> */}
                    <CustomButton name="Confirm check-out" callback={handleCheckout} />
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
