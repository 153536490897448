import React, { useState, useEffect } from "react";
import { Box, Typography, Card, CardContent, CardActions, IconButton } from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsIcon from "@mui/icons-material/Settings";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import CardService from "./CardService";
import { useHistory } from "react-router-dom";
import NoiseChart from "./NoiseChart";
import CommonSetting from "./CommonSetting";
import CommonTop from "./CommonTop";
import CommonAlert from "./CommonAlert";
import UtilService from "../../../services/utilService";
import { useTranslation } from "react-i18next"; // Ajouter cet import

const getColor = (level) => {
    if (level <= 60) return "green";
    if (level <= 80) return "yellow";
    return "red";
};

const getBarHeight = (index) => {
    const level = (index + 1) * 10;
    if (level === 60 || level === 80) return 60; // Double height for 60 and 80 dB
    return 30;
};

const NoiseCard = ({ asset, setReloadParent }) => {
    const history = useHistory();
    const { t, i18n } = useTranslation(); // Ajouter i18n
    const [soundLevel, setSoundLevel] = useState(0);
    const [maxSoundLevel, setMaxSoundLevel] = useState(0);
    const [avgSoundLevel, setAvgSoundLevel] = useState(0); // Ajout de l'état pour la moyenne
    const [expanded, setExpanded] = useState(false);
    const [currentTime, setCurrentTime] = useState(
        new Date().toLocaleTimeString("fr-FR", {
            hour: "2-digit",
            minute: "2-digit",
        })
    );

    const [batteryLevel, setBatteryLevel] = useState(100);
    const [showAlarms, setShowAlarms] = useState(false);

    const [currentDate, setCurrentDate] = useState(
        new Date().toLocaleDateString(i18n.language, {
            day: "2-digit",
            month: "long",
            year: "numeric",
        })
    );
    const [entity, setEntity] = useState({});
    const [site, setSite] = useState(null);
    const [fixedName, setFixedName] = useState(null);
    const [alarms, setAlarms] = useState([]);
    const [alertCount, setAlertCount] = useState(0);
    const [openSetting, setOpenSetting] = useState(false);
    const [moreFrequentPeriod, setMoreFrequentPeriod] = useState(null);
    const [weighting, setWeighting] = useState(null);

    // Déplacer la constante dans le composant pour utiliser t()
    const soundLevelThresholds = [
        { level: 130, source: t("emera_v2.noise.thresholds.pain") },
        { level: 120, source: t("emera_v2.noise.thresholds.concert") },
        { level: 110, source: t("emera_v2.noise.thresholds.construction") },
        { level: 100, source: t("emera_v2.noise.thresholds.subway") },
        { level: 90, source: t("emera_v2.noise.thresholds.truck") },
        { level: 80, source: t("emera_v2.noise.thresholds.restaurant") },
        { level: 70, source: t("emera_v2.noise.thresholds.traffic") },
        { level: 60, source: t("emera_v2.noise.thresholds.conversation") },
        { level: 50, source: t("emera_v2.noise.thresholds.office") },
        { level: 40, source: t("emera_v2.noise.thresholds.library") },
        { level: 30, source: t("emera_v2.noise.thresholds.whisper") },
        { level: 20, source: t("emera_v2.noise.thresholds.leaves") },
        { level: 10, source: t("emera_v2.noise.thresholds.breathing") },
        { level: 0, source: t("emera_v2.noise.thresholds.hearing") },
    ];

    useEffect(() => {
        let isMounted = true; // Add mounted flag

        const attributeIds = asset.assetAttribute.map((a) => a.attribute.id);
        const thingId = asset.assetAttribute[0].thing.id;

        CardService.requestThing(thingId).then((r) => {
            if (isMounted && r?.site) {
                setSite(r.site);
            }
        });

        CardService.requestLastMeasures(thingId, attributeIds).then((r) => {
            if (isMounted) {
                r.forEach((ele) => {
                    // console.log(ele);

                    if (ele.attribute.name === "LF") {
                        setEntity({
                            thingId: ele.thingId,
                            fixedName: ele.fixedName,
                            value: Number(ele.value),
                            unit: ele.attribute.unit,
                            date: ele.timestamp,
                            attributeId: ele?.attribute?.id,
                        });
                        setFixedName(ele.fixedName);
                        setSoundLevel(Number(ele.value));
                    } else if (ele.attribute.name === "battery") {
                        setBatteryLevel(ele.value);
                    } else if (ele.attribute.name === "weighting") {
                        setWeighting(ele.value);
                    }
                });
            }
        });

        CardService.requestAlerts(thingId, setAlarms, setAlertCount);
        CardService.requestMaxToday(thingId, setMaxSoundLevel);

        const lfAttrId = asset.assetAttribute
            .filter((a) => a.attribute.name === "LF")
            .map((a) => a.attribute.id)
            .find(() => true);
        CardService.requestMoreFrequentPeriod(thingId, lfAttrId, setMoreFrequentPeriod, setAvgSoundLevel);

        // Ajout de la requête pour la moyenne
        // CardService.requestAvgToday(thingId, setAvgSoundLevel);

        // Add cleanup function to prevent state updates after unmount
        return () => {
            isMounted = false;
        };
    }, [asset]);

    useEffect(() => {
        if ("getBattery" in navigator) {
            navigator.getBattery().then((battery) => {
                setBatteryLevel(Math.floor(battery.level * 100));

                battery.addEventListener("levelchange", () => {
                    setBatteryLevel(Math.floor(battery.level * 100));
                });
            });
        }
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(
                new Date().toLocaleTimeString("fr-FR", {
                    hour: "2-digit",
                    minute: "2-digit",
                })
            );
        }, 60000); // Updates every minute (60000 milliseconds)

        // Cleanup interval on component unmount
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        setCurrentDate(
            new Date().toLocaleDateString(i18n.language, {
                day: "2-digit",
                month: "long",
                year: "numeric",
            })
        );
    }, [i18n.language]);

    if (soundLevel > maxSoundLevel) {
        setMaxSoundLevel(soundLevel);
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
        // console.log("🔽 Expansion cliquée");
    };

    const handleSettingsClick = () => {
        // console.log("⚙️ Paramètres cliqués");
        setOpenSetting(true);
    };

    const handleGraphClick = () => {
        // console.log("📈 Graphique cliqué");
        history.push(`/apps/emera/v2/common/${entity.thingId}/${entity.attributeId}`);
    };

    const handleAlarmDetailsClick = () => {
        // console.log("📋 Ouverture detail alarmes");
        history.push("/apps/emera/v2/alerts");
    };

    return (
        <Card
            sx={{
                backgroundColor: "white",
                borderRadius: "12px",
                width: "400px",
                minHeight: expanded ? "600px" : "400px",
                display: "flex",
                flexDirection: "column",
                transition: "all 0.3s ease-in-out",
            }}
        >
            <CommonTop 
                thingId={asset.assetAttribute[0].thing.id} 
                showAlarms={showAlarms} 
                setShowAlarms={setShowAlarms} 
            />

            {/* Suppression du CardActionArea, gardons uniquement le CardContent */}
            <CardContent
                sx={{
                    flex: 1,
                    py: 0, // Supprime le padding vertical
                    "&:last-child": {
                        paddingBottom: 0, // Supprime le padding bottom par défaut de MUI
                    },
                }}
            >
                {!showAlarms ? (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            px: 1, // Ajout de padding horizontal
                        }}
                    >
                        <Typography variant="h6" sx={{ color: "black" }}>
                            {t("emera_v2.noise.title")}
                        </Typography>

                        <Typography variant="h3" sx={{ color: "black", display: "flex", alignItems: "baseline", minWidth: "3ch", gap: 1 }}>
                            {soundLevel}

                            <Typography
                                component="span"
                                variant="h6"
                                sx={{
                                    color: "black",
                                }}
                            >
                                dBA
                            </Typography>
                        </Typography>

                        {/* Déplacement du texte moyenne ici */}
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 0.8, // Réduction de 1 à 0.8
                                mb: 0.8, // Réduction de 1 à 0.8
                            }}
                        >
                            <Typography
                                variant="caption"
                                sx={{
                                    color: "text.secondary",
                                    fontSize: "0.75rem",
                                    fontWeight: 500,
                                }}
                            >
                                {t("emera_v2.noise.average")} :
                            </Typography>
                            <Typography
                                variant="caption"
                                sx={{
                                    color: "black",
                                    fontSize: "0.75rem",
                                    fontWeight: 700,
                                }}
                            >
                                {avgSoundLevel} dBA
                            </Typography>
                            {weighting && (
                                <Typography
                                    variant="caption"
                                    sx={{
                                        color: "gray",
                                        fontSize: "0.7rem",
                                        letterSpacing: "0.5px",
                                        ml: 1,
                                    }}
                                >
                                    {`(${weighting}-weighting)`}
                                </Typography>
                            )}
                        </Box>

                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <Typography variant="body2" sx={{ color: "black", fontWeight: 500 }}>
                                {UtilService.formateHM(entity.date)}
                            </Typography>

                            <UpdateIcon sx={{ color: "black", fontSize: 20 }} />

                            {moreFrequentPeriod && (
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: "gray",
                                        fontWeight: 400,
                                    }}
                                >
                                    {moreFrequentPeriod}
                                </Typography>
                            )}
                        </Box>

                        <Box
                            sx={{
                                width: 150,
                                height: 80,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: "16px", // Réduction de 20px à 16px
                                marginBottom: "16px", // Réduction de 20px à 16px
                                gap: 0.8, // Réduction de 1 à 0.8
                                position: "relative",
                            }}
                        >
                            {[...Array(12)].map((_, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        width: 10,
                                        height: getBarHeight(index),
                                        backgroundColor: soundLevel >= (index + 1) * 10 ? getColor(soundLevel) : "gray",
                                        borderRadius: 50,
                                        transition: "background-color 0.5s ease-in-out, height 0.5s ease-in-out",
                                    }}
                                />
                            ))}

                            {/* Red vertical line for max sound level aligned with 60px bar */}
                            {maxSoundLevel > 0 && (
                                <Box
                                    sx={{
                                        position: "absolute",
                                        left: `${(maxSoundLevel / 120) * 120}px`,
                                        bottom: 0,
                                        width: 3,
                                        height: 70,
                                        backgroundColor: "red",
                                        display: "flex",
                                        alignItems: "flex-end",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            position: "absolute",
                                            bottom: "-20px",
                                            right: "-15px",
                                            fontSize: "12px",
                                            color: "black", // Changed from "white" to "black"
                                            transform: "translateX(-50%)",
                                        }}
                                    >
                                        {maxSoundLevel}
                                    </Typography>
                                </Box>
                            )}
                        </Box>

                        {/* Box des seuils sonores */}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                mt: 1.6, // Réduction de 2 à 1.6
                                maxHeight: "150px",
                                overflowY: "auto",
                                width: "100%",
                            }}
                        >
                            {soundLevelThresholds
                                .filter((threshold) => {
                                    // Find the closest threshold that's less than or equal to the current sound level
                                    const closestThreshold = soundLevelThresholds
                                        .filter((t) => t.level <= soundLevel)
                                        .reduce(
                                            (prev, curr) => {
                                                return curr.level > prev.level ? curr : prev;
                                            },
                                            { level: -Infinity }
                                        );

                                    // Only show the closest threshold
                                    return threshold.level === closestThreshold.level;
                                })
                                .map((threshold, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            width: "90%",
                                            py: 0.4, // Réduction de 0.5 à 0.4
                                        }}
                                    >
                                        <Typography variant="caption" sx={{ color: "black" }}>
                                            {threshold.source}
                                        </Typography>
                                    </Box>
                                ))}
                        </Box>

                        {/* Suppression du Box dupliqué de la moyenne ici */}
                    </Box>
                ) : (
                    <CommonAlert alarms={alarms} onAlarmDetailsClick={handleAlarmDetailsClick} />
                )}
            </CardContent>

            <CardActions disableSpacing sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 1 }}>
                <Box sx={{ display: "flex", gap: 1 }}>
                    <IconButton
                        onClick={handleSettingsClick}
                        aria-label="paramètres"
                        sx={{
                            transition: "transform 0.3s",
                            "&:hover": {
                                transform: "scale(1.1)",
                            },
                        }}
                    >
                        <SettingsIcon />
                    </IconButton>

                    <IconButton
                        onClick={handleGraphClick}
                        aria-label="graphique"
                        sx={{
                            transition: "transform 0.3s",
                            "&:hover": {
                                transform: "scale(1.1)",
                            },
                        }}
                    >
                        <AutoGraphIcon />
                    </IconButton>
                </Box>

                <IconButton
                    onClick={handleExpandClick}
                    aria-label="voir plus"
                    sx={{
                        transition: "transform 0.3s",
                        transform: expanded ? "rotate(180deg)" : "none",
                        "&:hover": {
                            transform: expanded ? "rotate(180deg) scale(1.1)" : "scale(1.1)",
                        },
                    }}
                >
                    <ExpandMoreIcon />
                </IconButton>
            </CardActions>

            {expanded && (
                <Box sx={{ width: "90%", display: "flex", flexDirection: "column" }}>
                    <Typography variant="subtitle1" sx={{ textAlign: "center", color: "text.secondary", mb: 2, fontWeight: 500 }}>
                        {currentDate}
                    </Typography>
                    <NoiseChart thingId={entity.thingId} alarms={alarms} currentDate={currentDate} />
                </Box>
            )}

            <CommonSetting open={openSetting} setOpen={setOpenSetting} thingId={entity?.thingId} />
        </Card>
    );
};

export default NoiseCard;
