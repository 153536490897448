import ApiService from "Services/apiService";
import _ from "underscore";
import dayjs from "dayjs";

const ChartPirService = {
    requestMeasures: async (thingId, attributeId) => {
        const end = dayjs().unix();
        const start = dayjs().subtract(1, "week").unix();

        let requestOptions = {
            url: `/v2/measures/xrange`,
            params: {
                thingId,
                attributeId,
                start,
                end,
            },
        };

        // console.log(requestOptions);
        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response);

                let mapped = response.map((ele) => {
                    return {
                        x: ele.start,
                        x2: ele.end,
                        y: ele.value === "idle" ? 0 : 1,
                        duration: ele.duration,
                        color: ele.value === "idle" ? "#168714" : "#D12427",
                    };
                });

                return mapped;
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default ChartPirService;
