import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getChartOptions } from "./NoiseChartOption";
import CardService from "./CardService";

// Helper functions
export const getHourFromTimestamp = (timestamp) => {
    return parseInt(timestamp.split(" ")[1].split(":")[0]);
};

export const isToday = (timestamp) => {
    const today = new Date();
    const [date] = timestamp.split(" ");
    const [day, month, year] = date.split("/");
    return parseInt(year) === today.getFullYear() && parseInt(month) === today.getMonth() + 1 && parseInt(day) === today.getDate();
};

export default function NoiseChart({ thingId, alarms, currentDate }) {
    const [dayData, setDayData] = useState([]);
    const chartOptions = getChartOptions(alarms, dayData);

    useEffect(() => {
        // console.log(alarms);
        // console.log(thingId);

        CardService.request24HChartData(thingId, setDayData);
    }, [thingId]);

    return (
        <>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </>
    );
}
