import _ from "underscore";
import ApiService from "Services/apiService";
import UtilService from "Services/utilService";

export const PRODUCT_AXIOMA = {
    ID: "304e91ba-16c2-46f7-b3c3-59feb717f0e6",
    ATTRIBUTE_ID_VOLUME: "786e3246-0c7f-46bc-90dc-47e1af5ca926",
    ATTRIBUTE_ID_VOLUME_HOUR: "0ed0d11a-e838-4e35-a0ad-cf334df5f49d",
    ATTRIBUTE_ID_HOURLY_DELTA_CONSUMPTION: "9666fe77-0b6a-42e6-814e-81d998c4ad43",
};

export const PRODUCT_AXIOMA_CUBIC = {
    ID: "24254521-9f97-4d13-9be4-acfd23d15a07",
    ATTRIBUTE_ID_VOLUME_CUBIC: "c14a1e0d-a554-47e0-970d-477f11bb027a",
    ATTRIBUTE_ID_VOLUME_HOUR_CUBIC: "38ed78d2-9da6-4733-acfe-6ee323db173b",
    ATTRIBUTE_ID_HOURLY_DELTA_CONSUMPTION_CUBIC: "dea83359-b28d-4b2b-ae10-6808227054a2",
};

export const PRODUCT_STEALTH = {
    ID: "106718e7-c96a-4444-914e-6b139e42e5ed",
    ATTRIBUTE_ID_VOLUME: "65acf98a-7d3e-466a-aa36-8810b59dcf7c",
    ATTRIBUTE_ID_VOLUME_HOUR: "21d5f7f5-d95a-4412-9284-dc2729475da3",
};

export const PRODUCT_STEALTH_CUBIC = {
    ID: "2b8f3589-f009-41f5-8daa-d7a4201f2041",
    ATTRIBUTE_ID_VOLUME_CUBIC: "e44e6320-1dbb-47ea-a86d-6071f947988e",
    ATTRIBUTE_ID_VOLUME_HOUR_CUBIC: "6b4fac3f-acd7-44e0-a962-bb5b51add0c6",
};

// Axioma's attribute which are generated by PTH system. not sensor
// * ATTRIBUTE_ID_HOURLY_DELTA_CONSUMPTION, ATTRIBUTE_ID_HOURLY_DELTA_CONSUMPTION_CUBIC
// ==> These consumption values are calculated based on delta volumes and delta dates.

export const EXCLUDED_ATTRIBUTES_NAMES = ["email", "hourlyDeltaConsumption", "location", "owner", "radio", "size", "unit", "volumeHour"];

const DetailService = {
    requestThing: async (thingId, setThing) => {
        if (thingId == undefined) {
            return;
        }

        let requestOptions = {
            url: `/api/things/${thingId}?detailed=true`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response);
                setThing(response);
                return response;
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestMeasurements: async (page, pageSize, setRowsCallback, thingId, sortQuery, selectedAttributeId, setRowCount) => {
        if (thingId == undefined) {
            return;
        }

        setRowsCallback([]); // reset the rows

        // let filter = `thing.id:eq_${thingId},measure.timestamp:gt_${moment().subtract(1, 'months').unix()}`;
        let filter = `thing.id:eq_${thingId}`;
        let orFilter = "";

        if (selectedAttributeId != "all") {
            filter += `,attribute.id:eq_${selectedAttributeId}`;
        } else {
            let thingQuery = [];

            // console.log(`{"property":["attribute.name"],"operator":"in","value":"${SUPPORT_ATTRIBUTES_NAMES.join(',')}"}`)
            thingQuery.push(`{"property":["attribute.name"],"operator":"notin","value":"${EXCLUDED_ATTRIBUTES_NAMES.join(",")}"}`);

            orFilter = encodeURI("&orFilter=" + "[" + thingQuery.join(",") + "]");
        }

        let sort = "&sort=measure.timestamp&dir=DESC";
        if (sortQuery) {
            sort = sortQuery;
        }

        let requestOptions = {
            url: `/api/measures?filter=${filter}&page=${page + 1}&pageSize=${pageSize}&detailed=true&${sort}${orFilter}`,
        };

        // console.log("requestOptions...")
        // console.log(requestOptions)

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response)

                let data = [];
                let totalSize = response.totalSize;

                response.data.forEach((ele) => {
                    let tValue = ele.value + " " + ele.attribute.unit;
                    if (ele.attribute.name == "size") {
                        switch (ele.value) {
                            case "0":
                                tValue = "";
                                break;
                            case "10":
                                tValue = "5/8 x ¾”";
                                break;
                            case "20":
                                tValue = "¾” x ¾”";
                                break;
                            case "30":
                                tValue = "1”";
                                break;
                            case "40":
                                tValue = "1 ½”";
                                break;
                            case "50":
                                tValue = "2";
                                break;
                            default:
                                tValue = "";
                                break;
                        }
                    }

                    let obj = {
                        id: ele.id,
                        measurement: ele.attribute.name,
                        value: tValue,
                        timestamp: UtilService.shortestFormateDateMDYHMS(ele.timestamp * 1000),
                    };

                    data.push(obj);
                });

                setRowCount(totalSize);
                setRowsCallback(data);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    updateName: async (thingId, newName, thingDetail) => {
        thingDetail["fixedName"] = newName;

        let requestOptions = {
            url: `/api/things/${thingId}`,
            data: thingDetail,
        };

        return ApiService.putData(requestOptions)
            .then((response) => {
                return response.fixedName;
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestLastGatewayStatus: async (thingId, setLastGatewayStatus) => {
        if (thingId == undefined) {
            return;
        }

        let requestOptions = {
            url: `/api/gateways/last/${thingId}`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                setLastGatewayStatus(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestLastGps: async (thingId, setPosition, setGps) => {
        let requestOptions = {
            url: `/api/things/${thingId}/last_measurements`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response)

                if (response && !response.error) {
                    const gps = response.filter((a) => a.attribute.name == "gps");
                    // console.log(gps);
                    if (!_.isEmpty(gps)) {
                        // console.log("//-------------------");
                        // console.log(gps[0].value.split(","));

                        setPosition(gps[0].value.split(","));
                        setGps(gps[0].value.split(","));
                    }
                }
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestLastMeasureByAttribute: async (thingId, selectedProduct, lastMeterRead) => {
        if (!selectedProduct) {
            return;
        }

        let attributeId = "";
        if (selectedProduct) {
            if (selectedProduct.id == PRODUCT_AXIOMA_CUBIC.ID) {
                attributeId = PRODUCT_AXIOMA_CUBIC.ATTRIBUTE_ID_VOLUME_CUBIC;
            } else {
                attributeId = PRODUCT_AXIOMA.ATTRIBUTE_ID_VOLUME;
            }

            switch (selectedProduct.id) {
                case PRODUCT_AXIOMA_CUBIC.ID:
                    attributeId = PRODUCT_AXIOMA_CUBIC.ATTRIBUTE_ID_VOLUME_CUBIC;
                    break;
                case PRODUCT_AXIOMA.ID:
                    attributeId = PRODUCT_AXIOMA.ATTRIBUTE_ID_VOLUME;
                    break;
                case PRODUCT_STEALTH_CUBIC.ID:
                    attributeId = PRODUCT_STEALTH_CUBIC.ATTRIBUTE_ID_VOLUME_CUBIC;
                    break;
                case PRODUCT_STEALTH.ID:
                    attributeId = PRODUCT_STEALTH.ATTRIBUTE_ID_VOLUME;
                    break;
                default:
                    attributeId = PRODUCT_AXIOMA.ATTRIBUTE_ID_VOLUME;
                    break;
            }
        }

        let requestOptions = {
            url: `/api/measures/${thingId}/${attributeId}`,
        };

        // console.log(requestOptions);
        return ApiService.getData(requestOptions)
            .then((response) => {
                if (response.error) {
                    // setTabIndex(1);
                    lastMeterRead(undefined);
                } else {
                    // console.log("requestLastMeasureByAttribute...");
                    // console.log(response);
                    lastMeterRead(response);
                }
            })
            .catch((e) => {
                console.error(e);
            });
    },

    getWaterMeteringAttributes: async (thingId, setSize, setRadio, setUnit) => {
        let requestOptions = {
            url: `/v2/things/${thingId}/custom_fields`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response);

                if (setSize && setRadio && setUnit) {
                    if (response) {
                        response.forEach((r) => {
                            const { name, value } = r;

                            if (name.includes("size")) {
                                setSize(value);
                            } else if (name.includes("radio")) {
                                setRadio(value.toLowerCase() === "true");
                            } else if (name.includes("unit")) {
                                setUnit(value);
                            }
                        });
                    }
                } else {
                    return response;
                }
            })
            .catch((e) => {
                console.error(e);
            });
    },

    updateWaterMeteringAttributes: async (thingId, list) => {
        let requestOptions = {
            url: `/v2/things/${thingId}/custom_fields`,
            data: list.map((e) => {
                e.name = "watermeter_" + e.name;
                return e;
            }),
        };

        // console.log(requestOptions);
        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    associateThing: async (thingId, productId) => {
        let requestOptions = {
            url: `/api/things/${thingId}/product`,
            data: {
                id: productId,
            },
        };

        // console.log(requestOptions);
        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    updateFavoriteAttribute: async (star, cognitoUsername, selectedAttributeId, allAttributes, selectedThingId) => {
        let attributes = [];
        if (selectedAttributeId == "all") {
            allAttributes
                .filter((a) => a.id != "all")
                .forEach((a) => {
                    attributes.push(a.id);
                });
        } else {
            attributes.push(selectedAttributeId);
        }

        let requestOptions = {
            url: `/api/favorite/${star ? "delete" : "add"}`,
            data: {
                username: cognitoUsername,
                attributes: attributes,
                thingId: selectedThingId,
            },
        };

        // console.log(requestOptions)

        if (star) {
            return ApiService.deleteData(requestOptions)
                .then((r) => console.log(r))
                .catch((e) => console.error(e));
        } else {
            return ApiService.putData(requestOptions)
                .then((r) => console.log(r))
                .catch((e) => console.error(e));
        }
    },

    requestFavoriteAttribute: async (cognitoUsername, selectedThingId, selectedAttributeId, setStar) => {
        if (selectedAttributeId) {
            let requestOptions = {
                url: `/api/favorite/${cognitoUsername}/${selectedThingId}/${selectedAttributeId}/exist`,
            };

            // console.log(requestOptions)
            return ApiService.getData(requestOptions).then((r) => {
                // console.log(r);
                setStar(r.isExist);
            });
        } else {
            let requestOptions = {
                url: `/api/favorite/${cognitoUsername}/${selectedThingId}`,
            };

            // console.log(requestOptions)
            return ApiService.getData(requestOptions).then((r) => r);
        }
    },

    requestOperations: async (modelId, setOperations) => {
        let requestOptions = {
            url: `/api/models/${modelId}/operations`,
        };

        let result = await ApiService.getData(requestOptions);
        // console.log(result);
        if (result && result.data) {
            setOperations(result.data);
        }
    },

    sendDownlink: async (thingId, operationId, opName) => {
        let requestOptions = {
            url: `/api/things/${thingId}/operations/${operationId}`,
            data: {
                opName: opName,
            },
        };

        // console.log(requestOptions);
        return ApiService.postData(requestOptions)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestLastState: async (selectedThingId, setValue) => {
        let requestOptions = {
            url: `/api/measures/last_state`,
            params: {
                thingId: selectedThingId,
            },
        };

        const result = await ApiService.getData(requestOptions);
        setValue(result.value || null);
    },

      updateSerialNumber: async (thingId, serial) => {
        let requestOptions = {
            url: "/v2/things/serial_number",
            data: {
                thingId: thingId,
                serialNumber: serial,
            },
        };

        return ApiService.putData(requestOptions);
    },

    requestDisplayThing: async (selectedThingId, setValue) => {
        let requestOptions = {
            url: `/v2/things/${selectedThingId}/display_thing`,
        };

        const result = await ApiService.getData(requestOptions);
        // console.log(result);
        setValue(result);
    },

    disassociateDisplayThing: async(meterDisplayId) => {
        let requestOptions = {
            url: `/v2/things/${meterDisplayId}/disassociate`, 
        };

        return ApiService.deleteData(requestOptions);
    },

    requestGatewayStatus: async (thingId) => {
        let requestOptions = {
            url: `/api/gateways/last/${thingId}`,
            // url: `/api/gateways/last/2bd20308-5ebc-44b5-b596-c8398d85af1e`,
        };

        // console.log(requestOptions)
        return ApiService.getData(requestOptions).then((response) => {
            // console.log(response);
            return response;
        });
    },
};

export default DetailService;
