import React, { useEffect, useContext, useState } from "react";
import AppContext from "Components/AppContext";
import SettingContext from "Providers/SettingContext";
import ApplicationData, { AppId } from "Components/applications/ApplicationData";
import Alerts from "Components/alerts/Alerts";
import _ from "underscore";
import { Box } from "@mui/material";
import UserService from "Services/userService";

export default function EmeraAlerts(props) {
    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);
    const [companyId, setCompanyId] = useState();

    useEffect(() => {
        setting.setGlobalSetting({
            appId: AppId.EMERA_V2,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });
        ctx.updateTitle(ApplicationData.find((a) => a.id == AppId.EMERA_V2).title);

        UserService.getAuthenticatedUser().then((user) => {
            // console.log(user);
            setCompanyId(user.companyId);
        });

        return () => {
            ctx.updateTitle(null);
        };
    }, []);

    return <Box sx={{ mt: "76px", pl: 2, pr: 2 }}>{companyId && <Alerts {...props} companyId={companyId} />}</Box>;
}
