import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { CustomButton } from "Components/ui/CustomButton";
import RoomService from "./RoomService";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import validator from "validator";
import { useCallback } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import CardService from "./CardService";
import SiteSelector from "./SiteSelector";

export default function CommonSetting({ open, setOpen, thingId }) {
    const { t } = useTranslation();

    const [thing, setThing] = useState(null);
    const [enableEmail, setEnableEmail] = useState(false);
    const [enableSms, setEnableSms] = useState(false);
    const [fixedName, setFixedName] = useState("");
    const [reload, setReload] = useState(false);

    useEffect(() => {
        let isMounted = true;

        if (thingId) {
            CardService.requestThing(thingId).then((r) => {
                if (isMounted) {
                    // console.log("r", r);
                    setThing(r);
                    setFixedName(r.fixedName);

                    r.customFields?.forEach((customField) => {
                        if (customField.name === "NOTIFICATIONS") {
                            const notifications = JSON.parse(customField.value);
                            setEnableEmail(notifications.enableEmail);
                            setEnableSms(notifications.enableSms);
                        }
                    });
                }
            });
        }

        return () => {
            isMounted = false;
        };
    }, [open, thingId, reload]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOk = () => {
        // console.log("handleSave", delayThreshold);
        // RoomService.saveNotifications(thingId, enableEmail, enableSms).then(() => setOpen(false));
        setOpen(false);
    };

    const handleEmailNotifyChange = (e) => {
        setEnableEmail(e.target.checked);
        RoomService.saveNotifications(thingId, e.target.checked, enableSms).then(() => {
            // if (setOpen) setOpen(false);
        });
    };

    const handleSmsNotifyChange = (e) => {
        setEnableSms(e.target.checked);
        RoomService.saveNotifications(thingId, enableEmail, e.target.checked).then(() => {
            // if (setOpen) setOpen(false);
        });
    };

    const debounceChange = useCallback(
        debounce((thingId, fixedName) => {
            // console.log("debounceChange", thingId, fixedName);
            if (validator.isEmpty(fixedName)) {
                return;
            }
            RoomService.updateName(thingId, fixedName).then((r) => {
                // console.log("r", r);
                // if (callback) {
                //     callback();
                // }
            });
        }, 500),
        []
    );

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
            <DialogTitle id="form-dialog-title">
                Setup
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{ position: "absolute", right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ flexGrow: 1, m: 2 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sx={{ display: "flex", alignItems: "center", flexDirection: "row", gap: 2 }}>
                            <Box
                                component="img"
                                sx={{
                                    width: 100,
                                    maxHeight: 100,
                                    objectFit: "contain",
                                }}
                                src={`/static/catalog/products/${thing?.product?.id}/image.png`}
                                alt="Product"
                            />
                            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", gap: 2, width: "100%" }}>
                                <Typography variant="body1">
                                    {thing?.product?.manufacturer?.name} - {thing?.product?.name}
                                </Typography>
                                <Typography variant="body1">{thing?.product?.connectivityTypes?.join(", ")}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="body1">{t("col.site")}</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <SiteSelector selectedSiteId={thing?.site?.id} company={thing?.company} setReload={setReload} thingId={thingId} />
                        </Grid>
                        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="body1">{t("col.name")}</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                label={""}
                                value={fixedName || ""}
                                onChange={(e) => {
                                    setFixedName(e.target.value);
                                    debounceChange(thingId, e.target.value);
                                }}
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="body1">Notifications</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Stack direction="row" spacing={2}>
                                <FormControlLabel
                                    control={<Checkbox checked={enableEmail} onChange={handleEmailNotifyChange} />}
                                    label="Email"
                                    sx={{ ml: 0 }}
                                />
                                <FormControlLabel control={<Checkbox checked={enableSms} onChange={handleSmsNotifyChange} />} label="SMS" sx={{ ml: 0 }} />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <CustomButton name="OK" callback={handleOk} />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
