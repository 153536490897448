import dayjs from "dayjs";

export const getChartOptions = ({ chartDataGal }, t) => ({
    title: {
        text: undefined,
    },
    xAxis: {
        type: "category",
        title: {
            text: undefined,
        },
        // labels: {
        //     format: "{value:%H:%M}",
        // },
        categories: chartDataGal.categories,
    },
    yAxis: [
        {
            title: {
                text: t("wm.consumption") + " (gal)",
            },
        },
    ],
    tooltip: {
        formatter: function () {
            return `<b>${this.y} ${this.series.yAxis.options.title.text.match(/\((.*?)\)/)[1]}</b>`;
        },
    },
    series: [
        {
            name: "Min",
            data: chartDataGal.min,
            type: "column",
            yAxis: 0,
            showInLegend: true,
        },
        {
            name: "Avg",
            data: chartDataGal.avg,
            type: "column",
            yAxis: 0,
            showInLegend: true,
        },
        {
            name: "Max",
            data: chartDataGal.max,
            type: "column",
            yAxis: 0,
            showInLegend: true,
        },
    ],
    credits: {
        enabled: false,
    },
    exporting: {
        enabled: true,
        buttons: {
            contextButton: {
                symbol: "download",
            },
        },
    },
});
