import { Grid, FormControlLabel, Checkbox, TextField } from "@mui/material";
import CustomStyle from "Components/CustomStyle";
import ShareService from "./ShareService";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";

export default function MessageFormatPanel({
    chkMessage: initialChkMessage,
    chkThing: initialChkThing,
    chkProduct: initialChkProduct,
    chkGateway: initialChkGateway,
    callback,
}) {
    const [txtMessageFormat, setTxtMessageFormat] = useState();
    const [chkMessage, setChkMessage] = useState(initialChkMessage);
    const [chkThing, setChkThing] = useState(initialChkThing);
    const [chkProduct, setChkProduct] = useState(initialChkProduct);
    const [chkGateway, setChkGateway] = useState(initialChkGateway);

    useEffect(() => {
        // console.log("MessageFormat, initialChkMessage", initialChkMessage);
        // console.log("MessageFormat, chkMessage", chkMessage);

        ShareService.requestMessageFormat("HTTP", setTxtMessageFormat, [chkMessage, chkThing, chkProduct, chkGateway]);
    }, [chkMessage, chkThing, chkProduct, chkGateway]);

    // Update local state when props change
    useEffect(() => {
        setChkMessage(initialChkMessage);
        setChkThing(initialChkThing);
        setChkProduct(initialChkProduct);
        setChkGateway(initialChkGateway);
    }, [initialChkMessage, initialChkThing, initialChkProduct, initialChkGateway]);

    const handleMsgFormat = (e) => {
        const value = e.target.checked;
        if (e.target.name === "chkMessage") {
            setChkMessage(value);
        } else if (e.target.name === "chkThing") {
            setChkThing(value);
        } else if (e.target.name === "chkProduct") {
            setChkProduct(value);
        } else if (e.target.name === "chkGateway") {
            setChkGateway(value);
        }
        // console.log("target", e.target.name, "value", value);
        callback(e);
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    <FormControlLabel control={<Checkbox name="chkMeasurement" defaultChecked disabled />} label="Measurement" />
                    <FormControlLabel control={<Checkbox name="chkMessage" checked={chkMessage} onClick={handleMsgFormat} />} label="Message" />
                    <FormControlLabel control={<Checkbox name="chkThing" checked={chkThing} onClick={handleMsgFormat} />} label="Thing" />
                    <FormControlLabel control={<Checkbox name="chkProduct" checked={chkProduct} onClick={handleMsgFormat} />} label="Product" />
                    <FormControlLabel control={<Checkbox name="chkGateway" checked={chkGateway} onClick={handleMsgFormat} />} label="Gateway" />
                </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField
                    required
                    name="txtMessageFormat"
                    fullWidth
                    margin="dense"
                    multiline
                    rows={15}
                    value={txtMessageFormat}
                    sx={CustomStyle.RemoveLabel}
                />
            </Grid>
        </Grid>
    );
}
