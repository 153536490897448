import { useState } from "react";
import moment from "moment-timezone";
import UtilService from "Services/utilService";
import { ChartLangOption } from "../../../components/chart/ChartLangOption";

export function ChartPirOptions(series, t, i18n) {
    const [groupEnable, setGroupEnable] = useState(true);

    // console.log("Chart series data:", series);
    // console.log("Chart data being passed to chart:", series.chartData);

    const langSettings = ChartLangOption(i18n);

    return {
        chart: {
            type: "xrange",
        },
        title: {
            text: null,
        },
        xAxis: {
            type: "datetime",
            ordinal: false,
        },
        yAxis: {
            title: {
                text: "",
            },
            categories: ["Idle", "Trigger"],
            reversed: false,
            opposite: false,
        },
        tooltip: {
            shared: true,
            split: false,
            formatter: function () {
                let result = "";

                this.points.forEach((point) => {
                    let x = point.x;
                    let x2 = point.x2;
                    let status = point.y === 0 ? "Idle" : "Triger";

                    let start = UtilService.formatMDHms(x);
                    let end = UtilService.formatMDHms(x2);
                    let duration = UtilService.toDuration(x2 - x);

                    result += `<br/><b>${status}</b><br/>${start} - ${end}<br/>Duration: ${duration}`;
                });

                return result;
            },
        },
        credits: {
            enabled: false,
        },
        time: {
            timezone: moment.tz.guess(),
            useUTC: false,
        },
        series: [
            {
                name: "Status",
                borderColor: "gray",
                pointWidth: 20,
                data: series.chartData,
                dataLabels: {
                    enabled: true,
                },
            },
        ],
        rangeSelector: {
            buttons: [
                {
                    type: "day",
                    count: 1,
                    text: "1d raw",
                    events: {
                        click: function () {
                            setGroupEnable(false);
                        },
                    },
                },
                {
                    type: "week",
                    count: 1,
                    text: "1w",
                    events: {
                        click: function () {
                            setGroupEnable(true);
                        },
                    },
                },
                {
                    type: "month",
                    count: 1,
                    text: "1m",
                    events: {
                        click: function () {
                            setGroupEnable(true);
                        },
                    },
                },
                {
                    type: "all",
                    text: "All",
                    events: {
                        click: function () {
                            setGroupEnable(true);
                        },
                    },
                },
            ],
            selected: 4,
            inputEnabled: true,
        },
        lang: langSettings,
    };
}
