import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import ShareService from "./ShareService";
import DeviceExplorerConstant from "./DeviceExplorerConstant";
import Item from "../../../components/ui/Item";

export default function ButtonSwitch({
    forwardKey = '',
    name = '',
    checked = false,
    disableSwitch = false,
    companyName = '',
    clickButton = () => {},
    clickSwitch = () => {}
} = {}) {
    const [on, setOn] = useState(false);
    const [lastSentTimestamp, setLastSentTimestamp] = useState();

    const handleButton = (e) => {
        clickButton(forwardKey);
    };

    const handleSwitch = (e) => {
        clickSwitch(name, e.target.checked);
        setOn(e.target.checked);
    };

    useEffect(() => {
        setOn(checked);

        ShareService.requestLastSentTimestamp(DeviceExplorerConstant.toScope(forwardKey), setLastSentTimestamp);
    }, [checked, companyName, forwardKey]);

    return (
        <>
            <FormGroup>
                <Stack spacing={0}>
                    <Item>
                        <Button
                            variant="contained"
                            onClick={(e) => handleButton(e)}
                            fullWidth
                            sx={{ backgroundColor: "#5694B5", color: "#FFFFFF", textTransform: "none" }}
                        >
                            {name}
                        </Button>
                    </Item>
                    <Item style={{ width: "120px", display: "flex", justifyContent: "center" }}>
                        <FormControlLabel
                            control={<Switch checked={on} size="small" onChange={(e) => handleSwitch(e)} disabled={disableSwitch} />}
                            label={on ? "ON" : "OFF"}
                            labelPlacement="start"
                            sx={{
                                m: 0,
                                p: 0,
                                "& .MuiSwitch-switchBase.Mui-checked": {
                                    color: "#5694B5",
                                },
                            }}
                        />
                    </Item>
                    {lastSentTimestamp && <Item>{lastSentTimestamp}</Item>}
                </Stack>
            </FormGroup>
        </>
    );
}
