import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "underscore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ConfirmDialog from "./ConfirmDialog";
import ShareService from "./ShareService";
import CustomStyle from "Components/CustomStyle";
import MessageFormatPanel from "./MessageFormatPanel";
import Checkbox from "@mui/material/Checkbox";

export default function SetupSQLserver({ setActiveStep, goCompletePage, goFirstPage, on, userCompany }) {
    let history = useHistory();
    let errors = {
        hostname: false,
        user: false,
        password: false,
        database: false,
        description: false,
        message: "",
    };

    const [companyName, setCompanyName] = useState(userCompany);

    const [flowId, setFlowId] = useState();
    const [forwardTargetId, setForwardTargetId] = useState();

    const [hostname, setHostname] = useState();
    const [user, setUser] = useState();
    const [password, setPassword] = useState();
    const [database, setDatabase] = useState();
    const [description, setDescription] = useState();
    const [enabled, setEnabled] = useState(false);

    const [chkMessage, setChkMessage] = useState(false);
    const [chkThing, setChkThing] = useState(false);
    const [chkProduct, setChkProduct] = useState(false);
    const [chkGateway, setChkGateway] = useState(false);

    useEffect(() => {
        setActiveStep(1);

        ShareService.getFlowTargetSQLserver(
            companyName,
            setFlowId,
            setForwardTargetId,
            setHostname,
            setUser,
            setPassword,
            setDatabase,
            setEnabled,
            setChkMessage,
            setChkThing,
            setChkProduct,
            setChkGateway
        );

        setDescription(ShareService.DESC_SQL_SERVER);
    }, [userCompany, companyName]);

    const handleSave = () => {
        ShareService.getFlowTargetSQLserver(
            companyName,
            setFlowId,
            setForwardTargetId,
            setHostname,
            setUser,
            setPassword,
            setDatabase,
            setEnabled,
            setChkMessage,
            setChkThing,
            setChkProduct,
            setChkGateway
        ).then(() => {
            if (!forwardTargetId) {
                // create a flow, enabled=false
                ShareService.createFlow(companyName, "Share-SQLserver").then((flowId) => {
                    // Insert SQLserver integration, enabled=false
                    ShareService.insertForwardSQLserver(hostname, user, password, database, [chkMessage, chkThing, chkProduct, chkGateway], enabled).then(
                        (savedForwardTargetId) => {
                            // Insert Action
                            setForwardTargetId(savedForwardTargetId);
                            ShareService.createAction(flowId, savedForwardTargetId).then(() => goCompletePage());
                        }
                    );
                });
            } else {
                // Update configuration
                ShareService.updateForwardSQLserver(forwardTargetId, hostname, user, password, database, [chkMessage, chkThing, chkProduct, chkGateway], enabled).then(
                    () => goCompletePage()
                );
            }
        });
    };

    const handleMsgFormat = (e) => {
        const value = e.target.checked;
        if (e.target.name === "chkMessage") {
            setChkMessage(value);
        } else if (e.target.name === "chkThing") {
            setChkThing(value);
        } else if (e.target.name === "chkProduct") {
            setChkProduct(value);
        } else if (e.target.name === "chkGateway") {
            setChkGateway(value);
        }
    };

    const handleValue = (e) => {
        const value = e.target.value;

        if (e.target.name == "txtUrl") {
            setHostname(value);
        } else if (e.target.name == "txtUser") {
            setUser(value);
        } else if (e.target.name == "txtPassword") {
            setPassword(value);
        } else if (e.target.name == "txtDatabase") {
            setDatabase(value);
        } else if (e.target.name == "cboEnabled") {
            setEnabled(e.target.checked);
        }
    };

    const closeDialog = (answer) => {
        if (answer) {            
            // Set false the forward target
            ShareService.setForwardTargetEnabled(forwardTargetId, false).then(() => setEnabled(false));
        } else {
            goFirstPage();
            
        }
    };

    return (
        <>
            <Paper elevation={6} spacing={2}>
                {on && <ConfirmDialog callback={closeDialog}></ConfirmDialog>}

                <Grid container padding={2}>
                    <Grid item xs={6} sm={6}>
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" style={{ backgroundColor: "#9CC96B" }} onClick={() => handleSave()}>
                                Save
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>

                <Accordion defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                        <Typography>Connection</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={2} sm={2}>
                                <Typography>Hostname</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtUrl"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={hostname}
                                    onChange={(e) => handleValue(e)}
                                    error={errors.hostname ? true : false}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>User</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtUser"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={user}
                                    onChange={(e) => handleValue(e)}
                                    error={errors.user ? true : false}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>Password</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtPassword"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={password}
                                    onChange={(e) => handleValue(e)}
                                    error={errors.password ? true : false}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>Database name</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtDatabase"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={database}
                                    onChange={(e) => handleValue(e)}
                                    error={errors.database ? true : false}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>Description</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtDescription"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    multiline
                                    minRows={4}
                                    value={description}
                                    disabled
                                    // onChange={(e) => handleValue(e)}
                                    error={errors.description ? true : false}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>Enable</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <Checkbox name="cboEnabled" checked={enabled} onChange={(e) => handleValue(e)} />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                        <Typography>Message format</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <MessageFormatPanel
                            chkMessage={chkMessage}
                            chkThing={chkThing}
                            chkProduct={chkProduct}
                            chkGateway={chkGateway}
                            callback={handleMsgFormat}
                        />
                    </AccordionDetails>
                </Accordion>
            </Paper>
        </>
    );
}
