import React from 'react';
import { Box, Typography } from '@mui/material';

const HCHO_THRESHOLDS = {
    excellent: 0,      // Air très sain
    good: 0.05,       // Bon
    moderate: 0.1,    // Modéré (norme WHO)
    poor: 0.3,        // Médiocre
    bad: 0.5,         // Mauvais
    max: 1.0         // Très mauvais
};

const getHCHOLabel = (value) => {
    if (value <= HCHO_THRESHOLDS.excellent) return 'Excellent';
    if (value <= HCHO_THRESHOLDS.good) return 'Bon';
    if (value <= HCHO_THRESHOLDS.moderate) return 'Modéré';
    if (value <= HCHO_THRESHOLDS.poor) return 'Médiocre';
    if (value <= HCHO_THRESHOLDS.bad) return 'Mauvais';
    return 'Dangereux';
};

const IAQHCHO = ({ hcho, formatValue }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flex: 1,
            width: '100%',
            gap: 2
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 0.5
            }}>
                <Typography sx={{
                    fontSize: '3rem',
                    fontWeight: 'bold',
                    color: 'black'
                }}>
                    {formatValue(hcho)} mg/m³
                </Typography>
                
                <Typography sx={{
                    fontSize: '1.2rem',
                    color: 'text.secondary',
                    fontWeight: 500
                }}>
                    {getHCHOLabel(parseFloat(hcho))}
                </Typography>
            </Box>

            <Box sx={{ 
                position: 'relative',
                width: '80%',
                height: '40px',
                bgcolor: 'rgba(0, 0, 0, 0.05)',
                borderRadius: 2,
                background: `linear-gradient(to right, #00ff00, #ffff00, #ffa500, #ff0000)`
            }}>
                <Box sx={{
                    position: 'absolute',
                    left: `${((parseFloat(hcho)) / HCHO_THRESHOLDS.max) * 100}%`,
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    bgcolor: 'black',
                    border: '2px solid white',
                    boxShadow: 1,
                    zIndex: 1
                }} />
            </Box>

            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                width: '80%',
                fontSize: '0.75rem',
                color: 'text.secondary'
            }}>
                {Object.entries(HCHO_THRESHOLDS).map(([level, value]) => (
                    <Typography key={level} variant="caption">
                        {value}
                    </Typography>
                ))}
            </Box>
        </Box>
    );
};

export default IAQHCHO;