import React, { useEffect, useState, useContext } from "react";
import { Box, Typography, CircularProgress, Grid, IconButton, Fab, Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SensorDetailService from "Components/chart/SensorDetailService";
import AppContext from "Components/AppContext";
import SettingContext from "Providers/SettingContext";
import ApplicationData, { AppId } from "Components/applications/ApplicationData";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SiteDialogue from "Components/site/SiteDialogue";
import dayjs from "dayjs";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fr';
import ElecChartStacked from './ElecChartStacked';
import BarChartIcon from '@mui/icons-material/BarChart';

const MAX_VALUE = Math.floor(Math.random() * (1200 - 800 + 1)) + 800;
const VALUES = Array(10).fill(0).map((_, index) => {
    const decrease = (MAX_VALUE * (index * 0.1));
    return Math.max(MAX_VALUE - decrease, 100);
});

// Modifier la fonction generateMonthData pour stocker la valeur maximale
const generateMonthData = (date) => {
    const daysInMonth = date.daysInMonth();
    const data = Array(daysInMonth).fill(0).map((_, index) => ({
        day: index + 1,
        value: Math.random() > 0.3 ? Math.floor(Math.random() * (1200 - 800 + 1)) + 800 : 0
    }));
    
    const maxValue = Math.max(...data.map(d => d.value));
    
    return data.map(d => ({
        ...d,
        isMax: d.value === maxValue && d.value > 0,
        maxValue: maxValue // Stocker la valeur maximale pour le calcul des hauteurs
    }));
};

// Modifier la constante CHART_CONTAINER_STYLE
const CHART_CONTAINER_STYLE = {
    width: '100%',
    height: '400px',
    position: 'relative',
    display: 'flex',
    flex: 1  // Ajout pour prendre tout l'espace disponible
};

/**
 * ElectricityCardDetails Component
 * 
 * This page displays the details of an electrical sensor.
 * Main features:
 * - URL parameters retrieval (thingId, attributeId)
 * - Sensor name display
 * - Loading error handling
 * 
 * @param {string} thingId - Connected object ID
 * @param {string} attributeId - Attribute ID to display
 */
export default function ElectricityCardDetailsMain() {
    const { t } = useTranslation();
    const { thingId, attributeId } = useParams();
    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);
    
    const [loading, setLoading] = useState(true);
    const [thing, setThing] = useState(null);
    const [error, setError] = useState(null);
    const [openSite, setOpenSite] = useState(false);
    const [reload, setReload] = useState();
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [showGraph, setShowGraph] = useState(false);
    const [monthData, setMonthData] = useState(generateMonthData(selectedDate));
    const [hoveredBar, setHoveredBar] = useState(null);

    useEffect(() => {
        const initializeApp = () => {
            setting.setGlobalSetting({ appId: AppId.EMERA_V2 });
            ctx.updateTitle(ApplicationData.find((a) => a.id === AppId.EMERA_V2).title);
        };

        initializeApp();
    }, []); // Exécuter une seule fois au montage

    useEffect(() => {
        const fetchThingDetails = async () => {
            if (!thingId) {
                setLoading(false);
                setError(t('common.error.no_thing_id'));
                return;
            }
            try {
                setLoading(true);
                const response = await SensorDetailService.requestThing(thingId);
                setThing(response);
            } catch (err) {
                console.error('Error fetching thing details:', err);
                setError(t('common.error.loading'));
            } finally {
                setLoading(false);
            }
        };

        fetchThingDetails();
    }, [thingId, t]); // Dépendances réduites

    useEffect(() => {
        setMonthData(generateMonthData(selectedDate));
    }, [selectedDate]);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }
    if (error) {
        return (
            <Box sx={{ p: 2 }}>
                <Typography color="error">
                    {error}
                </Typography>
            </Box>
        );
    }
    /* ------------------- MAIN PAGE ------------------*/
    return (
        <Box sx={{ pt: 10, ml: 2, mr: 2 }}>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} style={{ flexGrow: "1", display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Typography 
                        variant="h4"
                        sx={{ 
                            flexGrow: 1,
                            color: "text.secondary"
                        }}
                    >
                        {thing?.fixedName} ({thingId})
                    </Typography>
                    <Box
                        sx={{
                            backgroundColor: '#70AD47',
                            color: 'white',
                            px: 2,
                            py: 0.5,
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: 500,
                            ml: 2
                        }}
                    >
                        Primaire
                    </Box>
                    
                </Grid>
            </Grid>
            <Box sx={{ mt: 4, mb: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
                <Typography 
                    variant="h5"
                    sx={{ 
                        color: "text.secondary",
                        fontWeight: 500
                    }}
                >
                    Maximums journaliers
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                    <DatePicker
                        views={['month', 'year']}
                        label="Mois"
                        value={selectedDate}
                        onChange={(newValue) => setSelectedDate(newValue)}
                        slotProps={{
                            textField: {
                                size: "small",
                                sx: { width: 200 }
                            }
                        }}
                    />
                </LocalizationProvider>
            </Box>

            <Box sx={{ position: 'relative', mt: 2 }}>
                <Paper elevation={3} sx={{ p: 2 }}> {/* Ajout du padding de 16px (p: 2 = 16px dans MUI) */}
                    {showGraph ? (
                        <Box sx={CHART_CONTAINER_STYLE}>
                            <ElecChartStacked selectedDate={selectedDate} />
                            <Fab
                                color="primary"
                                size="small"
                                onClick={() => setShowGraph(false)}
                                sx={{
                                    position: 'absolute',
                                    top: -8, // Ajusté pour tenir compte du padding
                                    left: -8, // Ajusté pour tenir compte du padding
                                    backgroundColor: '#70AD47',
                                    '&:hover': {
                                        backgroundColor: '#5B8C3A'
                                    }
                                }}
                            >
                                <BarChartIcon />
                            </Fab>
                        </Box>
                    ) : (
                        <Box sx={{
                            ...CHART_CONTAINER_STYLE
                        }}>
                            {/* Conteneur des barres */}
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "flex-end",
                                    width: "100%",
                                    height: "100%", // Prendre toute la hauteur disponible
                                    justifyContent: "stretch"
                                }}
                            >
                                {monthData.map((data, index) => (
                                    <Box
                                        key={index}
                                        onClick={() => data.value > 0 && setShowGraph(true)}
                                        onMouseEnter={() => setHoveredBar(index)}
                                        onMouseLeave={() => setHoveredBar(null)}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            position: 'relative',
                                            cursor: data.value > 0 ? 'pointer' : 'default',
                                            flex: 1,
                                            minWidth: 0,
                                        }}
                                    >
                                        {data.value > 0 && hoveredBar === index && (
                                            <Typography
                                                sx={{
                                                    fontSize: "10px",
                                                    color: "black",
                                                    whiteSpace: "nowrap",
                                                    height: "14px",
                                                    mb:1
                                                }}
                                            >
                                                {data.value >= 1000 
                                                    ? `${(data.value / 1000).toFixed(1)} kW`
                                                    : `${data.value.toFixed(1)} W`
                                                }
                                            </Typography>
                                        )}
                                        
                                        <Box
                                            sx={{
                                                width: 10,
                                                // Ajuster la hauteur maximale à 90%
                                                height: data.value > 0 ? `${(data.value / data.maxValue) * 320}px` : '0px', // 360px = 90% de 400px
                                                backgroundColor: data.value > 0 
                                                    ? (data.isMax ? '#FF0000' : '#70AD47') 
                                                    : 'transparent',
                                                borderRadius: 50,
                                                transition: "background-color 0.3s",
                                                "&:hover": {
                                                    backgroundColor: data.value > 0 
                                                        ? (data.isMax ? '#DD0000' : '#5B8C3A') 
                                                        : 'transparent'
                                                }
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                color: "text.secondary",
                                                mt: 1
                                            }}
                                        >
                                            {data.day}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    )}
                </Paper>
            </Box>

            <SiteDialogue
                open={openSite}
                setOpen={setOpenSite}
                setReloadParent={() => {
                    setReload(dayjs());
                }}
            />
        </Box>
    );
}