import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import style from "./style.module.css";
import IconButton from "@mui/material/IconButton";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import CardService from "./CardService";
import { useState } from "react";
import AttributeWidget from "../../../components/widgets/AttributeWidget";
import Tooltip from "@mui/material/Tooltip";

export default function RoomCard({ asset, entity }) {
    const { t, i18n } = useTranslation();
    let history = useHistory();
    // const [moyenne, setMoyenne] = useState(false);

    const [humidity, setHumidity] = useState();
    const [temperature, setTemperature] = useState();
    const [co2, setCo2] = useState();
    const [siteId, setSiteId] = useState(null);
    const [siteName, setSiteName] = useState(null);
    const [battery, setBattery] = useState(100);
    const [fixedName, setFixedName] = useState(null);

    useEffect(() => {
        // console.log("uef in RoomCard");
        // console.log(asset);

        if (!asset.assetAttribute || asset.assetAttribute.length === 0) {
            return;
        }

        // console.log(asset.assetAttribute.find((a) => a.thing.id).thing.id);
        // console.log(asset.assetAttribute.map((a) => a.attribute.id));

        const thingId = asset.assetAttribute.find((a) => a?.thing.id).thing.id;
        const attributeIds = asset.assetAttribute.map((a) => a.attribute.id);

        CardService.requestLastMeasures(thingId, attributeIds).then((r) => {
            // console.log(r);
            r.forEach((ele) => {
                // console.log(ele);

                let element = {
                    thingId: ele.thingId,
                    attributeId: ele.attribute.id,
                    value: ele.value,
                    unit: ele.attribute.unit,
                    date: ele.timestamp / 1000,
                };

                switch (ele.attribute.name) {
                    case "humidity":
                        setHumidity(element);
                        break;
                    case "temperature":
                        setTemperature(element);
                        break;
                    case "co2":
                        setCo2(element);
                        break;
                    default:
                        break;
                }

                setFixedName(ele.fixedName);
            });

            // console.log(asset.site);
            setSiteId(asset.site ? asset.site.id : "");
            setSiteName(asset.site ? asset.site.name : "");
        });
    }, [asset]);

    const handleButton = (thingId, attributeId) => {
        if (!thingId && !attributeId) {
            return;
        }
        history.push(`${process.env.PUBLIC_URL}/emera/v2/chart/${thingId}/${attributeId}`);
    };

    const handleFloor = (floor) => {
        history.push(`${process.env.PUBLIC_URL}/emera/v2/floor/${siteId}`);
    };

    return (
        <Box sx={{ width: "300px", height: "300px" }}>
            <Paper elevation={1} sx={{ borderRadius: "15px", p: 2 }}>
                <Grid container sx={{ height: "100%" }}>
                    {/* Room name */}
                    <Grid item xs={7} sx={{ height: "30px" }}>
                        <span>{fixedName?.length > 20 ? `${fixedName.slice(0, 20)}...` : fixedName}</span>
                    </Grid>

                    {/* Battery icon and Calendar icon */}
                    <Grid item xs={5} sx={{ display: "flex", justifyContent: "flex-end", height: "30px" }}>
                        <Tooltip title={`${battery}%`} arrow>
                            <IconButton
                                aria-label="btn-battery"
                                sx={{
                                    "&.MuiButtonBase-root:hover": {
                                        cursor: "default",
                                    },
                                }}
                                size="small"
                            >
                                <i className="fa fa-battery-full" aria-hidden="true"></i>
                            </IconButton>
                        </Tooltip>
                    </Grid>

                    <Grid item xs={12}>
                        <AttributeWidget
                            variant="co2"
                            title="Co2"
                            entity={co2}
                            thingId={co2 ? co2.thingId : undefined}
                            attributeId={co2 ? co2.attributeId : undefined}
                            callback={handleButton}
                        />
                    </Grid>

                    {/* Temperature */}
                    <Grid item xs={6}>
                        <AttributeWidget
                            variant="temperature"
                            title={t("emera_v2.room_temperature")}
                            entity={temperature}
                            thingId={temperature ? temperature.thingId : undefined}
                            attributeId={temperature ? temperature.attributeId : undefined}
                            callback={handleButton}
                        />
                    </Grid>

                    {/* humidity */}
                    <Grid item xs={6}>
                        <AttributeWidget
                            variant="humidity"
                            title={t("emera_v2.room_humidity")}
                            entity={humidity}
                            thingId={humidity ? humidity.thingId : undefined}
                            attributeId={humidity ? humidity.attributeId : undefined}
                            callback={handleButton}
                        />
                    </Grid>

                    <Grid item xs={12} sx={{ display: "flex", flexGrow: 1, alignItems: "flex-end" }}>
                        <span className={style.box} onClick={() => handleFloor(entity.floor)}>
                            {siteName}
                        </span>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}
