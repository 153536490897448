import { useState, useEffect } from "react";
import _ from "underscore";
import style from "./style.module.css";
import * as React from "react";
import CatalogService from "./CatalogService";
import { useTranslation } from "react-i18next";
import { frFR } from "@mui/x-data-grid/locales";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("53264e234090f26bf650c109bcf9fc2fTz0xMDUxMDMsRT0xNzY5Mjg1NDAxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

export default function ManufacturerList(props) {
    const { t, i18n } = useTranslation();
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 100,
    });

    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);

    useEffect(() => {
        CatalogService.requestManufacturers(paginationModel.page, paginationModel.pageSize, setRows, setRowCount, props.keyword);
    }, [paginationModel, props.keyword]);

    const handleRowClick = (params) => {
        let selectedManufactuer = rows.find(function (el) {
            return el.id == params.id;
        });
        props.callback(selectedManufactuer);
    };

    const columns = [
        {
            field: "id",
            headerName: t("col.id"),
            flex: 1,
            editable: false,
            sortable: false,
        },
        {
            field: "logo",
            headerName: t("col.logo"),
            flex: 0.3,
            editable: false,
            sortable: false,
            align: "center",
            renderCell: (params) => {
                return (
                    // <img width="auto" height="auto" class={style.manufacturer_image} src={imgFront} />
                    <img
                        width="auto"
                        height="auto"
                        className={style.manufacturer_image}
                        src={`/static/catalog/manufacturers/${params.id}/image.png`}
                        alt="Manufacturer"
                    />
                );
            },
            headerAlign: "center",
        },
        {
            field: "name",
            headerName: t("col.name"),
            flex: 0.7,
            editable: false,
            sortable: false,
            headerAlign: "center",
        },
    ];

    return (
        <DataGridPro
            columns={columns}
            rows={rows}
            disableColumnFilter={true}
            disableColumnMenu={true}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            rowCount={rowCount}
            pagination={true}
            paginationMode="server"
            sortingMode="server"
            pageSizeOptions={[100]}
            // onRowSelectionModelChange={(newSelection) => handleSelectionModel(newSelection)}
            density="comfortable"
            // onCellClick={handleCellClick}
            disableSelectionOnClick
            style={{ height: "calc(100vh - 160px)" }}
            headerHeight={0}
            onRowClick={handleRowClick}
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        id: false,
                    },
                },
            }}
            hideFooterSelectedRowCount
            localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
        />
    );
}
