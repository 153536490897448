import React, { useEffect, useState, useContext } from "react";
import AppContext from "Components/AppContext";
import SettingContext from "Providers/SettingContext";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import RoomContainer from "./RoomContainer";
import ApplicationData, { AppId } from "Components/applications/ApplicationData";
import SiteBox from "./SiteBox";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import SiteDialogue from "Components/site/SiteDialogue";
import dayjs from "dayjs";
highchartsMore(Highcharts);

export default function Dashboard() {
    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);    

    const [selectedSiteId, setSelectedSiteId] = useState(0);
    const [openSite, setOpenSite] = useState(false);
    const [reload, setReload] = useState();

    useEffect(() => {
        setting.setGlobalSetting({ appId: AppId.EMERA_V2 });
        ctx.updateTitle(ApplicationData.find((a) => a.id === AppId.EMERA_V2).title);
    }, []);

    return (
        <Box sx={{ pt: 10, ml: 2, mr: 2 }}>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} style={{ flexGrow: "1", display: "flex", flexDirection: "row" }}>
                    <SiteBox selectedSiteId={selectedSiteId} callback={setSelectedSiteId} />
                    <IconButton aria-label="delete" size="large" variant="contained" onClick={() => setOpenSite(true)}>
                        <MoreHorizIcon fontSize="inherit" />
                    </IconButton>
                </Grid>
                <Grid item xs={12}>
                    <RoomContainer selectedSiteId={selectedSiteId} parentReload={reload} />
                </Grid>
            </Grid>

            <SiteDialogue
                open={openSite}
                setOpen={setOpenSite}
                // selectedThingId={selectedThingId}
                setReloadParent={(siteId) => {
                    // console.log("siteId", siteId);
                    setSelectedSiteId(siteId);
                    setReload(dayjs());
                }}
            />
        </Box>
    );
}
