import { useState, useEffect, createRef } from "react";
import { useHistory } from "react-router-dom";
import _ from "underscore";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import ReplyIcon from "@mui/icons-material/Reply";
import ImportTool from "./ImportTool";
import UserService from "Services/userService";
import UtilService from "Services/utilService";
import Grid from "@mui/material/Grid";
import Item from "Components/ui/Item";
import Stack from "@mui/material/Stack";
import { CustomButton } from "../../../components/ui/CustomButton";
import { useTranslation } from "react-i18next";

export default function TopToolBar(props) {
    const { t, i18n } = useTranslation();
    let history = useHistory();

    // const classes = useStyles();
    const searchKeyword = props.callback;
    const modal = createRef();

    const [openImport, setOpenImport] = useState(false);

    const appId = props.appId;

    const [isAdmin, setIsAdmin] = useState(false);
    const [company, setCompany] = useState("");

    useEffect(() => {
        UserService.getAuthenticatedUser().then((user) => {
            if (user != undefined && !UtilService.isEmpty(user.authorities)) {
                let roles = user.authorities.split(",");
                // console.log(roles)

                _.each(roles, function (role) {
                    if (role.toUpperCase() == "ADMIN" || role.toUpperCase() == "SUPER_ADMIN" || role.toUpperCase() == "CLIENT_ADMIN") {
                        setIsAdmin(true);
                    }
                });

                setCompany(user.company);
            }
        });
    }, []);

    const handleKeyword = (e) => {
        if (e instanceof KeyboardEvent) {
            if (e.key != "Enter") {
                return;
            }
        }
        searchKeyword(e.target.value);
    };

    const handleDestination = (e) => {
        history.push(`${process.env.PUBLIC_URL}/device_explorer/share/${appId}`);
    };

    const handleCatalog = (e) => {
        history.push(`${process.env.PUBLIC_URL}/device_explorer/catalog/manufacturerList`);
    };

    return (
        <>
            <AppBar position="static" style={{ backgroundColor: "#FFFFFF" }}>
                <Toolbar>
                    <Grid container>
                        <Grid item xs={6}>
                            <Item sx={{ display: "flex", textAlign: "left", alignItems: "center"}}>
                                {/* <div className={classes.searchIcon}> */}
                                <SearchIcon />
                                {/* </div> */}
                                <InputBase
                                    placeholder={t("de.placeholder")}
                                    // classes={{
                                    // 	root: classes.inputRoot,
                                    // 	input: classes.inputInput,
                                    // }}
                                    inputProps={{ "aria-label": "search" }}
                                    // onBlur={(e) => handleKeyword(e)}
                                    onChange={(e) => handleKeyword(e)}
                                    sx={{ width: 300 }}
                                />
                            </Item>
                        </Grid>
                        <Grid item xs={6} sx={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                            <Stack spacing={1} direction={"row"}>
                                <CustomButton
                                    startIcon={<ReplyIcon style={{ fontSize: "25px", color: "white" }} />}
                                    name={t("btn.share")}
                                    callback={handleDestination}
                                />

                                <CustomButton
                                    startIcon={<i className="fa fa-upload" aria-hidden="true" style={{ color: "rgb(255,255,255)" }}></i>}
                                    name={t("btn.import")}
                                    callback={() => setOpenImport(true)}
                                />
                                <CustomButton startIcon={<i className="fa fa-book"></i>} name="Catalog" callback={handleCatalog} />
                            </Stack>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <ImportTool refs={modal} openImport={openImport} setOpenImport={setOpenImport}></ImportTool>
        </>
    );
}
