import ApiService from "Services/apiService";
import UserService from "Services/userService";
import UtilService from "Services/utilService";
import { v4 as uuidv4 } from "uuid";

const ShareService = {
    DESC_SYSTEM: "This is automatically generated by the system.",

    DESC_SQL_SERVER:
        "This is automatically generated by the system. " +
        "If the pth_data table does not exist, the system automatically creates it. " +
        "Real data like the example below is stored in the json_data column of this table " +
        "and port 1433 will be used to connect.",

    requestForwardMQTT: async (companyName, setTxtMessageFormat, chkOption) => {
        let options = [
            `forwarderKey=MQTT`,
            `&messagePackageSelected=${chkOption[0]}`,
            `&thingPackageSelected=${chkOption[1]}`,
            `&measurePackageSelected=${true}`,
            `&productPackageSelected=${chkOption[2]}`,
            `&gatewayPackageSelected=${chkOption[3]}`,
        ];

        let requestOptions = {
            url: `/api/forward_resources/format?${options.join("")}`,
        };

        ApiService.getData(requestOptions)
            .then((response) => {
                //               // console.dir(response);

                var structureFormat = JSON.parse(response.structure);
                structureFormat = JSON.stringify(structureFormat, null, "\t");

                setTxtMessageFormat(structureFormat);
            })
            .catch((e) => console.error(e));

        return;
    },

    requestMessageFormat: async (forwarderKey, setTxtMessageFormat, chkOption) => {
        let options = [
            `forwarderKey=${forwarderKey}`,
            `&messagePackageSelected=${chkOption[0]}`,
            `&thingPackageSelected=${chkOption[1]}`,
            `&measurePackageSelected=${true}`,
            `&productPackageSelected=${chkOption[2]}`,
            `&gatewayPackageSelected=${chkOption[3]}`,
        ];

        let requestOptions = {
            url: `/api/forward_resources/format?${options.join("")}`,
        };

        // console.log(requestOptions);

        await ApiService.getData(requestOptions)
            .then((response) => {
                // console.dir(response);

                var structureFormat = JSON.parse(response.structure);
                structureFormat = JSON.stringify(structureFormat, null, "\t");

                setTxtMessageFormat(structureFormat);
            })
            .catch((e) => console.error(e));

        return;
    },

    insertForwardMqtt: async (urlParam, topic, clientId, username, password, chkOption, enabled) => {
        let requestOptions = {
            url: `/api/forward_resources`,
            data: {
                name: "Share-MQTT",
                description: "This is automatically generated by the system.",
                forwarderKey: "MQTT",
                scope: "",
                enabled: enabled,
                measurePackageSelected: true,
                messagePackageSelected: chkOption[0],
                thingPackageSelected: chkOption[1],
                productPackageSelected: chkOption[2],
                gatewayPackageSelected: chkOption[3],
                // "id": "7c479ff8-598e-4301-86e8-f9c4e325200b",
                id: uuidv4(),
                parameters: {
                    url: urlParam,
                    topic: topic,
                    clientId: clientId,
                    username: username,
                    password: password,
                },
            },
        };

        return ApiService.postData(requestOptions)
            .then((response) => {
                // console.dir(response);
                return response.id; // forwardTargetId
            })
            .catch((e) => console.error(e));
    },

    insertForwardHttp: async (urlParam, headers, method, grouped, chkOption, files, enabled) => {
        let requestOptions = {
            url: `/api/forward_resources/files`,
            data: {
                name: "Share-HTTP",
                description: "This is automatically generated by the system.",
                forwarderKey: "HTTP",
                scope: "",
                enabled: enabled,
                measurePackageSelected: true,
                messagePackageSelected: chkOption[0],
                thingPackageSelected: chkOption[1],
                productPackageSelected: chkOption[2],
                gatewayPackageSelected: chkOption[3],
                // "id": "7c479ff8-598e-4301-86e8-f9c4e325200b",
                id: uuidv4(),
                parameters: {
                    url: urlParam,
                    headers: headers,
                    method: method,
                    grouped: grouped,
                },
            },
        };

        // console.log(requestOptions);
        if (files) {
            // https
            return ApiService.postDataFile(requestOptions, files)
                .then((response) => {
                    // console.dir(response);
                    return response.id; // forwardTargetId
                })
                .catch((e) => console.error(e));
        } else {
            // http
            return ApiService.postDataFile(requestOptions, undefined)
                .then((response) => {
                    // console.dir(response);
                    return response.id; // forwardTargetId
                })
                .catch((e) => console.error(e));
        }
    },

    insertForwardThingsBoard: async (urlParam, headers, method, grouped, chkOption, files, enabled) => {
        let requestOptions = {
            url: `/api/forward_resources/files`,
            data: {
                name: "Share-ThingsBoard",
                description: "This is automatically generated by the system.",
                forwarderKey: "THINGSBOARD",
                scope: "",
                enabled: enabled,
                measurePackageSelected: true,
                messagePackageSelected: chkOption[0],
                thingPackageSelected: chkOption[1],
                productPackageSelected: chkOption[2],
                gatewayPackageSelected: chkOption[3],
                // "id": "7c479ff8-598e-4301-86e8-f9c4e325200b",
                id: uuidv4(),
                parameters: {
                    url: urlParam,
                    headers: headers,
                    method: method,
                    grouped: grouped,
                },
            },
        };

        // console.log("insertForwardThingsBoard, requestOptions", requestOptions);
        // console.log(files)

        if (files) {
            // https
            return ApiService.postDataFile(requestOptions, files)
                .then((response) => {
                    // console.dir(response);
                    return response.id; // forwardTargetId
                })
                .catch((e) => console.error(e));
        } else {
            // http
            return ApiService.postDataFile(requestOptions, undefined)
                .then((response) => {
                    // console.dir(response);
                    return response.id; // forwardTargetId
                })
                .catch((e) => console.error(e));
        }
    },

    insertForwardEsri: async (enabled, description, url, headers, timezone) => {
        let requestOptions = {
            url: `/api/forward_resources`,
            data: {
                name: "Share-ESRI",
                description: ShareService.DESC_SYSTEM,
                forwarderKey: "ESRIVelocity",
                scope: "",
                enabled: enabled,
                measurePackageSelected: true,
                messagePackageSelected: true,
                thingPackageSelected: true,
                productPackageSelected: true,
                gatewayPackageSelected: true,
                id: uuidv4(),
                parameters: {
                    url: url,
                    headers: headers,
                    timezone: timezone,
                },
            },
        };

        return ApiService.postData(requestOptions)
            .then((response) => {
                // console.dir(response);
                return response.id; // forwardTargetId
            })
            .catch((e) => console.error(e));
    },

    insertForwardSQLserver: async (hostname, user, password, database, chkOption, enabled) => {
        let requestOptions = {
            url: `/api/forward_resources`,
            data: {
                name: "Share-SQLserver",
                description: ShareService.DESC_SQL_SERVER,
                forwarderKey: "SQLserver",
                scope: "",
                enabled: enabled,
                measurePackageSelected: true,
                messagePackageSelected: chkOption[0],
                thingPackageSelected: chkOption[1],
                productPackageSelected: chkOption[2],
                gatewayPackageSelected: chkOption[3],
                id: uuidv4(),
                parameters: {
                    hostname: hostname,
                    user: user,
                    password: password,
                    database: database,
                },
            },
        };

        return ApiService.postData(requestOptions)
            .then((response) => {
                // console.dir(response);
                return response.id; // forwardTargetId
            })
            .catch((e) => console.error(e));
    },

    insertForwardIcicle: async (hostname, user, password, chkOption, enabled) => {
        let requestOptions = {
            url: `/api/forward_resources`,
            data: {
                name: "Share-Icicle",
                description: ShareService.DESC_SYSTEM,
                forwarderKey: "ICICLE",
                scope: "",
                enabled: enabled,
                measurePackageSelected: true,
                messagePackageSelected: chkOption[0],
                thingPackageSelected: chkOption[1],
                productPackageSelected: chkOption[2],
                gatewayPackageSelected: chkOption[3],
                id: uuidv4(),
                parameters: {
                    hostname: hostname,
                    user: user,
                    password: password,
                },
            },
        };

        return ApiService.postData(requestOptions)
            .then((response) => {
                // console.dir(response);
                return response.id; // forwardTargetId
            })
            .catch((e) => console.error(e));
    },

    updateForwardMqtt: async (forwardTargetId, url, topic, clientId, username, password, chkOption, enabled) => {
        let requestOptions = {
            url: `/api/forward_resources/${forwardTargetId}`,
            data: {
                id: forwardTargetId,
                name: "Share-MQTT",
                description: ShareService.DESC_SYSTEM,
                forwarderKey: "MQTT",
                measurePackageSelected: true,
                messagePackageSelected: chkOption[0],
                thingPackageSelected: chkOption[1],
                productPackageSelected: chkOption[2],
                gatewayPackageSelected: chkOption[3],
                measureCustomPackageSelected: null,
                parameters: {
                    url: url ? url : "",
                    topic: topic ? topic : "",
                    clientId: clientId ? clientId : "",
                    username: username ? username : "",
                    password: password ? password : "",
                },
                enabled: enabled,
                link: `/api/forward_resources/forwardTargetId`,
                scope: "",
            },
        };

        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.dir(response);
            })
            .catch((e) => console.error(e));
    },

    updateForwardHttp: async (forwardTargetId, url, headers, method, grouped, chkOption, files, enabled) => {
        let requestOptions = {
            // url: `/api/forward_resources/${forwardTargetId}`,
            data: {
                id: forwardTargetId,
                name: "Share-HTTP",
                description: ShareService.DESC_SYSTEM,
                forwarderKey: "HTTP",
                measurePackageSelected: true,
                messagePackageSelected: chkOption[0],
                thingPackageSelected: chkOption[1],
                productPackageSelected: chkOption[2],
                gatewayPackageSelected: chkOption[3],
                measureCustomPackageSelected: null,
                parameters: {
                    url: url ? url : "",
                    headers: headers ? headers : "",
                    method: method ? method : "",
                    grouped: grouped ? grouped : "",
                },
                enabled: enabled,
                // link: `/api/forward_resources/forwardTargetId`,
                scope: "",
            },
        };

        // console.log("updateForwardHttp, requestOptions", requestOptions);

        if (files) {
            // https
            requestOptions["url"] = `/api/forward_resources/${forwardTargetId}/files`;
            return ApiService.postDataFile(requestOptions, files)
                .then((response) => {
                    // console.dir(response);
                })
                .catch((e) => console.error(e));
        } else {
            // http
            requestOptions["url"] = `/api/forward_resources/${forwardTargetId}`;
            return ApiService.putData(requestOptions)
                .then((response) => {
                    // console.dir(response);
                })
                .catch((e) => console.error(e));
        }
    },

    updateForwardThingsBoard: async (forwardTargetId, url, headers, method, grouped, chkOption, files, enabled) => {
        let requestOptions = {
            // url: `/api/forward_resources/${forwardTargetId}`,
            data: {
                id: forwardTargetId,
                name: "Share-ThingsBoard",
                description: ShareService.DESC_SYSTEM,
                forwarderKey: "THINGSBOARD",
                measurePackageSelected: true,
                messagePackageSelected: chkOption[0],
                thingPackageSelected: chkOption[1],
                productPackageSelected: chkOption[2],
                gatewayPackageSelected: chkOption[3],
                measureCustomPackageSelected: null,
                parameters: {
                    url: url ? url : "",
                    headers: headers ? headers : "",
                    method: method ? method : "",
                    grouped: grouped ? grouped : "",
                },
                enabled: enabled,
                // link: `/api/forward_resources/forwardTargetId`,
                scope: "",
            },
        };

        if (files) {
            // https
            requestOptions["url"] = `/api/forward_resources/${forwardTargetId}/files`;
            return ApiService.postDataFile(requestOptions, files)
                .then((response) => {
                    // console.dir(response);
                })
                .catch((e) => console.error(e));
        } else {
            // http
            requestOptions["url"] = `/api/forward_resources/${forwardTargetId}`;
            return ApiService.putData(requestOptions)
                .then((response) => {
                    // console.dir(response);
                })
                .catch((e) => console.error(e));
        }
    },

    updateForwardEsri: async (forwardTargetId, enabled, description, url, headers, timezone) => {
        let requestOptions = {
            url: `/api/forward_resources/${forwardTargetId}`,
            data: {
                id: forwardTargetId,
                name: "Share-ESRI",
                description: "This is automatically generated by the system.",
                forwarderKey: "ESRIVelocity",
                scope: "",
                enabled: enabled,
                measurePackageSelected: true,
                messagePackageSelected: true,
                thingPackageSelected: true,
                productPackageSelected: true,
                gatewayPackageSelected: true,
                parameters: {
                    url: url,
                    headers: headers,
                    timezone: timezone,
                },
            },
        };

        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.dir(response);
            })
            .catch((e) => console.error(e));
    },

    updateForwardSQLserver: async (forwardTargetId, hostname, user, password, database, chkOption, enabled) => {
        let requestOptions = {
            url: `/api/forward_resources/${forwardTargetId}`,
            data: {
                id: forwardTargetId,
                name: "Share-SQLserver",
                description: ShareService.DESC_SQL_SERVER,
                forwarderKey: "SQLserver",
                measurePackageSelected: true,
                messagePackageSelected: chkOption[0],
                thingPackageSelected: chkOption[1],
                productPackageSelected: chkOption[2],
                gatewayPackageSelected: chkOption[3],
                measureCustomPackageSelected: null,
                parameters: {
                    hostname: hostname ? hostname : "",
                    user: user ? user : "",
                    password: password ? password : "",
                    database: database ? database : "",
                },
                enabled: enabled,
                link: `/api/forward_resources/forwardTargetId`,
                scope: "",
            },
        };

        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.dir(response);
            })
            .catch((e) => console.error(e));
    },

    updateForwardIcicle: async (forwardTargetId, hostname, user, password, chkOption, enabled) => {
        let requestOptions = {
            url: `/api/forward_resources/${forwardTargetId}`,
            data: {
                id: forwardTargetId,
                name: "Share-Icicle",
                description: ShareService.DESC_SYSTEM,
                forwarderKey: "ICICLE",
                measurePackageSelected: true,
                messagePackageSelected: chkOption[0],
                thingPackageSelected: chkOption[1],
                productPackageSelected: chkOption[2],
                gatewayPackageSelected: chkOption[3],
                measureCustomPackageSelected: null,
                parameters: {
                    hostname: hostname ? hostname : "",
                    user: user ? user : "",
                    password: password ? password : "",
                },
                enabled: enabled,
                link: `/api/forward_resources/forwardTargetId`,
                scope: "",
            },
        };

        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.dir(response);
            })
            .catch((e) => console.error(e));
    },

    deleteFlow: async (flowId) => {
        let requestOptions = {
            url: `/api/flows/${flowId}`,
        };

        ApiService.deleteData(requestOptions)
            .then((response) => {
                // console.dir(response);
            })
            .catch((e) => console.error(e));

        return;
    },

    deleteForward: async (forwardTargetId) => {
        let requestOptions = {
            url: `/api/forward_resources/${forwardTargetId}`,
            data: {
                id: forwardTargetId,
            },
        };

        ApiService.deleteData(requestOptions)
            .then((response) => {
                // console.dir(response);
            })
            .catch((e) => console.error(e));

        return;
    },

    getCompany: async (setCompanyName, setTimezone) => {
        const user = await UserService.getAuthenticatedUser();
        setCompanyName(user.company);
        if (setTimezone != undefined) {
            setTimezone(user.timezone);
        }
        return user.company;
    },

    createFlow: async (companyName, flowName) => {
        let requestOptions = {
            url: `/api/flows/create`,
            data: {
                name: flowName,
                description: "This is automatically generated by the system.",
                companyName: companyName,
                enabled: false,
            },
        };
        // console.log(requestOptions);

        return ApiService.postData(requestOptions)
            .then((response) => {
                // console.dir(response);
                // set flowId
                return response.id; // flowId
            })
            .catch((e) => console.error(e));
    },

    createAction: async (flowId, forwardTargetId) => {
        let requestOptions = {
            url: `/api/flows/${flowId}/actions`,
            data: [
                {
                    forwardTargetId: forwardTargetId,
                    actionEnabled: true,
                    type: "FLOW_ACTION_FORWARD",
                },
            ],
        };

        return ApiService.postData(requestOptions)
            .then((response) => {
                // console.dir(response);
            })
            .catch((e) => console.error(e));
    },

    // Request flow target by company name with forward key,
    getFlowTargetMqtt: async (
        companyName,
        setFlowId,
        setForwardTargetId,
        setUrl,
        setTopic,
        setClientId,
        setUserName,
        setPassword,
        setEnabled,
        setChkMessage,
        setChkThing,
        setChkProduct,
        setChkGateway
    ) => {
        let requestOptions = {
            url: `/api/forward_resources/flow_target`,
            data: {
                companyName: companyName,
                forwardKey: "MQTT",
            },
        };

        console.log(requestOptions);

        ApiService.postData(requestOptions)
            .then((response) => {
                // console.dir(response);

                if (response.error == undefined) {
                    setFlowId(response.flowId);
                    setForwardTargetId(response.id);
                    setUrl(response.parameters.url);
                    setTopic(response.parameters.topic);
                    setClientId(response.parameters.clientId);
                    setUserName(response.parameters.username);
                    setPassword(response.parameters.password);
                    setEnabled(response.enabled);
                    // options
                    setChkMessage(response.messagePackageSelected);
                    setChkThing(response.thingPackageSelected);
                    setChkProduct(response.productPackageSelected);
                    setChkGateway(response.gatewayPackageSelected);
                } else {
                    // Reset all values if there's an error
                    setFlowId("");
                    setForwardTargetId("");
                    setUrl("");
                    setTopic("");
                    setClientId("");
                    setUserName("");
                    setPassword("");
                    setEnabled(false);
                    // options
                    setChkMessage(false);
                    setChkThing(false);
                    setChkProduct(false);
                    setChkGateway(false);
                }
            })
            .catch((e) => console.error(e));

        return;
    },

    getFlowTargetHttp: async (
        companyName,
        setFlowId,
        setForwardTargetId,
        setUrl,
        setHeaders,
        setMethod,
        setGrouped,
        setEnabled,
        setChkMessage,
        setChkThing,
        setChkProduct,
        setChkGateway,
        setHost,
        setFilesOnCloud
    ) => {
        let requestOptions = {
            url: `/api/forward_resources/flow_target`,
            data: {
                companyName: companyName,
                forwardKey: "HTTP",
            },
        };

        // console.log(requestOptions);

        return ApiService.postData(requestOptions)
            .then((response) => {
                // console.dir(response);

                if (response.error == undefined) {
                    setFlowId(response.flowId);
                    setForwardTargetId(response.id);

                    const param = response.parameters;
                    let url = param.url;
                    setUrl(url);
                    setHeaders(param.headers);
                    setMethod(param.method);
                    setGrouped(param.grouped);
                    setEnabled(response.enabled);

                    // options
                    setChkMessage(response.messagePackageSelected);
                    setChkThing(response.thingPackageSelected);
                    setChkProduct(response.productPackageSelected);
                    setChkGateway(response.gatewayPackageSelected);

                    let host = url.replace(/^(https?:\/\/)/, "");
                    setHost(host);

                    if (param.CERTIFICATE) {
                        const blobCA = new Blob([param.CERTIFICATE_AUTHORITY], { type: "text/plain" });
                        const fileCA = new File([blobCA], param.CERTIFICATE_AUTHORITY_FILE, { type: "text/plain" });

                        const blobCert = new Blob([param.CERTIFICATE], { type: "text/plain" });
                        const fileCert = new File([blobCert], param.CERTIFICATE_FILE, { type: "text/plain" });

                        const blobPriv = new Blob([param.PRIVATE_KEY], { type: "text/plain" });
                        const filePriv = new File([blobPriv], param.PRIVATE_KEY_FILE, { type: "text/plain" });

                        setFilesOnCloud([fileCA, fileCert, filePriv]);

                        return "https";
                    }

                    return response;
                } else {
                    // Reset all values if there's an error
                    setFlowId("");
                    setForwardTargetId("");
                    setUrl("");
                    setHeaders("");
                    setMethod("");
                    setGrouped("");
                    setEnabled(false);
                    setChkMessage(false);
                    setChkThing(false);
                    setChkProduct(false);
                    setChkGateway(false);
                    setHost("");
                    setFilesOnCloud([]);
                }
            })
            .catch((e) => console.error(e));
    },

    getFlowTargetThingsBoard: async (
        companyName,
        setFlowId,
        setForwardTargetId,
        setUrl,
        setHeaders,
        setMethod,
        setGrouped,
        setEnabled,
        setChkMessage,
        setChkThing,
        setChkProduct,
        setChkGateway,
        setHost,
        setFilesOnCloud
    ) => {
        let requestOptions = {
            url: `/api/forward_resources/flow_target`,
            data: {
                companyName: companyName,
                forwardKey: "THINGSBOARD",
            },
        };

        // console.log(requestOptions);

        return ApiService.postData(requestOptions)
            .then((response) => {
                // console.dir(response);

                if (response.error == undefined) {
                    setFlowId(response.flowId);
                    setForwardTargetId(response.id);

                    const param = response.parameters;
                    let url = param.url;
                    setUrl(url);
                    setHeaders(param.headers);
                    setMethod(param.method);
                    setGrouped(param.grouped);
                    setEnabled(response.enabled);

                    // options
                    setChkMessage(response.messagePackageSelected);
                    setChkThing(response.thingPackageSelected);
                    setChkProduct(response.productPackageSelected);
                    setChkGateway(response.gatewayPackageSelected);

                    let host = url.replace(/^(https?:\/\/)/, "");
                    setHost(host);

                    if (param.CERTIFICATE) {
                        const blobCA = new Blob([param.CERTIFICATE_AUTHORITY], { type: "text/plain" });
                        const fileCA = new File([blobCA], param.CERTIFICATE_AUTHORITY_FILE, { type: "text/plain" });

                        const blobCert = new Blob([param.CERTIFICATE], { type: "text/plain" });
                        const fileCert = new File([blobCert], param.CERTIFICATE_FILE, { type: "text/plain" });

                        const blobPriv = new Blob([param.PRIVATE_KEY], { type: "text/plain" });
                        const filePriv = new File([blobPriv], param.PRIVATE_KEY_FILE, { type: "text/plain" });

                        setFilesOnCloud([fileCA, fileCert, filePriv]);

                        return "https";
                    }
                } else {
                    // Reset all values if there's an error
                    setFlowId("");
                    setForwardTargetId("");
                    setUrl("");
                    setHeaders("");
                    setMethod("");
                    setGrouped("");
                    setEnabled(false);
                    setChkMessage(false);
                    setChkThing(false);
                    setChkProduct(false);
                    setChkGateway(false);
                    setHost("");
                    setFilesOnCloud([]);
                }
            })
            .catch((e) => console.error(e));
    },

    // Request flow target by company name with forward key,
    getFlowTargetEsri: async (companyName, setFlowId, setForwardTargetId, setEnabled, setDescription, setUrl, setHeaders, setTimezone) => {
        let requestOptions = {
            url: `/api/forward_resources/flow_target`,
            data: {
                companyName: companyName,
                forwardKey: "ESRIVelocity",
            },
        };

        ApiService.postData(requestOptions)
            .then((response) => {
                // console.dir(response);

                if (response) {
                    setFlowId(response.flowId);
                    setForwardTargetId(response.id);
                    setEnabled(response.enabled);
                    setDescription(response.description);

                    setUrl(response.parameters.url);
                    setHeaders(response.parameters.headers);
                    setTimezone(response.parameters.timezone);
                }
            })
            .catch((e) => console.error(e));

        return;
    },

    getFlowTargetSQLserver: async (
        companyName,
        setFlowId,
        setForwardTargetId,
        setHostname,
        setUser,
        setPassword,
        setDatabase,
        setEnabled,
        setChkMessage,
        setChkThing,
        setChkProduct,
        setChkGateway
    ) => {
        let requestOptions = {
            url: `/api/forward_resources/flow_target`,
            data: {
                companyName: companyName,
                forwardKey: "SQLserver",
            },
        };

        ApiService.postData(requestOptions)
            .then((response) => {
                // console.dir(response);

                if (response.error == undefined) {
                    setFlowId(response.flowId);
                    setForwardTargetId(response.id);

                    setHostname(response.parameters.hostname);
                    setUser(response.parameters.user);
                    setPassword(response.parameters.password);
                    setDatabase(response.parameters.database);
                    setEnabled(response.enabled);
                    // options
                    setChkMessage(response.messagePackageSelected);
                    setChkThing(response.thingPackageSelected);
                    setChkProduct(response.productPackageSelected);
                    setChkGateway(response.gatewayPackageSelected);
                }
            })
            .catch((e) => console.error(e));

        return;
    },

    getFlowTargetIcicle: async (
        companyName,
        setFlowId,
        setForwardTargetId,
        setHostname,
        setUser,
        setPassword,
        setEnabled,
        setChkMessage,
        setChkThing,
        setChkProduct,
        setChkGateway
    ) => {
        let requestOptions = {
            url: `/api/forward_resources/flow_target`,
            data: {
                companyName: companyName,
                forwardKey: "ICICLE",
            },
        };
        console.log("getFlowTargetIcicle", requestOptions);

        await ApiService.postData(requestOptions)
            .then((response) => {
                console.dir(response);

                if (response.error == undefined) {
                    setFlowId(response.flowId);
                    setForwardTargetId(response.id);

                    setHostname(response.parameters.hostname);
                    setUser(response.parameters.user);
                    setPassword(response.parameters.password);
                    setEnabled(response.enabled);

                    // options
                    setChkMessage(response.messagePackageSelected);
                    setChkThing(response.thingPackageSelected);
                    setChkProduct(response.productPackageSelected);
                    setChkGateway(response.gatewayPackageSelected);
                } else {
                    // Reset all values if there's an error
                    setFlowId("");
                    setForwardTargetId("");
                    setHostname("");
                    setUser("");
                    setPassword("");
                    setEnabled(false);
                    setChkMessage(false);
                    setChkThing(false);
                    setChkProduct(false);
                    setChkGateway(false);
                }
            })
            .catch((e) => console.error(e));

        return;
    },

    checkFlowTargetIncludeSub: async (forwardKey, companyName, setCheckShare, disableShare) => {
        let requestOptions = {
            url: `/api/forward_resources/flow_target/include_sub`,
            data: {
                companyName: companyName,
                forwardKey: forwardKey,
            },
        };
        console.log(requestOptions);

        return ApiService.postData(requestOptions)
            .then((response) => {
                console.dir(response);

                if (response.error) {
                    disableShare(true);
                } else {
                    setCheckShare(response.enabled);
                    disableShare(false);
                }
            })
            .catch((e) => console.error(e));
    },

    checkFlowTarget: async (forwardKey, companyName, setCheckShare, disableShare) => {
        let requestOptions = {
            url: `/api/forward_resources/flow_target`,
            data: {
                companyName: companyName,
                forwardKey: forwardKey,
            },
        };
        // console.log(requestOptions)

        return ApiService.postData(requestOptions)
            .then((response) => {
                // console.dir(response);

                if (response.error) {
                    disableShare(true);
                } else {
                    setCheckShare(response.enabled);
                    disableShare(false);
                }
            })
            .catch((e) => console.error(e));
    },

    isFlowTargetEnable: async (companyName, forwardKey, setFlowId, setForwardTargetId, setCheckShare) => {
        let requestOptions = {
            url: `/api/forward_resources/flow_target`,
            data: {
                companyName: companyName,
                forwardKey: forwardKey,
            },
        };

        return ApiService.postData(requestOptions)
            .then((response) => {
                // console.dir(response);

                if (response) {
                    setFlowId(response.flowId);
                    setForwardTargetId(response.id);
                    setCheckShare(response.enabled);
                }
            })
            .catch((e) => console.error(e));
    },

    enableFlowFlowTargetByCompany: async (companyName, forwardKey, enabled) => {
        let requestOptions = {
            url: `/api/flows/enableByName`,
            data: {
                companyName,
                forwardKey,
                enabled,
            },
        };
        console.log(requestOptions);

        return ApiService.postData(requestOptions)
            .then((response) => {
                console.dir(response);
                return response.enabled;
            })
            .catch((e) => console.error(e));
    },

    enableFlowFlowTarget: async (flowId, forwardTargetId, enable) => {
        let requestOptions = {
            url: `/api/flows/enable`,
            data: {
                flowId: flowId,
                flowEnable: enable,
                forwardTargetId: forwardTargetId,
                forwardTargetEnable: enable,
            },
        };

        return ApiService.postData(requestOptions)
            .then((response) => {
                // console.dir(response);
                return response.enabled;
            })
            .catch((e) => console.error(e));
    },

    requestTimezone: async (setTimezone) => {
        let requestOptions = {
            url: `/timezones?page=1&start=0&limit=500`,
        };

        // console.log("requestTimezone...");
        // console.log(requestOptions);

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log("requestTimezone...Response");
                // console.log(response);
                let zones = [];

                response.forEach((zone) => {
                    zones.push({
                        title: zone.timezone,
                    });
                });

                setTimezone(zones);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestLastSentTimestamp: async (scope, setLastSentTimestamp) => {
        let requestOptions = {
            url: `/api/logs/company/${scope}`,
        };

        // console.log("requestLastSentTimestamp...");
        console.log(requestOptions);

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log("requestLastSentTimestamp...Response");
                console.log(response);

                if (!response.error) {
                    setLastSentTimestamp(UtilService.formatMDHma(response.lastSentTimestamp));
                }
                // setLastSentTimestamp(zones);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    getFlowTargetAwsIot: async (
        companyName,
        setFlowId,
        setForwardTargetId,
        setUrl,
        setTopic,
        setAccessKey,
        setSecretKey,
        setRegion,
        setEnabled,
        setChkMessage,
        setChkThing,
        setChkProduct,
        setChkGateway
    ) => {
        let requestOptions = {
            url: `/api/forward_resources/flow_target`,
            data: {
                companyName: companyName,
                forwardKey: "AWSIoT",
            },
        };

        ApiService.postData(requestOptions)
            .then((response) => {
                // console.dir(response);

                if (response.error == undefined) {
                    setFlowId(response.flowId);
                    setForwardTargetId(response.id);

                    setUrl(response.parameters.url);
                    setTopic(response.parameters.topic);
                    setAccessKey(response.parameters.accessKey);
                    setSecretKey(response.parameters.secretKey);
                    setRegion(response.parameters.region);
                    setEnabled(response.enabled);
                    // options
                    setChkMessage(response.messagePackageSelected);
                    setChkThing(response.thingPackageSelected);
                    setChkProduct(response.productPackageSelected);
                    setChkGateway(response.gatewayPackageSelected);
                } else {
                    // Reset all values if there's an error
                    setFlowId("");
                    setForwardTargetId("");
                    setUrl("");
                    setTopic("");
                    setAccessKey("");
                    setSecretKey("");
                    setRegion("");
                    setEnabled(false);
                    setChkMessage(false);
                    setChkThing(false);
                    setChkProduct(false);
                    setChkGateway(false);
                }
            })
            .catch((e) => console.error(e));

        return;
    },

    insertForwardAwsIot: async (url, topic, accessKey, secretKey, region, chkOption, enabled) => {
        let requestOptions = {
            url: `/api/forward_resources`,
            data: {
                name: "Share-AWS-IoT",
                description: "This is automatically generated by the system.",
                forwarderKey: "AWSIoT",
                scope: "",
                enabled: enabled,
                measurePackageSelected: true,
                messagePackageSelected: chkOption[0],
                thingPackageSelected: chkOption[1],
                productPackageSelected: chkOption[2],
                gatewayPackageSelected: chkOption[3],
                // "id": "7c479ff8-598e-4301-86e8-f9c4e325200b",
                id: uuidv4(),
                parameters: {
                    url: url,
                    topic: topic,
                    accessKey: accessKey,
                    secretKey: secretKey,
                    region: region,
                },
            },
        };

        return ApiService.postData(requestOptions)
            .then((response) => {
                // console.dir(response);
                return response.id; // forwardTargetId
            })
            .catch((e) => console.error(e));
    },

    updateForwardAwsIot: async (forwardTargetId, url, topic, accessKey, secretKey, region, chkOption, enabled) => {
        let requestOptions = {
            url: `/api/forward_resources/${forwardTargetId}`,
            data: {
                id: forwardTargetId,
                name: "Share-AWS-Iot",
                description: ShareService.DESC_SYSTEM,
                forwarderKey: "AWSIoT",
                measurePackageSelected: true,
                messagePackageSelected: chkOption[0],
                thingPackageSelected: chkOption[1],
                productPackageSelected: chkOption[2],
                gatewayPackageSelected: chkOption[3],
                measureCustomPackageSelected: null,
                parameters: {
                    url: url ? url : "",
                    topic: topic ? topic : "",
                    accessKey: accessKey ? accessKey : "",
                    secretKey: secretKey ? secretKey : "",
                    region: region ? region : "",
                },
                enabled: enabled,
                link: `/api/forward_resources/forwardTargetId`,
                scope: "",
            },
        };

        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.dir(response);
            })
            .catch((e) => console.error(e));
    },

    requestSubCompanyWithMine: async (company) => {
        let requestOptions = {
            url: `/v2/companies/sub_with_mine`,
            params: {
                companyName: company,
            },
        };

        // console.log(requestOptions);

        const result = await ApiService.getData(requestOptions);
        // console.log(result);

        const mapped = result.map((r) => {
            return {
                id: r.id,
                name: r.name,
                // name: r.name.replace("COMPANY_", ""),
            };
        });
        // console.log(mapped)

        return mapped;
    },

    setForwardTargetEnabled: async (companyName, forwardKey, enabled) => {
        if (!forwardKey) {
            return;
        }

        let requestOptions = {
            url: `/api/forward_resources/change_all_target`,
            data: {
                companyName,
                forwardKey,
                enabled,
            },
        };
        console.log(requestOptions);

        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.dir(response);
            })
            .catch((e) => console.error(e));
    },
};

export default ShareService;
