import React, { useEffect, useState, useContext } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import UsageMain from "./usage/UsageMain";
import Typography from "@mui/material/Typography";
import BizService from "./BizService";
import { useTranslation } from "react-i18next";
import AppContext from "Components/AppContext";
import ApplicationData, { AppId } from "Components/applications/ApplicationData";
import SubscriptionMain from "./sub/SubscriptionMain";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DatabaseUsage from "./database/DatabaseUsage";
import { CustomButton } from "Components/ui/CustomButton";
import CompanyDialog from "./retention/CompanyDialog";
import dayjs from "dayjs";

export default function BizMain() {
    const { t, i18n } = useTranslation();
    const [companies, setCompanies] = useState();
    const [selectedCompanyName, setSelectedCompanyName] = useState();
    const [selectedCompanyId, setSelectedCompanyId] = useState();
    const [lastMonth, setLastMonth] = useState();
    const [open, setOpen] = useState(false);
    const [reload, setReload] = useState(false);

    let appCtx = useContext(AppContext);
    const [value, setValue] = useState(0);

    useEffect(() => {
        appCtx.updateTitle(ApplicationData.find((a) => a.id == AppId.BIZ).title);

        BizService.requetCompanies(setCompanies);

        BizService.requestLastMonth(selectedCompanyId, setLastMonth);
    }, [selectedCompanyId, reload]);

    const handleCompany = (companyId, companyName) => {
        // console.log(companyId, companyName);
        setSelectedCompanyId(companyId);
        setSelectedCompanyName(companyName);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleRoot = () => {
        // console.log("handleRoot");
        setSelectedCompanyId(null);
        setSelectedCompanyName(null);
    };

    return (
        <Box sx={{ flexGrow: 1, pt: 10, ml: 2, mr: 2 }}>
            <Grid container spacing={0}>
                <Grid item xs={4} sx={{ p: 2 }}>
                    <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 2 }}>
                        <CustomButton
                            name={t("btn.add")}
                            callback={() => {
                                setOpen(true);
                            }}
                        />
                    </Box>
                    {companies && companies.length > 0 ? (
                        <TreeView
                            aria-label="subscription navigator"
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                            defaultExpanded={["0"]}
                        >
                            <TreeItem nodeId="0" label="All" onClick={handleRoot}>
                                {companies.map((c) => {
                                    if (!c.parent) {
                                        const sub = companies.filter((sub) => sub.parent && sub.parent.id == c.id);

                                        return (
                                            <TreeItem nodeId={c.id} key={c.id} label={c.name} onClick={() => handleCompany(c.id, c.name)}>
                                                {sub &&
                                                    sub.map((s) => {
                                                        return <TreeItem nodeId={s.id} key={s.id} label={s.name} onClick={() => handleCompany(s.id, s.name)} />;
                                                    })}
                                            </TreeItem>
                                        );
                                    }
                                })}
                            </TreeItem>
                        </TreeView>
                    ) : (
                        <Typography>Loading...</Typography>
                    )}
                </Grid>
                <Grid item xs={8} sx={{ p: 0 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs value={value} onChange={handleChange} aria-label="Catagories">
                                <Tab label="Subscription" {...a11yProps(0)} />
                                <Tab label="Usage" {...a11yProps(1)} />
                                <Tab label="DB Usage" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        {/* Subscription Panel */}
                        <CustomTabPanel value={value} index={0}>
                            <SubscriptionMain companyId={selectedCompanyId} companyName={selectedCompanyName} />
                        </CustomTabPanel>
                        {/* Usage Panel */}
                        <CustomTabPanel value={value} index={1}>
                            {lastMonth && (
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", flexGrow: 1, border: "1px solid #A8A8A8", pr: 1 }}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            height: 50,
                                            width: "100%",
                                            alignItems: "center",
                                            flexDirection: "row",
                                            textAlign: "center",
                                            pl: 5,
                                        }}
                                    >
                                        {lastMonth ? (
                                            lastMonth.thing ? (
                                                <Typography>{i18n.language === "en" ? lastMonth.thing.monthName : lastMonth.thing.moisNom}</Typography>
                                            ) : lastMonth.gateway ? (
                                                <Typography>{i18n.language === "en" ? lastMonth.gateway.monthName : lastMonth.gateway.moisNom}</Typography>
                                            ) : null
                                        ) : null}

                                        {["thing", "gateway", "sms"].map(
                                            (type) =>
                                                lastMonth[type] && (
                                                    <React.Fragment key={type}>
                                                        <Typography sx={{ pl: 5 }}>
                                                            {lastMonth[type].count} {t(`biz.${type}s`)}
                                                        </Typography>
                                                    </React.Fragment>
                                                )
                                        )}
                                    </Box>
                                    {/* <Box sx={{ display: "flex", flexGrow: 1, justifyContent: "flex-end" }}>
                                        <Box>{selectedCompanyId && <CustomButton name={"All"} callback={handleUsageAll} />}</Box>
                                    </Box> */}
                                </Box>
                            )}

                            <UsageMain companyId={selectedCompanyId} />
                        </CustomTabPanel>
                        {/* DB Usage Panel */}
                        <CustomTabPanel value={value} index={2}>
                            <DatabaseUsage companyId={selectedCompanyId} />
                        </CustomTabPanel>
                    </Box>
                </Grid>
            </Grid>

            <CompanyDialog
                open={open}
                setOpen={setOpen}
                selectedCompanyId={selectedCompanyId}
                selectedCompanyName={selectedCompanyName}
                callback={() => setReload(dayjs())}
            />
        </Box>
    );
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0, pt: 4 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
