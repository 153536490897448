import React, { useState, useEffect } from "react";
import { TextField, Box } from "@mui/material";
import SiteSelector from "./SiteSelector";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { getChartOptions } from "./AnalyticColumn";
import Highcharts from "highcharts/highstock";
import highchartsMore from "highcharts/highcharts-more.js";
import HighchartsReact from "highcharts-react-official";
import AnalyticService from "./AnalyticService";
highchartsMore(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

export default function AnalyticMain() {
    const { t, i18n } = useTranslation();

    const [startDate, setStartDate] = useState(() => {
        return dayjs().subtract(1, "day").format("YYYY-MM-DD");
    });
    const [endDate, setEndDate] = useState(() => {
        return dayjs().format("YYYY-MM-DD");
    });
    const [selectedSite, setSelectedSite] = useState([]);
    const [chartDataGal, setChartDataGal] = useState([]);
    const [chartDataCubic, setChartDataCubic] = useState([]);

    // Axioma in gal, Stealth in gal
    const attrIdsGal = ["9666fe77-0b6a-42e6-814e-81d998c4ad43", "5b27d1ef-af73-4544-a925-89290ea286cb"];

    useEffect(() => {
        AnalyticService.requestAnalytic(startDate, endDate, selectedSite, attrIdsGal).then((res) => {
            setChartDataGal(res);
        });

        // setChartData(generateSampleData());
    }, [startDate, endDate, selectedSite]);

    const chartOptions = getChartOptions({ chartDataGal, chartDataCubic }, t);

    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
        var path = [],
            h = h / 3 - 2;
        path = path.concat(this.circle(w - h, y, h, h), this.circle(w - h, y + h + 4, h, h), this.circle(w - h, y + 2 * (h + 4), h, h));
        return path;
    };

    return (
        <Box sx={{ pt: 12, pl: 2, pr: 2 }}>
            <Box sx={{ display: "flex", gap: "20px", mb: "30px" }}>
                <TextField
                    label={t("wm.start_date")}
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label={t("wm.end_date")}
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                />
                <SiteSelector selectedSite={selectedSite} onSiteChange={setSelectedSite} />
            </Box>

            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </Box>
    );
}
