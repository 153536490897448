import { useEffect, useState } from "react";
import RoomCard from "./card/RoomCard";
import Box from "@mui/material/Box";
import RoomElectricityCard from "./card/RoomElectricityCard";
import RoomContainerService from "./RoomContainerService";
import RoomContactorCard from "./card/RoomContactorCard";
import UserService from "Services/userService";
import NoiseCard from "./card/NoiseCard";
import WaterLeakCard from "./card/WaterLeakCard";
import IAQWidget from "./card/IAQWidget";

export default function RoomContainer({ selectedSiteId, parentReload }) {
    const [assetAttribute, setAssetAttribute] = useState([]);
    const [reloadParent, setReloadParent] = useState(0);
    const [things, setThings] = useState([]);

    const milesightAM308productId = "7f9e4b2d-8c31-4a65-b912-d3e567890abc";

    useEffect(() => {
        let isMounted = true;

        UserService.getAuthenticatedUser().then((u) => {
            // console.log(u);

            if (isMounted) {
                RoomContainerService.requestAsset(u.companyId).then((r) => {
                    // console.log(r);
                    setAssetAttribute(r);
                });

                RoomContainerService.requestThing(milesightAM308productId, u.company).then((r) => {
                    // console.log(r?.data);
                    setThings(r?.data);
                });
            }
        });

        return () => {
            isMounted = false;
        };
    }, [selectedSiteId, parentReload, reloadParent]);

    return (
        <Box
            sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, 400px)", // Largeur fixe des cartes
                gap: 3,
                justifyContent: "start",
                alignItems: "start",
                marginBottom: "20px",  // Ajout de la marge en bas
                "& > *": {
                    width: "400px", // Largeur fixe pour chaque carte
                    minHeight: "400px", // Hauteur minimale
                    height: "fit-content", // Permet l'expansion individuelle
                },
            }}
        >
            {assetAttribute
                .filter((a) => selectedSiteId === 0 || (a.site && selectedSiteId === a.site.id))
                .map((row) => {
                    // console.log(row);
                    if (row.assetAttribute.some((a) => a.attribute.name === "averageAmps")) {
                        return <RoomElectricityCard asset={row} key={row.assetId} setReloadParent={setReloadParent} />;
                    } else if (row.assetAttribute.some((a) => a.attribute.name === "current")) {
                        // console.log("current");
                        // console.log("row", row);

                        // Filtering the only current attribute
                        let updatedRow = { ...row, assetAttribute: row.assetAttribute.filter((a) => a.attribute.name === "current") };

                        return <RoomElectricityCard asset={updatedRow} key={row.assetId} setReloadParent={setReloadParent} />;
                    } else if (row.assetAttribute.some((a) => a.attribute.name === "magnet_status")) {
                        return <RoomContactorCard asset={row} key={row.assetId} setReloadParent={setReloadParent} />;
                    } else if (row.assetAttribute.some((a) => a.attribute.name === "LF")) {
                        // return <RoomSoundCard asset={row} key={row.assetId} setReloadParent={setReloadParent} />;
                        return <NoiseCard asset={row} key={row.assetId} setReloadParent={setReloadParent} />;
                    } else if (row.assetAttribute.some((a) => a.attribute.name === "leakage_status")) {
                        return <WaterLeakCard asset={row} key={row.assetId} setReloadParent={setReloadParent} />;
                    } else if (row && row.length !== 0) {
                        return <RoomCard asset={row} key={row.assetId} entity={row} setReloadParent={setReloadParent} />;
                    }
                })}

            {things
                ?.filter((t) => t.product.id === milesightAM308productId)
                ?.filter((t) => t.lastMeasurements !== undefined)
                ?.map((t) => {
                    const measurements = t?.lastMeasurements?.measurements;
                    if (measurements?.light_level !== undefined) {
                        // Rename light_level to light
                        measurements.light = measurements.light_level;
                    }
                    measurements.id = t?.id; // thingId

                    return measurements;
                })
                ?.map((t) => {
                    return <IAQWidget {...t} thingId={t.id} setReloadParent={setReloadParent} key={t.id} />;
                })}
        </Box>
    );
}
