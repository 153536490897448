import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, Checkbox } from "@mui/material";
import AnalyticService from "./AnalyticService";

export default function SiteSelector({ selectedSite, onSiteChange }) {
    const [sites, setSites] = useState([]);
    
    useEffect(() => {
        AnalyticService.requestSite("FLOWINC").then((res) => {
            setSites(res);
            if (!selectedSite || selectedSite.length === 0) {
                onSiteChange(["all"]);
            }
        });
    }, []);

    const handleChange = (event) => {
        const value = event.target.value;
        if (value.includes("all")) {
            if (selectedSite.includes("all")) {
                onSiteChange(value.filter(item => item !== "all"));
            } else {
                onSiteChange(["all"]);
            }
        } else {
            onSiteChange(value);
        }
    };

    return (
        <FormControl style={{ minWidth: 200 }}>
            <InputLabel>Site</InputLabel>
            <Select
                multiple
                value={selectedSite}
                onChange={handleChange}
                label="Site"
                renderValue={(selected) => 
                    selected.includes("all") ? "All" : 
                    selected.map(id => sites.find(site => site.id === id)?.name).join(", ")
                }
                sx={{ width: "300px" }}
            >
                <MenuItem value="all">
                    <Checkbox checked={selectedSite.includes("all")} />
                    All
                </MenuItem>
                {sites.map((site) => (
                    <MenuItem key={site.id} value={site.id}>
                        <Checkbox checked={selectedSite.includes(site.id)} />
                        {site.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
