import React from 'react';
import { Box, Typography } from '@mui/material';

// Constants pour les seuils de CO2
const CO2_THRESHOLDS = {
    excellent: 400,  // Air extérieur normal
    good: 800,      // Bonne qualité d'air
    fair: 1000,     // Seuil recommandé
    poor: 1500,     // Qualité médiocre
    bad: 2000,      // Mauvaise qualité
    max: 5000       // Limite maximale du capteur
};

// Fonction pour obtenir la couleur en fonction du niveau de CO2
const getCO2Color = (value) => {
    if (value <= CO2_THRESHOLDS.excellent) return '#00ff00';      // Vert
    if (value <= CO2_THRESHOLDS.good) return '#90EE90';          // Vert clair
    if (value <= CO2_THRESHOLDS.fair) return '#ffff00';          // Jaune
    if (value <= CO2_THRESHOLDS.poor) return '#ffa500';          // Orange
    if (value <= CO2_THRESHOLDS.bad) return '#ff4500';           // Orange rouge
    return '#ff0000';                                            // Rouge
};

// Fonction pour obtenir le label qualitatif
const getCO2Label = (value) => {
    if (value <= CO2_THRESHOLDS.excellent) return 'Excellente';
    if (value <= CO2_THRESHOLDS.good) return 'Bonne';
    if (value <= CO2_THRESHOLDS.fair) return 'Acceptable';
    if (value <= CO2_THRESHOLDS.poor) return 'Médiocre';
    if (value <= CO2_THRESHOLDS.bad) return 'Mauvaise';
    return 'Critique';
};

const IAQCo2 = ({ co2, formatValue }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flex: 1,
            width: '100%',
            gap: 2
        }}>
            <Typography sx={{
                fontSize: '3rem',
                fontWeight: 'bold',
                color: 'black'
            }}>
                {formatValue(co2)} ppm
            </Typography>
            
            <Typography sx={{
                fontSize: '1.2rem',
                color: 'black'
            }}>
                Qualité {getCO2Label(parseFloat(co2))}
            </Typography>

            <Box sx={{ 
                position: 'relative',
                width: '80%',
                height: '40px',
                bgcolor: 'rgba(0, 0, 0, 0.05)',
                borderRadius: 2,
                background: `linear-gradient(to right, #00ff00, #90EE90, #ffff00, #ffa500, #ff4500, #ff0000)`
            }}>
                <Box sx={{
                    position: 'absolute',
                    left: `${((parseFloat(co2) - 400) / (5000 - 400)) * 100}%`,
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    bgcolor: getCO2Color(parseFloat(co2)),
                    border: '2px solid white',
                    boxShadow: 1,
                    zIndex: 1
                }} />
            </Box>

            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                width: '80%',
                fontSize: '0.75rem',
                color: 'text.secondary'
            }}>
                {Object.entries(CO2_THRESHOLDS).map(([level, value], index) => (
                    <Typography key={level} variant="caption">
                        {value}
                    </Typography>
                ))}
            </Box>
        </Box>
    );
};

export default IAQCo2;