import React, { useEffect, useState, useContext } from "react";
import AppContext from "Components/AppContext";
import SettingContext from "../../providers/SettingContext";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import RoomContainer from "./RoomContainer";
import { AppId } from "Components/applications/ApplicationData";
import { useTranslation } from "react-i18next";
import SiteBox from "./SiteBox";
highchartsMore(Highcharts);

export default function Dashboard(props) {
    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);
    ctx.updateTitle("Emera");
    const { t, i18n } = useTranslation();
    const [value, setValue] = useState(0);

    useEffect(() => {
        // console.log("useEffect in Dashboard");
        setting.setGlobalSetting({ appId: AppId.EMERA });

        return () => {
            ctx.updateTitle(null);
        };
    }, []);

    return (
        <Box sx={{ pt: 10, ml: 2, mr: 2 }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} style={{ flexGrow: "1", display: "flex", flexDirection: "row" }}>
                    <Box sx={{ width: "20px" }} />
                    <SiteBox callback={setValue} />
                </Grid>
            </Grid>
            <RoomContainer selectedItem={value} />
        </Box>
    );
}
