import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Paper, IconButton } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const CommonAlert = ({ alarms, onAlarmDetailsClick }) => {
    const { t } = useTranslation();

    const getAlarmCount = (severity) => {
        return alarms.filter((alarm) => alarm.severity === severity).length;
    };

    const renderAlarmInfo = (severity, Icon, color) => {
        const count = getAlarmCount(severity);
        const lastAlarm = alarms.find((a) => a.severity === severity);

        return (
            <Paper
                elevation={2}
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: 2,
                    backgroundColor: "white",
                    width: "90%",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <Icon sx={{ color: `${color}.main`, fontSize: 24 }} />
                        <Typography sx={{ color: `${color}.main`, fontWeight: 500 }}>{count}</Typography>
                    </Box>
                    {count > 0 && (
                        <Typography variant="caption" color="text.secondary">
                            {t('common.last')}: {lastAlarm?.timestamp}
                        </Typography>
                    )}
                </Box>
            </Paper>
        );
    };

    return (
        <Box sx={{ 
            display: "flex", 
            flexDirection: "column", 
            height: "100%", 
            justifyContent: "flex-start", 
            gap: 2, 
            alignItems: "center" 
        }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "90%" }}>
                <Typography variant="h6">{t('emera_v2.alerts_title')}</Typography>
                <IconButton 
                    onClick={onAlarmDetailsClick} 
                    sx={{ 
                        transition: "transform 0.2s", 
                        "&:hover": { 
                            transform: "scale(1.1)" 
                        } 
                    }}
                >
                    <ChevronRightIcon />
                </IconButton>
            </Box>

            {renderAlarmInfo("critical", ErrorIcon, "error")}
            {renderAlarmInfo("warning", WarningIcon, "warning")}
            {renderAlarmInfo("info", InfoIcon, "info")}
        </Box>
    );
};

export default CommonAlert;
