import React from 'react';
import { Box, Typography } from '@mui/material';

const LIGHT_THRESHOLDS = {
    night: 0,           // Obscurité totale
    moonlight: 0.5,     // Nuit de pleine lune
    streetnight: 20,    // Rue de nuit bien éclairée min
    streetmax: 70,      // Rue de nuit bien éclairée max
    archives: 100,      // Local archives min
    archivesmax: 200,   // Local archives max
    workspace: 200,     // Local de travail min
    workspacemax: 2000, // Local de travail max
    cloudy: 2000,       // Extérieur couvert min
    cloudymax: 25000,   // Extérieur couvert max
    sunlight: 50000,    // Extérieur plein soleil min
    max: 100000         // Extérieur plein soleil max
};

const getLightLabel = (value) => {
    if (value <= LIGHT_THRESHOLDS.night) return 'Obscurité totale';
    if (value <= LIGHT_THRESHOLDS.moonlight) return 'Pleine lune';
    if (value <= LIGHT_THRESHOLDS.streetnight) return 'Nuit';
    if (value <= LIGHT_THRESHOLDS.streetmax) return 'Rue éclairée';
    if (value <= LIGHT_THRESHOLDS.archives) return 'Faible éclairage';
    if (value <= LIGHT_THRESHOLDS.archivesmax) return 'Archives';
    if (value <= LIGHT_THRESHOLDS.workspace) return 'Travail min';
    if (value <= LIGHT_THRESHOLDS.workspacemax) return 'Bureau';
    if (value <= LIGHT_THRESHOLDS.cloudy) return 'Intérieur lumineux';
    if (value <= LIGHT_THRESHOLDS.cloudymax) return 'Ciel couvert';
    if (value <= LIGHT_THRESHOLDS.sunlight) return 'Extérieur';
    return 'Plein soleil';
};

// Modifier le gradient pour mieux représenter les transitions
const getLightGradient = () => `linear-gradient(to right, 
    #000000 0%,          /* Noir pour obscurité */
    #1a237e ${(Math.log10(LIGHT_THRESHOLDS.moonlight + 1) / Math.log10(100001)) * 100}%,    /* Bleu nuit */
    #303f9f ${(Math.log10(LIGHT_THRESHOLDS.streetnight + 1) / Math.log10(100001)) * 100}%,  /* Bleu foncé */
    #3949ab ${(Math.log10(LIGHT_THRESHOLDS.archives + 1) / Math.log10(100001)) * 100}%,     /* Bleu moyen */
    #5c6bc0 ${(Math.log10(LIGHT_THRESHOLDS.workspace + 1) / Math.log10(100001)) * 100}%,    /* Bleu clair */
    #7986cb ${(Math.log10(LIGHT_THRESHOLDS.cloudy + 1) / Math.log10(100001)) * 100}%,       /* Bleu très clair */
    #90caf9 ${(Math.log10(LIGHT_THRESHOLDS.cloudymax + 1) / Math.log10(100001)) * 100}%,    /* Bleu ciel */
    #ffeb3b ${(Math.log10(LIGHT_THRESHOLDS.max + 1) / Math.log10(100001)) * 100}%           /* Jaune soleil */
)`;

const IAQLight = ({ light, formatValue }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flex: 1,
            width: '100%',
            gap: 2
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 0.5
            }}>
                <Typography sx={{
                    fontSize: '3rem',
                    fontWeight: 'bold',
                    color: 'black'
                }}>
                    {formatValue(light)} lux
                </Typography>
                
                <Typography sx={{
                    fontSize: '1.2rem',
                    color: 'text.secondary',
                    fontWeight: 500
                }}>
                    {getLightLabel(parseFloat(light))}
                </Typography>
            </Box>

            <Box sx={{ 
                position: 'relative',
                width: '80%',
                height: '40px',
                bgcolor: 'rgba(0, 0, 0, 0.05)',
                borderRadius: 2,
                background: getLightGradient()
            }}>
                <Box sx={{
                    position: 'absolute',
                    left: `${((Math.log10(parseFloat(light) + 1)) / Math.log10(100001)) * 100}%`,
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    bgcolor: 'black',
                    border: '2px solid white',
                    boxShadow: 1,
                    zIndex: 1
                }} />
            </Box>

            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                width: '80%',
                fontSize: '0.75rem',
                color: 'text.secondary'
            }}>
                <Typography variant="caption">0</Typography>
                <Typography variant="caption">1</Typography>
                <Typography variant="caption">10</Typography>
                <Typography variant="caption">100</Typography>
                <Typography variant="caption">1k</Typography>
                <Typography variant="caption">10k</Typography>
                <Typography variant="caption">100k</Typography>
            </Box>
        </Box>
    );
};

export default IAQLight;