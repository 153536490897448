import React, { useState, useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { BootstrapDialog } from "../../../../../components/ui/BootstrapDialog";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Item from "../../../../../components/ui/Item";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { DesktopDatePicker } from "@mui/x-date-pickers-pro";
import { CustomButton } from "../../../../../components/ui/CustomButton";
import TenantService from "./TenantService";
import moment from "moment";

import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("53264e234090f26bf650c109bcf9fc2fTz0xMDUxMDMsRT0xNzY5Mjg1NDAxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

export default function CheckInDialog({ open, setOpen, selectedThingId, selectedCloudUserId, setReloadParent }) {
    const [checkInDate, setCheckInDate] = useState(new Date());
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [lastCheckOut, setLastCheckOut] = useState();

    useEffect(() => {
        // console.log("uef in CheckInDialog. selectedCloudUserId=" + selectedCloudUserId);
        if (selectedCloudUserId) {
            TenantService.findCloudUser(selectedCloudUserId).then((u) => {
                // console.log(u);
                if (u) {
                    setFirstName(u.firstName ? u.firstName : "");
                    setLastName(u.lastName ? u.lastName : "");
                }
            });

            TenantService.requestLastCheckOut(selectedThingId).then((r) => {
                // console.log("check !!")
                if (r && lastCheckOut == undefined) {
                    // console.log(moment(r.checkOutDate * 1000).format("LLLL"))
                    setLastCheckOut(moment(r.checkOutDate * 1000));
                }
            });
        }
    }, [selectedCloudUserId]);

    const handleClose = () => {
        setOpen(false);
        setCheckInDate(new Date());
        setLastCheckOut();
    };

    const changeCheckIn = (e) => {
        // console.log(e);
        setCheckInDate(e);
    };

    const handleCheckin = () => {
        TenantService.assignTenantToThing(selectedThingId, selectedCloudUserId).then(() => {
            TenantService.updateTenantName(selectedThingId, firstName, lastName);

            TenantService.checkIn(selectedThingId, selectedCloudUserId, checkInDate).then(() => {
                setOpen(false);
                setReloadParent();
            });
        });
    };

    return (
        <div>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={"xs"} fullWidth>
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Check-in
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {selectedCloudUserId && (
                        <Box sx={{ flexGrow: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Grid container spacing={0}>
                                    <Grid item xs={5} sx={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                                        <Item>Tenant</Item>
                                    </Grid>
                                    <Grid item xs={7} sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                                        <Item>{firstName} {lastName}</Item>
                                    </Grid>
                                    <Grid item xs={5} sx={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                                        <Item>Check-in date</Item>
                                    </Grid>
                                    <Grid item xs={7} sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                                        <Item>
                                            <DesktopDatePicker
                                                label="Date"
                                                inputFormat="MM/dd/yyyy"
                                                value={checkInDate}
                                                minDate={lastCheckOut ? lastCheckOut : undefined}
                                                onChange={changeCheckIn}
                                                slotProps={{ textField: { size: "small" } }}
                                            />
                                        </Item>
                                    </Grid>
                                </Grid>
                            </LocalizationProvider>
                        </Box>
                    )}
                    {!selectedCloudUserId && <Box>Could you please choose a user from the list? Selecting a user is necessary for the check-in process</Box>}
                </DialogContent>
                <DialogActions>
                    {selectedCloudUserId && <CustomButton name="Confirm check-in" callback={handleCheckin} />}
                    {!selectedCloudUserId && <CustomButton name="Close" callback={handleClose} />}
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
