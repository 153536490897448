import ApiService from "Services/apiService";
import UtilService from "Services/utilService";
import _ from "underscore";

const AlertService = {
    // state : IGNORED, RESOLVED
    updateAlertState: async (selectedIds, comment, state, reload) => {
        let preDefinedConfig = {
            url: "/api/alerts/" + selectedIds[0],
            data: {
                id: selectedIds[0],
                comment: comment,
                state: state,
            },
        };

        return ApiService.putData(preDefinedConfig)
            .then((response) => {
                // console.log(response);

                reload(true);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    getEnableGenerateByCompany: async (setOn) => {
        let requestOptions = {
            url: `/api/alerts/getEnableGenerateByCompany/CENGN`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                setOn(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    getEnableGenerateByThingId: async (setOn, thingId) => {
        let requestOptions = {
            url: `/api/alerts/getEnableGenerateByThing/${thingId}`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                setOn(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    enableGenerate: async (enable, thingIds) => {
        let requestOptions = {
            url: `/api/alerts/enableGenerate`,
            data: {
                enableAlert: enable,
                company: "CENGN",
            },
        };

        if (thingIds != undefined) {
            requestOptions.data.thingIds = thingIds;
        }

        return ApiService.postData(requestOptions)
            .then((response) => {
                // do nothing
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestAlerts: async (page, pageSize, setRows, setRowCount, thingId, attributeId, sortModel, filterState, connectivity, companyId) => {
        let sortElement = sortModel.length ? "&sort=" + sortModel.map((s) => s.field + "&dir=" + s.sort.toUpperCase()).join(",") : "";
        let filter = ``;
        let conditions = [];

        if (filterState !== undefined && filterState !== "") {
            // filter += `state:eq_${filterState}`;
            conditions.push(`state:eq_${filterState}`);
        }
        if (attributeId) {
            // filter += `,alert.attributeId:eq_${attributeId}`;
            conditions.push(`alert.attributeId:eq_${attributeId}`);
        }

        if (companyId) {
            // filter += `,company.id:eq_${companyId}`;
            conditions.push(`company.id:eq_${companyId}`);
        }
        if (thingId) {
            // filter += `,thing.id:eq_${thingId}`;
            conditions.push(`thing.id:eq_${thingId}`);
        }

        if (conditions.length > 0) {
            filter = "&filter=" + conditions.join(",");
        }

        let orFilter = "";
        if (connectivity) {
            let allThings = await AlertService.allThings(connectivity);
            // console.log(allThings);

            if (allThings != undefined && allThings.length > 0) {
                let thingQuery = [];
                for (var i = 0; i < allThings.length; i++) {
                    thingQuery.push(`{"property":["thing.id"],"operator":"eq","value":"${allThings[i].thingId}"}`);
                }
                orFilter += encodeURI(`&orFilter=[${thingQuery.join(",")}]`);
            }
        }

        let requestOptions = {
            url: `/api/alerts?pageSize=${pageSize}&page=${page}&${sortElement}${filter}${orFilter}`,
        };

        console.log(requestOptions);

        return ApiService.getData(requestOptions)
            .then((response) => {
                console.log(response);
                let totalSize = response.totalSize;
                let values = [];

                response.data.forEach((ele) => {
                    values.push({
                        id: ele.id,
                        creationDate: UtilService.formateDate(ele.creationDate * 1000),
                        level: ele.level,
                        message: ele.message,
                        fixedName: ele.thing.displayName,
                        comment: ele.comment,
                        lastUpdate: UtilService.formateDate(ele.lastUpdate * 1000),
                        state: ele.state,
                        username: ele.username,
                    });
                });

                setRowCount(totalSize);
                setRows(values);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    allThings: async (connectivity) => {
        let requestOptions = {
            url: `/api/asset_thing/${connectivity}`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response);
                let thingIds = [];
                response.forEach((entity) => {
                    thingIds.push({ thingId: entity.thing.id });
                });
                return thingIds;
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default AlertService;
