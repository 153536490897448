import ApiService from "Services/apiService";

const RoomContainerService = {
    requestAsset: async (companyId) => {
        // let companyId = "c07e6258-0a04-4aca-9bdd-119b21dd5f9c"; // DEMO COMPANY ID
        // let companyId = "27b4629f-5f2e-4b4e-87cc-f691d151d9e0"; // PTH
        let appId = "EMERA_V2";

        let quryString = `companyId=${companyId}&appId=${appId}`;

        const param = {
            url: `/v2/asset?${quryString}`,
        };
        // console.log("param", param);

        return ApiService.getData(param).then((response) => {
            // console.log("in requestAsset");
            // console.log(response);

            return response.map((r) => {
                // console.log("in map");
                const site = r.assetAttribute.find((a) => a.thing.site)?.thing.site;

                return {
                    assetId: r.id,
                    name: r.name,
                    assetAttribute: r.assetAttribute.map((a) => {
                        return { attribute: a.attribute, thing: a.thing };
                    }),
                    site: site,
                };
            });
        });
    },

    requestThing: async (productId, company) => {
        const param = {
            url: `/api/things`,
            params: {
                page: 0,
                pageSize: 100,
                sort: "displayName",
                dir: "ASC",
                detailed: true,
                filter: `product.id:eq_${productId},company.name:eq_${company}`,
            },
        };
        // console.log(param);

        return ApiService.getData(param).then((response) => {
            // console.log(response);
            return response;
        });
    },
};

export default RoomContainerService;
