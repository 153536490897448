import { useState, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles';
import _ from 'underscore';
import style from './style.module.css';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
// import imgFront from 'Assets/images/camera_front.png';
import CatalogService from './CatalogService';

import {
    DataGridPro
} from '@mui/x-data-grid-pro';

import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey("53264e234090f26bf650c109bcf9fc2fTz0xMDUxMDMsRT0xNzY5Mjg1NDAxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");


const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function ManufacturerList(props) {

    const [pageSize, setPageSize] = useState(100);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);

    useEffect(() => {

        CatalogService.requestManufacturers(page, pageSize, setRows, props.keyword);

    }, [page, pageSize, props.keyword]);

    const handlePageChange = (param) => {
        setPage(param);
    }

    const handlePageSizeChange = (param) => {
        setPageSize(param);
    }

    const handleRowClick = (params) => {
        let selectedManufactuer = rows.find(function (el) {
            return el.id == params.id;
        })
        props.callback(selectedManufactuer);
    }

    const handleSelectionModel = (selectionModel) => {
        setSelectionModel(selectionModel);
    };

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 1,
            editable: false,
            sortable: false,
            hide: true
        },
        {
            field: 'logo',
            headerName: 'Logo',
            flex: 0.3,
            editable: false,
            sortable: false,
            align: 'center',
            renderCell: (params) => {
                return (
                    // <img width="auto" height="auto" class={style.manufacturer_image} src={imgFront} />
                    <img width="auto" height="auto" class={style.manufacturer_image}
                        src={`/static/catalog/manufacturers/${params.id}/image.png`} alt="Manufacturer" />
                )
            }
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.7,
            editable: true,
            sortable: false
        },
    ];

    return (
        <DataGridPro
            columns={columns}
            rows={rows}
            disableColumnFilter
            page={page}
            pageSize={pageSize}
            pagination={true}
            paginationMode="server"
            sortingMode="server"
            rowsPerPageOptions={[100]}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onRowSelectionModelChange={(newSelection) => handleSelectionModel(newSelection)}
            density="comfortable"
            // onCellClick={handleCellClick}
            disableSelectionOnClick
            style={{ height: '100%' }}
            headerHeight={0}
            onRowClick={handleRowClick}
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        id: false,
                    },
                },
            }}
        />
    );
}
