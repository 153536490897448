import React, { useState, useEffect, useCallback } from "react";
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    TextField,
    Select,
    MenuItem,
    FormControl,
    Box,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
} from "@mui/material";
import DetailService from "../DetailService";
import { debounce } from "lodash";
import dayjs from "dayjs";
import SiteDialogue from "Components/site/SiteDialogue";
import MiniMap from "./MiniMap";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function TabLocation({ selectedThingId }) {
    const [thing, setThing] = useState();
    const [locationType, setLocationType] = useState("individual");
    const [fixedLongitude, setFixedLongitude] = useState("");
    const [fixedLatitude, setFixedLatitude] = useState("");
    const [reload, setReload] = useState(false);
    const [sites, setSites] = useState([]);
    const [selectedSite, setSelectedSite] = useState(undefined);
    const [selectedSiteId, setSelectedSiteId] = useState(undefined);

    const [openSite, setOpenSite] = useState(false);
    const [coordinates, setCoordinates] = useState([]);

    useEffect(() => {
        // console.log("uef in TabLocation");

        DetailService.requestThing(selectedThingId, setThing).then((r) => {
            // console.log(r);

            DetailService.requestSites(r.company, setSites).then(() => {
                if (r.site) {
                    setSelectedSite(r.site);
                    setSelectedSiteId(r.site.id);

                    setFixedLatitude(null);
                    setFixedLongitude(null);

                    setCoordinates({
                        latitude: null,
                        longitude: null,
                    });

                    setLocationType("site");
                } else {
                    // setSelectedSite(r.site);
                    // setSelectedSiteId(r.site.id);
                    // console.log("Hi", r.fixedLongitude, r.fixedLatitude);

                    setFixedLatitude(r.fixedLatitude);
                    setFixedLongitude(r.fixedLongitude);

                    setCoordinates({
                        latitude: r.fixedLatitude,
                        longitude: r.fixedLongitude,
                    });
                    setLocationType("individual");
                }
            });

            // console.log("locationType", locationType);
        });
    }, [selectedThingId, reload]);

    const handleSite = (siteId) => {
        DetailService.updateSite(selectedThingId, siteId).then(() => {
            if (siteId === null || siteId === "") {
                setSelectedSite(null);
                setSelectedSiteId(null);
            }
 
            setCoordinates({
                latitude: null,
                longitude: null,
            });
            setFixedLatitude(null);
            setFixedLongitude(null);

            setReload(dayjs());
        });
    };

    const handleCoordinates = (coordinates) => {
        if (locationType === "individual") {
            DetailService.updateCoordinates(selectedThingId, coordinates[0], coordinates[1]).then(() => setReload(dayjs()));
        }
    };

    const debounceCoordinatesChange = useCallback(
        debounce((latitude, longitude) => {
            // console.log(companyId, value);
            // console.log(selectedThingId, longitude, latitude);
            DetailService.updateCoordinates(selectedThingId, latitude, longitude).then(() => setReload(dayjs()));
        }, 500),
        []
    );

    const handleLocationType = (type) => {
        // console.log("handleLocationType", type);
        setLocationType(type);

        if (type === "individual") {
            setCoordinates({
                latitude: fixedLatitude,
                longitude: fixedLongitude,
            });
            setSelectedSiteId(undefined);
            DetailService.resetSite(selectedThingId);
        } else {
            setCoordinates({
                latitude: null,
                longitude: null,
            });
            setFixedLatitude(null);
            setFixedLongitude(null);

            DetailService.resetCoordinates(selectedThingId);
        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Accordion defaultExpanded={false}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="position-content" id="position-header">
                    <Typography>Position</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <RadioGroup value={locationType} onChange={(e) => handleLocationType(e.target.value)}>
                        <FormControlLabel value="individual" control={<Radio />} label="Individual, select position on map" />

                        <Box sx={{ ml: 4, mt: 1 }}>
                            <Grid container spacing={0} alignItems="center">
                                <Grid item xs={3}>
                                    <Box>Latitude</Box>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        fullWidth
                                        value={fixedLatitude === null ? "" : fixedLatitude}
                                        onChange={(e) => {
                                            setFixedLatitude(e.target.value);
                                            debounceCoordinatesChange(e.target.value, fixedLongitude);
                                        }}
                                        margin="dense"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Box>Longitude</Box>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        fullWidth
                                        value={fixedLongitude === null ? "" : fixedLongitude}
                                        onChange={(e) => {
                                            setFixedLongitude(e.target.value);
                                            debounceCoordinatesChange(fixedLatitude, e.target.value);
                                        }}
                                        margin="dense"
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <FormControlLabel value="site" control={<Radio />} label="At a site" />

                        <Box sx={{ ml: 4, mt: 1 }}>
                            <FormControl fullWidth size="small">
                                <Select
                                    fullWidth
                                    value={selectedSiteId || ""}
                                    displayEmpty
                                    renderValue={(selected) => {
                                        if (!selected) return "Select a site";
                                        const site = sites.find((site) => site.id === selected);
                                        return site ? site.name : "";
                                    }}
                                    onChange={(e) => handleSite(e.target.value)}
                                >
                                    <MenuItem value="">None</MenuItem>
                                    {sites.map((site) => (
                                        <MenuItem key={site.id} value={site.id}>
                                            {site.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </RadioGroup>
                </AccordionDetails>
            </Accordion>

            <MiniMap zoom={13} callback={handleCoordinates} selectedSite={coordinates} />

            <SiteDialogue
                open={openSite}
                setOpen={setOpenSite}
                selectedThingId={selectedThingId}
                setReloadParent={() => {
                    setReload(dayjs());
                }}
            />
        </Box>
    );
}
